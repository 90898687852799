import create from 'zustand'

type ToastIcon =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'notification'
  | undefined

type ToastStore = {
  title: string
  description: string
  icon: ToastIcon
  visible: boolean
  open: (title: string, description: string, icon?: ToastIcon) => void
  close: () => void
}

export const useToast = create<ToastStore>((set) => ({
  visible: false,
  title: '',
  description: '',
  icon: undefined,
  open: (title: string, description: string, icon: ToastIcon = undefined) =>
    set((prev) => ({
      ...prev,
      title,
      description,
      visible: true,
      icon,
    })),
  close: () => set((prev) => ({ ...prev, visible: false })),
}))
