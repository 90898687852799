export default {
  page: {
    title: 'Restablecimiento de contraseña',
  },
  form: {
    title: 'Restablecimiento de contraseña',
    description:
      'Cree una nueva contraseña segura, diferente de la última que usó y siga nuestro estándar de contraseña',
    password: {
      label: 'Seña',
    },
    confirmPassword: {
      label: 'confirmar seña',
    },
    submit: {
      text: 'Reiniciar',
    },
  },
  instructions: {
    title: 'Nuevas instrucciones de contraseña',
    i1: 'Alfanumérico que tenga al menos 8 caracteres;',
    i2: '1 o más letras minúsculas;',
    i3: '1 o más letras mayúsculas;',
    i4: '1 o más caracteres numéricos;',
    i5: '1 o más caracteres especiales (por ejemplo, $*&@¨%!#)',
  },
  toast: {
    success: {
      title: '¡Éxito!',
      description: '¡Restablecimiento de contraseña con éxito!',
    },
  },
}
