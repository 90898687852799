export default {
  links: {
    requests: 'Solicitações',
    catalogEndoprosthesis: 'Catálogo de endoprótese',
    logout: 'Sair',
    editProfile: 'Editar Perfil',
    doctor: 'Médicos',
    hospital: 'Hospitais',
    dashboard: 'Dashboard',
    priorizate: 'Priorização de projeto',
    user: 'Usuários',
  },
}
