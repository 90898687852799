export default {
  page: {
    title: 'Solicitações',
  },
  status: {
    all: 'Todos os status',
    finished: 'Finalizado',
    '3d_aorta_printing': 'Impressão de aorta 3D',
    engineering_design: 'Projeto de engenharia',
    exam_analysis_sketch: 'Análise do exame/esboço',
    initial_analysis_and_dicom_images: 'Análise inicial e imagens Dicom',
    treatment_suggestion: 'Sugestão de tratamento',
    canceled: 'Cancelado',
    endoprosthesis_manufacturing: 'Fabricação de endoprótese',
    making_the_prototype: 'Confecção do protótipo',
    draft: 'Rascunho',
    screening: 'Triagem',
    tomography_analysis: 'Análise de Tomografia',
    project_approval: 'Aprovação do Projeto',
    prototype: 'Protótipo',
    logistics: 'Logística',
    waiting_for_the_surgery: 'Aguardando a Cirurgia',
    feedback_about_the_surgery: 'Feedaback sobre a cirurgia',
    declined: 'Declinado',
  },
  subStatus: {
    in_pre_analysis_of_informations: 'Pré-análise de informações',
    draft: 'Rascunho',
    waiting: 'Aguardando',
    approved: 'Aprovado',
    finished: 'Finalizado',
    tomography_analysis: 'Análise de tomografia',
    disapproved_no_contrast: 'Sem contraste',
    disapproved_cuts_larger_than_1mm: 'Cortes maiores que 1mm',
    disapproved_acquisition_date_over_6_months:
      'Data de aquisição acima de 6 meses',
    disapproved_motion_artifact_3d: 'Artefato de movimento(3D)',
    disapproved_impossibility_of_analyzing_the_3d_reconstruction:
      'Imposibilidade de analise da reconstrução 3D',
    disapproved_empty_file: 'Arquivo vazio',
    disapproved_others: 'Outros motivos',
    disapproved_no_lap_for_standard_care: 'Sem colo para tratamento padrão',
    disapproved_no_anatomical_indication_for_customized_treatment:
      'Sem indicação anatômica para tratamento customizado',
    disapproved_excessive_crookedness: 'Tortuosidade excessiva',
    disapproved_no_access: 'Sem acesso',
    disapproved_productive_impossibility: 'Impossibilidade produtiva',
    discuss_and_change: 'Discutir e Alterar',
    disapproved_does_not_agree_with_the_proposal: 'Não concorda com a proposta',
    disapproved_patient_withdrew_from_treatment:
      'Paciente desistiu do tratamento',
    disapproved_patient_died: 'Paciente faleceu',
    disapproved_paying_source_did_not_accept: 'Fonte pagadora não aceitou',
    disapproved_approved_by_another_company: 'Optou por outra empresa',
    very_complex_solution: 'Solução muito complexa',
    technically_approved: 'Aprovado Tecnicamente',
    commercially_approved: 'Aprovado Comercialmente',
    disapproved_finished: 'Finalizado',
    form_to_be_filled_out: 'Formulário a ser preenchido',
    registration_problems: 'Problemas no cadastro',
    disapproved_no_patient_access: 'Sem acesso do paciente',
    commercially_waiting: 'Aguardando comercialmente',
    approved_specialist: 'Aprovado especialista',
    disapproved_requester: 'Reprovado solicitante',
    disapproved_specialist: 'Reprovado especialista',
    waiting_schedule: 'Aguardando agendamento',
    analisys: 'Em análise de projeto - Especialista',
    engineering_project_analisys_production: 'Em análise de projeto - Produção',
    engineering_approved_production: 'Aprovado produção',
    engineering_repproved_production: 'Reprovado produção',
    engineering_production_settings_production: 'Ajustes de produção',
  },
  subStatusAbbr: {
    in_pre_analysis_of_informations: 'Pré-análise',
    draft: 'Rascunho',
    engineering_design_draft: 'Rascunho',
    waiting: 'Aguardando',
    approved: 'Aprovado',
    finished: 'Solicitação Finalizada',
    tomography_analysis: 'Análise de tomografia',
    disapproved_no_contrast: 'Reprovado - Problemas DICOM',
    disapproved_cuts_larger_than_1mm: 'Reprovado - Problemas DICOM',
    disapproved_acquisition_date_over_6_months: 'Reprovado - Problemas DICOM',
    disapproved_motion_artifact_3d: 'Reprovado - Problemas DICOM',
    disapproved_impossibility_of_analyzing_the_3d_reconstruction:
      'Reprovado - Problemas DICOM',
    disapproved_empty_file: 'Reprovado - Problemas DICOM',
    disapproved_others: 'Reprovado - Outros motivos',
    disapproved_no_lap_for_standard_care: 'Declinado - Sem colo',
    disapproved_no_anatomical_indication_for_customized_treatment:
      'Reprovado - S/ indi. anatômica',
    disapproved_excessive_crookedness: 'Reprovado - Tort. excessiva',
    disapproved_no_access: 'Declinado - Sem acesso',
    disapproved_productive_impossibility: 'Declinado - Impos. produtiva',
    discuss_and_change: 'Reprovado - Ajustes',
    disapproved_does_not_agree_with_the_proposal: 'Reprovado - Não concorda',
    disapproved_patient_withdrew_from_treatment: 'Paciente desistiu',
    disapproved_patient_died: 'Paciente faleceu',
    disapproved_paying_source_did_not_accept: 'Fonte não aceitou',
    disapproved_approved_by_another_company: 'Optou por outra empresa',
    very_complex_solution: 'Solução muito complexa',
    technically_approved: 'Aprovado Tecnicamente',
    commercially_approved: 'Aprovado Comercialmente',
    disapproved_finished: 'Finalizado',
    form_to_be_filled_out: 'Formulário a ser preenchido',
    registration_problems: 'Reprovado - Problem. cadastro',
    disapproved_no_patient_access: 'Declinado - S/ acesso do paciente',
    commercially_waiting: 'Aguardando comercialmente',
    approved_specialist: 'Aprovado especialista',
    engineering_design_disapproved_requester: 'Reprovado - Ajustes e detalhes',
    engineering_design_disapproved_specialist: 'Reprovado - Ajustes e detalhes',
    engineering_design_waiting_schedule: 'Aguardando agendamento',
    engineering_design_analisys: 'Em análise - Especialista',
    treatment_suggestion_repproved_dicom: 'Reprovado - Problemas DICOM',
    engineering_project_analisys_production: 'Em análise - Produção',
    engineering_approved_production: 'Aprovado produção',
    engineering_repproved_production: 'Reprovado produção',
    engineering_production_settings_production: 'Ajustes de produção',
  },
  priorization: {
    all: 'Todas as prioridades',
  },
  date: {
    all: 'Todas os dias',
    today: 'Hoje',
    lastWeek: 'Últimos 7 dias',
    lastFifteen: 'Últimos 15 dias',
    lastMonth: 'Ultimo Mês',
  },
  table: {
    emptyTitle: 'Cadastre sua solicitação',
    emptyDescription:
      'Você ainda não possui nenhuma solicitação. Cadastre sua primeira solicitação e suas informações serão exibidas aqui.',
    filters: {
      placeholder: 'Etapa',
      priorization: {
        placeholder: 'Prioridade',
      },
      date: {
        placeholder: 'Data',
      },
    },
    search: {
      placeholder: 'Pesquisar',
    },
    newButton: 'Nova solicitação',
    actions: {
      viewProgress: 'Visualizar andamento',
      cancelRequest: 'Cancelar solicitação',
      approved: 'Aprovação comercial',
      messages: 'Mensageria',
      declineRequest: 'Declinar solicitação',
      skipStep: 'Avançar etapa',
    },
  },
}
