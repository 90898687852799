import { ComponentType, FC, PropsWithChildren } from 'react'
import { useTranslator } from './index.hook'

type TranslatorProps = {
  path: string
  Wrapper?: ComponentType<PropsWithChildren> | string
}

export const Translator: FC<TranslatorProps & React.HTMLProps<HTMLElement>> = ({
  path,
  Wrapper,
  ...props
}) => {
  const { t } = useTranslator()

  if (!Wrapper) {
    return <>{t(path)}</>
  }

  return <Wrapper {...props}>{t(path)}</Wrapper>
}

Translator.defaultProps = {
  Wrapper: undefined,
}
