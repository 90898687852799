export default {
  afghanistao: 'Afganistán',
  africa_do_sul: 'Sudáfrica',
  albania: 'Albania',
  alemanha: 'Alemania',
  andora: 'Andora',
  angola: 'Angola',
  anguilla: 'Anguila',
  antarctica: 'Antártida',
  antigua_e_barbuda: 'Antigua y Barbuda',
  argentina: 'Argentina',
  argelia: 'Argelia',
  armenia: 'Armenia',
  aruba: 'Aruba',
  arabia_saudita: 'Arabia Saudita',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijao: 'Azerbaiyán',
  bahamas: 'Bahamas',
  bahrein: 'Bahréin',
  bangladesh: 'Bangladés',
  barbados: 'Barbados',
  belgica: 'Bélgica',
  belize: 'Belice',
  benim: 'Benín',
  bermuda: 'Islas Bermudas',
  butao: 'Bután',
  bielorrussia: 'Bielorrusia',
  bolivia: 'Bolivia',
  bosnia_e_herzegovina: 'Bosnia y Herzegovina',
  botswana: 'Botsuana',
  brasil: 'Brasil',
  brunei: 'Brunéi',
  bulgaria: 'Bulgaria',
  burkina_faso: 'Burkina Faso',
  myanmar_burma: 'Myanmar (Birmania)',
  burundi: 'Burundi',
  camaroes: 'Camerún',
  cabo_verde: 'Cabo Verde',
  camboja: 'Camboya',
  canada: 'Canadá',
  catar: 'Katar',
  chad: 'Chad',
  chile: 'Chile',
  china: 'Porcelana',
  chipre: 'Chipre',
  christmas_island: 'Isla de Navidad',
  cingapura: 'Singapur',
  colombia: 'Colombia',
  comores: 'Comoras',
  coreia_do_norte: 'Corea del Norte',
  coreia_do_sul: 'Corea del Sur',
  costa_do_marfim: 'Costa de Marfil',
  costa_rica: 'Costa Rica',
  croacia: 'Croacia',
  cuba: 'Cuba',
  dinamarca: 'Dinamarca',
  djibouti: 'Yibuti',
  dominica: 'Dominica',
  egito: 'Egipto',
  el_salvador: 'El Salvador',
  emirados_arabes_unidos: 'Emiratos Árabes Unidos',
  equador: 'Ecuador',
  eritreia: 'Eritrea',
  estonia: 'Estonia',
  espanha: 'España',
  etiopia: 'Etiopía',
  fiji: 'Fiyi',
  finlandia: 'Finlandia',
  franca: 'Francia',
  gabao: 'Gabón',
  gambia: 'Gambia',
  georgia: 'Georgia',
  gana: 'Ghana',
  gibraltar: 'Gibraltar',
  grecia: 'Grecia',
  groelandia: 'Groenlandia',
  granada: 'Granada',
  guam: 'Guam',
  guatemala: 'Guatemala',
  guiana: 'Guayana',
  guine: 'Guinea',
  guine_equatorial: 'Guinea Ecuatorial',
  guinebissau: 'Guinea-Bisáu',
  haiti: 'Haití',
  honduras: 'Honduras',
  hong_kong: 'Hong Kong',
  hungria: 'Hungría',
  islandia: 'Islandia',
  ilhas_cayman: 'Islas Caimán',
  ilhas_cocos_keeling: 'Islas Cocos (Keeling)',
  ilha_de_man: 'Isla del hombre',
  ilhas_cook: 'Islas Cook',
  ilhas_falkland_malvinas: 'Islas Malvinas (Falkland)',
  ilhas_feroe: 'Islas Faroe',
  ilhas_mariana_do_norte: 'Islas Marianas del Norte',
  ilhas_marshall: 'Islas Marshall',
  ilhas_norfolk: 'Islas Norfolk',
  ilhas_pitcairn: 'Islas Pitcairn',
  ilhas_salomao: 'Islas Salomón',
  ilhas_turcas_e_caicos: 'Islas Turcas y Caicos',
  ilhas_virgens_americanas: 'Islas Vírgenes de EE.UU',
  ilhas_virgens_britanicas: 'Islas Vírgenes Británicas',
  india: 'India',
  indonesia: 'Indonesia',
  inglaterra_reino_unido: 'Inglaterra, Reino Unido)',
  ira: 'Voluntad',
  iraque: 'Irak',
  irlanda: 'Irlanda',
  israel: 'Israel',
  italia: 'Italia',
  jamaica: 'Jamaica',
  japao: 'Japón',
  jordania: 'Jordán',
  cazaquistao: 'Kazajstán',
  quenia_kenya: 'Kenia (Kenia)',
  kiribati: 'Kiribati',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  quirguistao: 'Kirguistán',
  laos: 'Laos',
  letonia: 'Letonia',
  libano: 'Líbano',
  lesoto: 'Lesoto',
  liberia: 'Liberia',
  libia: 'Libia',
  liechtenstein: 'Liechtenstein',
  lituania: 'Lituania',
  luxemburgo: 'Luxemburgo',
  macau: 'Macao',
  macedonia: 'Macedonia',
  madagascar: 'Madagascar',
  malawi: 'Malaui',
  malasia: 'Malasia',
  maldivas: 'Maldivas',
  mali: 'Malí',
  malta: 'Malta',
  mauritania: 'Mauritania',
  mauricia: 'Mauricio',
  mayotte: 'Mayotte',
  mexico: 'México',
  micronesia: 'Micronesia',
  moldavia: 'Moldavia',
  monaco: 'Mónaco',
  mongolia: 'Mongolia',
  montenegro: 'Montenegro',
  montserrat: 'Monserrat',
  marrocos: 'Marruecos',
  mocambique: 'Mozambique',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands_antilles: 'Antillas Holandesas',
  nova_caledonia: 'Nueva Caledonia',
  nova_zelandia: 'Nueva Zelanda',
  nicaragua: 'Nicaragua',
  niger: 'Níger',
  nigeria: 'Nigeria',
  niue: 'nué',
  noruega: 'Noruega',
  oma: 'Omán',
  paises_baixos: 'Países Bajos',
  paquistao: 'Pakistán',
  palau: 'Palaus',
  panama: 'Panamá',
  papuanova_guine: 'Papúa Nueva Guinea',
  paraguai: 'Paraguay',
  peru: 'Perú',
  filipinas: 'Filipinas',
  polonia: 'Polonia',
  polinesia_francesa: 'Polinesio francés',
  portugal: 'Portugal',
  porto_rico: 'Puerto Rico',
  republica_do_congo: 'República del Congo',
  republica_democratica_do_congo: 'República Democrática del Congo',
  republica_centroafricana: 'República Centroafricana',
  republica_checa: 'República Checa',
  republica_dominicana: 'República Dominicana',
  romenia: 'Rumania',
  ruanda: 'Ruanda',
  russia: 'Rusia',
  saint_barthelemy: 'San Bartolomé',
  samoa: 'Samoa',
  samoa_americana: 'Samoa Americana',
  san_marino: 'San Marino',
  sao_tome_e_principe: 'San Tome y Principe',
  senegal: 'Senegal',
  serbia: 'Serbia',
  serra_leoa: 'Sierra Leona',
  seychelles: 'Seychelles',
  slovakia: 'Eslovaquia',
  slovenia: 'Eslovenia',
  somalia: 'Somalia',
  sri_lanka: 'Sri Lanka',
  saint_helena: 'Santa Elena',
  saint_kitts_and_nevis: 'San Cristóbal y Nieves',
  saint_lucia: 'Santa Lucía',
  saint_martin: 'San Martín',
  saint_pierre_and_miquelon: 'San Pedro y Miquelón',
  saint_vincent_and_the_grenadines: 'San Vicente y las Granadinas',
  sudao: 'Sudán',
  suriname: 'Surinam',
  suazilandia: 'Suazilandia',
  suecia: 'Suecia',
  suica: 'Suiza',
  syria: 'Siria',
  taiwan: 'Taiwán',
  tajiquistao: 'Tayikistán',
  tanzania: 'Tanzania',
  timorleste: 'Timor Oriental',
  tailandia: 'Tailandia',
  togo: 'Ir',
  tokelau: 'Tokelau',
  tonga: 'Tonga',
  trinidad_e_tobago: 'Trinidad y Tobago',
  tunisia: 'Túnez',
  turquemenistao: 'Turkmenistán',
  turquia: 'Pavo',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ucrania: 'Ucrania',
  uruguai: 'Uruguay',
  estados_unidos_eua: 'Estados Unidos (EE. UU.)',
  uzbequistao: 'Uzbekistán',
  vanuatu: 'Vanuatu',
  vaticano: 'Vaticano',
  venezuela: 'Venezuela',
  vietna_vietname: 'Vietnam (Vietnam)',
  wallis_e_futuna: 'Wallis y Futuna',
  iemen_iemen_yemen: 'Yemen (Yemen, Yemen)',
  zimbabwezimbabue: 'Zimbabue (Zimbabwe)',
  zambia: 'Zambia',
}
