export default {
  projectsRequests: {
    columns: {
      protocol: 'Pro...',
      patient: 'Paciente',
      specialist: 'Especialista Técnico',
      date: 'Fecha',
      priority: 'Pri...',
      status: 'Estado',
      actions: 'Comportamientos',
      doctor: 'Médico',
      payingSource: 'Fuente de pago',
      commerciallyApproved: 'Com...',
      consultant: 'Consultor',
      hospital: 'Hospital',
      user: 'Solicitante',
      tooltip: {
        protocol: 'Protocolo',
        priority: 'Prioridad',
        commerciallyApproved: 'Comercial',
      },
    },
    modal: {
      approve: {
        title: 'Aprobación comercial',
        initial: 'O que você quer fazer?',
        approve:
          '¿Está seguro de que desea aprobar comercialmente la solicitud?',
        success: {
          title: 'Petición aprobada',
          description: 'Solicitud aprobada comercialmente.',
        },
        cancel: {
          title: 'Solicitud cancelada',
          description: 'Pedido cancelado debido a una aprobación no comercial.',
        },
      },
    },
  },
  requestHistory: {
    columns: {
      name: 'Nombre',
      profile: 'Perfil',
      status: 'Estado',
      observation: 'Observación',
      date: 'Fecha',
      time: 'Tiempo',
    },
  },
  doctors: {
    columns: {
      name: 'Nombre',
      email: 'Correo electrónico',
      phone: 'Celular',
      city: 'Ciudad',
      province: 'Estado',
      country: 'País',
      actions: 'Acciones',
    },
  },
  users: {
    columns: {
      type: 'Tipo de usuario',
    },
  },
}
