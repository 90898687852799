import home from './home'
import test from './testPage'
import token from './token'
import register from './register'
import login from './login'
import core from './core'
import navbar from './navbar'
import projectsRequests from './projectsRequests'
import tables from './tables'
import wizards from './wizards'
import requests from './requests'
import countries from './countries'
import tabs from './tabs'
import doctor from './doctor'
import hospital from './hospital'
import changePassword from './changePassword'
import passwordReset from './passwordReset'
import notifications from './notifications'
import draftsman from './draftsman'
import notFound from './notFound'
import dashboard from './dashboard'
import scheduleRequests from './scheduleRequests'
import user from './user'
import projectAnalyst from './projectAnalyst'

export default {
  app: {
    home,
    test,
    token,
    register,
    login,
    core,
    navbar,
    projectsRequests,
    tables,
    wizards,
    requests,
    countries,
    tabs,
    doctor,
    changePassword,
    passwordReset,
    hospital,
    notifications,
    draftsman,
    notFound,
    dashboard,
    scheduleRequests,
    user,
    projectAnalyst,
  },
}
