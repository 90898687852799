export default {
  table: {
    newButton: 'Novo usuário',
    emptyTitle: 'Cadastre um usuário',
    emptyDescription:
      'Você ainda não possui nenhum usuário. Cadastre seu primeiro usuário e suas informações serão exibidas aqui.',
  },
  update: {
    title: 'Usuário',
  },
  register: {
    title: 'Cadastro de usuário',
    sections: {
      title: 'Dados do usuário',
      description: 'Preenche as informações do usuário',
    },
    saveButton: 'Criar',
  },
  toast: {
    error: {
      'e-mail_already_in_use':
        'E-mail já está sendo utilizado por outro usuário',
    },
  },
  exclude: {
    modal: {
      initial: {
        title: 'Excluir usuário',
        description: 'Tem certeza que deseja excluir esse usuário?',
      },
      success: {
        title: 'Sucesso',
        description: 'Usuário excluído com sucesso.',
      },
    },
  },
}
