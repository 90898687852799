export default {
  page: {
    title: 'Cadastrar',
  },
  loading: {
    title: 'Carregando',
    description: 'Por favor aguarde o fim da requisição.',
  },
  register: {
    title: 'Faça seu cadastro',
    description: 'Preencha as informações para realizar seu cadastro.',
    form: {
      email: {
        label: 'Email',
        placeholder: 'Digite seu email',
      },
      password: {
        label: 'Senha',
      },
      confirmPassword: {
        label: 'Confirmar senha',
      },
      button: 'Cadastrar',
    },
    hasAccount: 'Já possui uma conta?',
    redirectLogin: 'Faça login',
  },
  success: {
    title: 'Sucesso!',
    description: {
      p1: 'A sua conta de usuário foi criada com sucesso.',
      p2: 'Agora você pode gerenciar as solicitações de tratamento.',
    },
    hasAccount: 'Já possui uma conta?',
    redirectLogin: 'Faça login',
  },
  notFound: {
    title: 'Token não encontrado!',
    description: {
      p1: 'Token não foi encontrado no seu convite.',
      p2: 'Entre em contato com support@email.com.br',
    },
  },
  errorLoading: {
    title: 'Erro de carregamento',
    description: {
      p1: 'Ocorreu um erro durante o carregamento deste formulário.',
      p2: 'Recomenda-se não usar este formulário para entrada de dados até que isso seja resolvido.',
    },
    error: {
      text: 'Entre em contato com suporte@email.com.br com o link para esta página e a mensagem de erro abaixo:',
      message: 'token inesperado < em JSON na posição 0',
    },
  },
  expired: {
    title: 'Acesso não autorizado!',
    description: {
      p1: 'Sua autenticação de token está expirado.',
    },
  },
  invalid: {
    title: 'Token inválido!',
    description: {
      p1: 'Sua autenticação de token para nova senha está inválido.',
      p2: 'Por favor tente novamente.',
    },
  },
}
