export default {
  projectsRequests: {
    columns: {
      protocol: 'Pro...',
      patient: 'Patient',
      specialist: 'Technical specialist',
      date: 'Date',
      priority: 'Pri...',
      status: 'Status',
      actions: 'Actions',
      doctor: 'Doctor',
      payingSource: 'Paying source',
      commerciallyApproved: 'Com...',
      consultant: 'Consultant',
      hospital: 'Hospital',
      user: 'Requester',
      tooltip: {
        protocol: 'Protocol',
        priority: 'Priority',
        commerciallyApproved: 'Commercial',
      },
    },
    modal: {
      approve: {
        title: 'Commercial approval',
        initial: 'What do you want to do?',
        approve: 'Are you sure you want to commercially approve the request?',
        success: {
          title: 'Request approved',
          description: 'Commercially approved request.',
        },
        cancel: {
          title: 'Request canceled',
          description: 'Request canceled due to non-commercial approval.',
        },
      },
    },
  },
  requestHistory: {
    columns: {
      name: 'Name',
      profile: 'Profile',
      status: 'Status',
      observation: 'Observation',
      date: 'Date',
      time: 'Time',
    },
  },
  doctors: {
    columns: {
      name: 'Name',
      email: 'E-mail',
      phone: 'Mobile phone',
      city: 'City',
      province: 'Province',
      country: 'Country',
      actions: 'Actions',
    },
  },
  users: {
    columns: {
      type: 'User type',
    },
  },
}
