export default {
  page: {
    title: 'Dashboard',
    diffMonth: {
      title: 'Visualización de datos',
      description: 'Estás viendo datos de ({{month}}-{{year}}).',
    },
  },
  counterRequests: {
    labels: {
      progress: 'Proceso',
      finished: 'Terminado',
      canceled: 'Cancelado',
      declined: 'Rechazado',
    },
  },
  counterRequestsByStep: {
    title: 'Cantidad de evaluación por etapa',
  },
  counterRequestsByPriority: {
    title: 'Número de solicitudes por prioridad',
  },
  cancellationReasons: {
    title: 'Motivo principal de la cancelación',
  },
  statusByday: {
    title: 'Tiempo promedio por paso',
  },
}
