import { FC, SVGProps } from 'react'

export const FileIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    className={`stroke-primary-300 ${props.className ?? ''}`}
  >
    <path
      d="M15 8H15.01"
      // stroke="#3085E8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4Z"
      // stroke="#3085E8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 15L8 11C8.45606 10.5612 8.97339 10.3301 9.5 10.3301C10.0266 10.3301 10.5439 10.5612 11 11L16 16"
      // stroke="#3085E8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 14L15 13C15.4561 12.5612 15.9734 12.3301 16.5 12.3301C17.0266 12.3301 17.5439 12.5612 18 13L20 15"
      // stroke="#3085E8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
