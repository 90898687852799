export default {
  title: 'Notificaciones',
  markAll: 'Marcar todo como leido',
  empty: 'Aún no tienes notificaciones',
  titles: {
    draft_awaiting_dicon: 'Solicitud en borrador',
    draft: 'Solicitud en borrador',
    created: 'Nueva solicitud creada',
    screening_pre_analysis: 'Solicitud de triaje',
    screening_disapproved_registration_problems: 'Solicitud de triaje',
    screening_disapproved_dicon: 'Solicitud de triaje',
    screening_approved: 'Solicitud de triaje',
    screening_disapproved_no_lap_for_standard_care: 'Solicitud rechazada',
    screening_disapproved_no_patient_access: 'Solicitud rechazada',
    screening_disapproved_productive_impossibility: 'Solicitud rechazada',
    screening_disapproved_no_atomical_indication: 'Solicitud de triaje',
    screening_disapproved_no_excessive_crookdness: 'Solicitud de triaje',
    screening_disapproved_others: 'Solicitud de triaje',
    treatment_suggestion_draft: 'Solicitud de sugerencia de tratamiento',
    treatment_suggestion_waiting_consultant_approved:
      'Solicitud de sugerencia de tratamiento',
    treatment_suggestion_approved: 'Solicitud de sugerencia de tratamiento',
    treatment_suggestion_commercially_approved: 'Aprobación Comercial',
    treatment_suggestion_consultant_approve_request:
      'Solicitud de diseño de ingeniería',
    treatment_approved: 'Solicitud aprobada',
    engineering_design_waiting: 'Solicitud de diseño de ingeniería',
    endoprosthesis_manufacturing_waiting:
      'Aplicación en la fabricación de endoprótesis',
    treatment_suggestion_disapproved_approved_does_not_agree_with_the_proposal:
      'Solicitud de sugerencia de tratamiento',
    treatment_suggestion_disapproved_paying_source_did_not_accept:
      'Solicitud cancelada',
    treatment_suggestion_disapproved_by_another_company: 'Solicitud cancelada',
    canceled_disapproved_patient_died: 'Solicitud cancelada',
    canceled_disapproved_patient_others: 'Solicitud cancelada',
    canceled_disapproved_withdrew_from_treatment: 'Solicitud cancelada',
    treatment_suggestion_disapproved_others:
      'Solicitud de sugerencia de tratamiento',
    declined_others: 'Solicitud rechazada',
    declined_disapproved_patient_withdrew_from_treatment: 'Solicitud rechazada',
    approved: 'Solicitud aprobada',
    declined: 'Solicitud rechazada',
    canceled: 'Solicitud cancelada',
    finished: 'Solicitud completada',

    draft_awaiting_consultant: 'Solicitud de triaje',
    screening_tomography_analysis: 'Solicitud de triaje',
    treatment_disapproved_others: 'Solicitud de sugerencia de tratamiento',
    engineering_design_draft: 'Solicitud de diseño de ingeniería',
    engineering_design_approved: 'Proyecto de ingeniería aprobado',
    engineering_design_repproved: 'Proyecto de ingeniería fallido',
    treatment_suggestion_approved_consultant:
      'Solicitud de diseño de ingeniería',
    engineering_design_waiting_title: 'Solicitud de diseño de ingeniería',
    screening_disapproved_others_agreement_with_specialist:
      'Solicitud de triaje',
    prototype_waiting: 'Solicitud en prototipo',
  },
  descriptions: {
    draft_awaiting_dicon:
      'Consultor su solicitud se encuentra en borrador, para proceder adjunte el archivo DICOM',
    draft:
      'Consultor su solicitud está en borrador, hable con el consultor para más información',
    created:
      'Experto se ha creado una nueva solicitud, haga clic aquí para verla',
    screening_pre_analysis:
      'Consultor, su solicitud está siendo clasificada y se está realizando un pre-análisis de información',
    screening_disapproved_registration_problems:
      'Consultor su solicitud fue rechazada en pre-análisis por problemas en el registro, para proceder a editar la solicitud',
    screening_disapproved_dicon:
      'Consultor su solicitud fue desaprobada pre-analisis por problemas en el archivo DICOM, para proceder a editar la solicitud',
    screening_approved:
      'Consultor su solicitud fue aprobada en la fase de selección',

    treatment_suggestion_approved_consultant:
      'Consultor, la sugerencia de tratamiento fue aprobada y la solicitud continúa con el siguiente paso.',
    treatment_suggestion_approved_specialist:
      'Especialista, su sugerencia de tratamiento ha sido aprobada, continúe con el siguiente paso',
    treatment_suggestion_consultant_approve_request:
      'Consultor aprueba sugerencia de tratamiento',
    treatment_suggestion_commercially_approved:
      'Consultor su solicitud ha sido aprobada comercialmente',
    screening_disapproved_no_lap_for_standard_care:
      'Consultor, su solicitud fue rechazada porque no tiene una vuelta para el tratamiento estándar',
    screening_disapproved_no_patient_access:
      'Consultor su solicitud fue rechazada porque no tiene acceso al paciente',
    screening_disapproved_productive_impossibility:
      'Consultor su solicitud fue rechazada por imposibilidad de producción',
    screening_disapproved_no_atomical_indication:
      'Consultor su tomografía fue rechazada por no tener indicación anatómica, para proceder a realizar un convenio',
    screening_disapproved_no_excessive_crookdness:
      'Consultor su tomografía fue rechazada por tener excesiva tortuosidad, para proceder a realizar un convenio',
    screening_disapproved_others:
      'Consultor Su solicitud no pasó la revisión previa por otros motivos, haga clic aquí para obtener más información',
    treatment_suggestion_draft:
      'Consultor, la solicitud está a la espera de la sugerencia de tratamiento del especialista.',
    treatment_suggestion_waiting_consultant_approved:
      'Consultor aprueba sugerencia de tratamiento',
    treatment_suggestion_waiting:
      'Consultor, aprueba sugerencia de tratamiento',
    treatment_suggestion_disapproved_others:
      'La solicitud de experto fue rechazada por otras razones, haga clic aquí para obtener más información',
    treatment_suggestion_disapproved_by_another_company:
      'Especialista se canceló la solicitud, optaron por otra empresa, click aquí para más información',
    treatment_suggestion_disapproved_paying_source_did_not_accept:
      'Especialista, la solicitud fue cancelada, no se aceptó la fuente de pago, haga clic aquí para obtener más información.',
    treatment_suggestion_disapproved_approved_does_not_agree_with_the_proposal:
      'Especialista su sugerencia de tratamiento fue rechazada, no estaban de acuerdo con ella, para proceder a llegar a un acuerdo',
    engineering_design_waiting: 'Consultor, aprobar el diseño de ingeniería.',
    endoprosthesis_manufacturing_waiting:
      'Consultor su solicitud está en espera de cita',
    declined_others:
      'Consultor, la solicitud fue rechazada por otras razones, haga clic aquí para obtener más información.',
    declined_disapproved_patient_withdrew_from_treatment:
      'Paciente consultor se retiró del tratamiento, haga clic aquí para más información',
    finished: 'La solicitud ha llegado al final de todas las etapas.',
    canceled_disapproved_patient_others:
      'La solicitud de experto se canceló por otros motivos, haga clic aquí para obtener más información',
    canceled_disapproved_patient_died:
      'Especialista el paciente falleció, click aquí para más información',
    canceled_disapproved_withdrew_from_treatment:
      'Especialista el paciente se retiró del tratamiento, click aquí para más información',

    draft_awaiting_consultant:
      'Consultor, su solicitud está siendo clasificada, realizándose un pre-análisis de información',
    screening_tomography_analysis:
      'Consultor, su solicitud está siendo evaluada y se está realizando un análisis de tomografía.',
    treatment_suggestion_approved:
      'Especialista, su sugerencia de tratamiento ha sido aprobada, continúe con el siguiente paso',
    treatment_disapproved_others:
      'Experto, la solicitud fue rechazada por otros motivos, haga clic aquí para obtener más información',
    engineering_design_draft:
      'Diseñador, hay un proyecto de ingeniería por hacer.',
    engineering_design_approved:
      'Experto, el proyecto de ingeniería ha sido aprobado, continúe con el siguiente paso.',
    engineering_design_repproved:
      'Experto, el proyecto de ingeniería ha fallado, hay que hacer ajustes.',
    engineering_design_waiting_description:
      'Consultor, aprobar el diseño de ingeniería.',
    draft_waiting:
      'Consultor su solicitud está en borrador, hable con el consultor para más información.',
    screening_disapproved_others_agreement_with_specialist:
      'Consultor, su tomografía fue rechazada por otros motivos, para proceder, pacte con el especialista.',
    prototype_waiting_applicant_prototype_production:
      'Solicitante, el prototipo está en producción',
    endoprosthesis_manufacturing_waiting_endoprosthesis_protuction:
      'Solicitante, la endoprótesis está en producción',
    finished_finished: 'Solicitante, su solicitud ha sido finalizada',
  },
}
