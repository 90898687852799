import { FileUpload } from '@components/Features/Upload/hook'
import Dexie, { Table } from 'dexie'

type FileTable = FileUpload & {
  id?: number
  startHash: boolean
}

export class BraileDB extends Dexie {
  files!: Table<FileTable>

  constructor() {
    super('braile')
    this.version(1).stores({
      files: '++id',
    })
  }
}

export const braileDB = new BraileDB()
