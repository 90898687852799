export default {
  dateTime: {
    day: {
      sunday: 'Domingo',
      monday: 'Segunda',
      tuesday: 'Terça',
      wednesday: 'Quarta',
      thursday: 'Quinta',
      friday: 'Sexta',
      saturday: 'Sábado',
    },
    longDay: {
      sunday: 'Domingo',
      monday: 'Segunda',
      tuesday: 'Terça-feira',
      wednesday: 'Quarta-feira',
      thursday: 'Quinta-feira',
      friday: 'Sexta-feira',
      saturday: 'Sábado',
    },
    month: {
      january: 'Janeiro',
      february: 'Fevereiro',
      march: 'Março',
      april: 'Abril',
      may: 'Maio',
      june: 'Junho',
      july: 'Julho',
      august: 'Agosto',
      september: 'Setembro',
      october: 'Outubro',
      november: 'Novembro',
      december: 'Dezembro',
    },
    at: 'às',
    of: 'de',
    today: 'Hoje',
  },
  errors: {
    form: {
      required: 'Este campo é obrigatório',
      minArray: 'O campo deve ter pelo menos 1 itens',
      confirmPassword: 'As senhas não estão iguais',
      email: 'Esse é um e-mail inválido',
      passwordLength: 'A senha deve conter no mínimo 8 dígitos',
      biggerThanToday: 'Data de procedimento deve ser superior à data de hoje',
      passwordInvalid:
        'A senha deve conter no mínimo uma letra maiúscula, uma minúscula, um número e um caractere especial.',
    },
    generic: {
      title: 'Erro',
      description:
        'Houve algum erro em nossos servidores, tente novamente mais tarde!',
    },
  },
  roles: {
    doctor: 'Médico',
    admin: 'Admin',
    consultant: 'Consultor',
    specialist: 'Especialista',
    distributor: 'Distribuidor',
    designer: 'Designer',
    estimator: 'Orçamentista',
    draftsman: 'Projetista',
    production_analyst: 'Analista de Produção',
  },
  table: {
    default: {
      emptyTitle: 'Não encontramos nada',
      emptyDescription: 'Nenhum dados foi obtido',
    },
  },
  wizards: {
    stepPrefix: 'Passo',
    stepOf: 'de',
  },
  genre: {
    male: 'Masculino',
    female: 'Feminino',
    other: 'Outro',
  },
  payingSourceType: {
    health_insurance: 'Convênio',
    sus: 'Sistema Público De Saúde',
    particular: 'Particular',
    plano_saude: 'Plano De Saúde',
    outros: 'Outros',
  },
  diseases: {
    aaa: 'AAA',
    coarctation: 'Coarctação',
    taa: 'TAA',
    dissection: 'Dissecção',
    endoleak: 'Endoleak',
    bruise: 'Hematoma',
    iliac: 'Ilíaca',
    ulcer: 'Úlcera',
    thoracoabdominal: 'Toracoabdominal',
  },
  generic: {
    yes: 'Sim',
    not: 'Não',
    noOptions: 'Sem opções',
    all: 'Todos',
    days: 'dias',
  },
  upload: {
    completed: 'Upload de arquivos finalizados',
    cancel: 'CANCELAR',
    error: {
      title: 'Upload com error',
      description:
        'Não foi possivel fazer o upload do arquivo, por favor tente novamente.',
    },
    finish: {
      title: 'Upload concluído',
      description: 'Seu arquivo foi upado com sucesso.',
    },
    starting: 'Iniciando upload',
    loading: 'Fazendo upload de arquivos',
    canceled: 'Upload cancelado',
    dragDrop: 'Arraste e solte ou',
    clickSearch: 'clique para procurar',
    fileValid: 'Arquivo válido, solte para selecionar',
    fileNotValid: 'Arquivo não permitido',
    limitSize: 'Tamanho máximo permitido de até',
  },
  words: {
    date: 'Data',
  },
  languages: {
    'pt-BR': 'Português',
    'es-ES': 'Espanhol',
    'en-US': 'Inglês',
  },
  actions: {
    response: {
      understood: 'Entendi',
    },
  },
  file: {
    modal: {
      exclude: {
        title: 'Excluir arquivo',
        description: 'Tem certeza que deseja excluir esse arquivo?',
        success: {
          title: 'Sucesso',
          description:
            'Essa operação foi realizada com sucesso. Arquivo excluído.',
        },
      },
    },
  },
}
