export default {
  projectsRequests: {
    columns: {
      protocol: 'Pro...',
      patient: 'Paciente',
      specialist: 'Especialista técnico',
      date: 'Data',
      priority: 'Pri...',
      status: 'Status',
      actions: 'Ações',
      doctor: 'Médico',
      payingSource: 'Fonte pag.',
      commerciallyApproved: 'Com...',
      consultant: 'Consultor',
      hospital: 'Hospital',
      user: 'Solicitante',
      procedure_date: 'Data procedimento',
      tooltip: {
        protocol: 'Protocolo',
        priority: 'Prioridade',
        commerciallyApproved: 'Comercial',
      },
    },
    modal: {
      approve: {
        title: 'Aprovação comercial',
        initial: 'O que você quer fazer?',
        approve: 'Tem certeza que deseja aprovar comercialmente a solicitação?',
        success: {
          title: 'Solicitação aprovada',
          description: 'Solicitação aprovada comercialmente.',
        },
        cancel: {
          title: 'Solicitação cancelada',
          description: 'Solicitação cancelada por não aprovação comercial.',
        },
      },
    },
  },
  requestHistory: {
    columns: {
      name: 'Nome',
      profile: 'Perfil',
      status: 'Status',
      observation: 'Observação',
      date: 'Data',
      time: 'Horário',
    },
  },
  doctors: {
    columns: {
      name: 'Nome',
      email: 'E-mail',
      phone: 'Celular',
      city: 'Cidade',
      province: 'Estado',
      country: 'País',
      actions: 'Ações',
    },
  },
  users: {
    columns: {
      type: 'Tipo de usuário',
    },
  },
}
