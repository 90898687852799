export default {
  page: {
    title: 'Dashboard',
    diffMonth: {
      title: 'Data visualization',
      description: 'You are viewing data from ({{month}}-{{year}}).',
    },
  },
  counterRequests: {
    labels: {
      progress: 'Progress',
      finished: 'Completed',
      canceled: 'Canceled',
      declined: 'declined',
    },
  },
  counterRequestsByStep: {
    title: 'Quantity of evaluation per stage',
  },
  counterRequestsByPriority: {
    title: 'Number of requests by priority',
  },
  cancellationReasons: {
    title: 'Main reason for cancellation',
  },
  statusByday: {
    title: 'Average time per stage',
  },
}
