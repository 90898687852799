export default {
  message: 'Prueba de mensaje',
  other: 'Otra prueba',
  title: {
    translator: 'Componente de traductor',
    input: 'Componente de entrada',
    button: 'Componente de botón',
  },
  text: {
    input: 'Seleccionar variación de entrada: ',
    button: 'Seleccionar variación de botón: ',
  },
}
