export default {
  sections: {
    patient: {
      title: 'Datos del paciente',
      description:
        'Ingrese los datos del paciente aquí para el análisis previo',
    },
    treatmentLocation: {
      title: 'Datos de ubicación del tratamiento',
      description:
        'Informar los datos del lugar donde se realizará el tratamiento',
    },
    payingSource: {
      title: 'Datos de fuente de pago',
      description: 'Informe aquí cómo se pagará el tratamiento del paciente',
    },
    diseases: {
      title: 'Enfermedades y más información',
      description:
        'Seleccione una o más opciones de enfermedad. Informar la fecha del procedimiento, historia clínica y propuesta de tratamiento actual del paciente.',
    },
    questions: {
      title: 'Preguntas generales',
      description:
        'Complete la información para proceder con la priorización del servicio',
    },
    upload: {
      title: 'Documentos necesarios',
      description:
        'Ingrese los documentos requeridos. Elija uno o más archivos que desea transferir.',
      help: {
        title: 'Para adjuntar un DICOM, primero debe comprimir el archivo.',
        description:
          'Para comprimir un archivo en Mac, siga las instrucciones a continuación:',
        options: {
          1: 'Haga clic derecho en la carpeta que desea comprimir;',
          2: 'Seleccione "Comprimir" en el menú y espere la compresión;',
          3: 'Cuando se complete el proceso, el archivo tendrá el nombre de la carpeta con la extensión ".zip".',
        },
      },
    },
    priorization: {
      title: 'Priorización',
    },
  },
  modal: {
    title: 'Propuesta enviada con éxito.',
    description:
      'Su solicitud de revisión previa se ha enviado con éxito y está sujeta a revisión de información y documentos.',
    protocol: 'Su número de protocolo',
    titles: {
      send: 'Solicitud enviada',
      save: 'Éxito',
    },
    descriptions: {
      send: 'Su solicitud ha sido enviada con éxito. Espera la revisión del experto.',
      save: 'Los datos del paciente han sido actualizados.',
      thanks: 'Felicidades!',
    },
    back: {
      title: 'Registro de aplicaciones',
      description:
        'Está saliendo del registro de solicitudes y está a punto de perder la información que ya ha sido completada.',
      question: '¿Seguro que quieres salir?',
      toast: {
        title: 'información no guardada',
        description: 'La información ingresada se ha perdido.',
      },
    },
  },
  form: {
    name: {
      label: 'Nombre',
      placeholder: 'Ingrese el nombre del paciente',
    },
    genre: {
      label: 'Género',
      placeholder: 'Seleccione una opción',
    },
    birthDate: {
      label: 'Fecha de nacimiento',
    },
    hospital: {
      label: 'Hospital',
      placeholder: 'Ingrese el nombre del hospital',
    },
    country: {
      label: 'País',
      placeholder: 'Ingrese el nombre del país',
    },
    state: {
      label: 'Estado',
      placeholder: 'Ingrese el nombre del estado',
    },
    city: {
      label: 'Ciudad',
      placeholder: 'Introduzca el nombre de la ciudad',
    },
    doctor: {
      label: 'Médico',
      placeholder: 'Ingrese el nombre del médico',
    },
    payingSourceType: {
      label: 'Tipo de fuente de pago',
      placeholder: 'Seleccione una opción',
    },
    payingSource: {
      label: 'Fuente de pago',
    },
    payingSourceDescription: {
      label: 'Complemento de fuente de pago',
      placeholder: 'Ingrese la fuente de pago',
    },
    diseases: {
      label: 'Enfermedades',
    },
    clinicalHistory: {
      label: 'Historial clinico',
      placeholder: 'Ingresar el historial médico del paciente',
    },
    treatmentProposal: {
      label: 'Propuesta de tratamiento',
      placeholder:
        'Paciente con aneurisma de arteria ilíaca bilateral. Intención del tratamiento: embolización de la arteria hipogástrica derecha y endoprótesis ramificada personalizada para la arteria ilíaca común izquierda.',
    },
    comments: {
      label: 'Comentarios',
      placeholder: 'Ingrese notas para la priorización aquí',
    },
    dicom: {
      label: 'Documento Dicom',
    },
    medicalReport: {
      label: 'Informe médico',
    },
    projectSketch: {
      label: 'Boceto del proyecto',
    },
    treatmentSuggestion: {
      label: 'Sugerencia de tratamiento',
    },

    procedure_date: {
      label: 'Fecha del procedimiento',
    },
  },
  questions: {
    paciente_esta_internado: '¿El paciente está hospitalizado?',
    paciente_esta_com_dor: '¿El paciente tiene dolor?',
    se_sim_paciente_aguardara_a_cirurgia_internado:
      'En caso afirmativo, ¿el paciente esperará la cirugía en el hospital?',
  },
}
