export default {
  modal: {
    title: "doctor's data",
    description: "Fill in the doctor's information",
    cancelButton: 'Cancel',
    saveButton: 'Save',
    toast: {
      success: {
        title: 'Success!',
        description: 'Doctor registered successfully!',
      },
      error: {
        title: 'Error!',
        'e-mail_already_in_use':
          'Email is already being used by another doctor',
        register: {
          generic: 'There was an error registering the doctor!',
        },
        update: {
          generic: 'There was an error updating the doctor!',
        },
      },
    },
  },
  form: {
    name: {
      label: 'Name',
      placeholder: "Enter the doctor's name",
    },
    consultant: {
      placeholder: 'Enter the name of the consultant',
    },
    email: {
      label: 'Email',
      placeholder: "Enter the doctor's email",
    },
    phone: {
      label: 'Mobile phone',
    },
    language: {
      label: 'Language',
      placeholder: 'Select an option',
    },
    telephone: {
      label: 'Telephone',
    },
    invite: {
      title: 'Make the doctor a user of the platform',
      description:
        'Soon after completing the registration, an invitation will be sent by email for the doctor to become a user of the platform.',
    },
  },
  table: {
    newButton: 'New doctor',
    actions: {
      update: 'Change registration',
      updateSpecialist: 'Change consultant',
      delete: 'Delete registration',
      resend: 'Resend invitation',
      resetPassword: 'Reset password',
    },
    emptyTitle: 'Register a doctor',
    emptyDescription:
      "You don't have any doctors yet. Register your first doctor and their information will be displayed here.",
  },
  register: {
    title: 'Doctor registration',
    cancelButton: 'Cancel',
    saveButton: 'Save',
    sections: {
      title: "Doctor's data",
      description: "Fill in the doctor's information",
    },
    modal: {
      success: {
        title: 'Success',
        description: 'This operation was successfully performed.',
      },
      noUser: "Saved doctor's data.",
      isUser: "Doctor's data saved and invitation sent by email.",
      error: {
        title: 'Error',
      },
    },
  },
  update: {
    title: "Doctor's Edition",
    actionButton: 'Edit information',
  },
  exclude: {
    modal: {
      initial: {
        title: 'Doctor exclusion',
        description: 'Are you sure you want to delete this doctor?',
      },
      success: {
        title: 'Success',
        description: 'This operation was successfully performed.',
        passwordReset: 'Password reset link has been sent to the doctor.',
        exclude: "Saved doctor's data.",
        invite: 'Invitation resent to doctor',
      },
      error: {
        title: 'Error',
      },
    },
  },
}
