export default {
  page: {
    title: 'Dashboard',
    diffMonth: {
      title: 'Visualização de dados',
      description: 'Você está visualizando os dados de ({{month}}-{{year}}).',
    },
  },
  counterRequests: {
    labels: {
      progress: 'Andamento',
      finished: 'Concluídas',
      canceled: 'Canceladas',
      declined: 'Declinadas',
    },
  },
  counterRequestsByStep: {
    title: 'Quantidade de solicitações por etapa',
  },
  counterRequestsByPriority: {
    title: 'Quantidade de solicitações por prioridade',
  },
  cancellationReasons: {
    title: 'Principal motivo de cancelamento',
  },
  statusByday: {
    title: 'Tempo médio por etapa',
  },
}
