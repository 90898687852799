export default {
  page: {
    title: 'Alteração de senha',
  },
  form: {
    firstStep: {
      title: 'Alterar minha senha',
      description:
        'Entre com o e-mail associado a sua conta que nós te enviaremos as instruções para alterar sua senha.',
    },
    secondStep: {
      title: 'Verifique seu e-mail',
      description:
        'Enviamos as instruções para você alterar a sua senha por e-mail. Lembre-se de verificar a caixa de spam',
      p1: 'Não recebeu o e-mail?',
      p2: 'Enviar novo link',
    },
    email: {
      label: 'Email',
      placeholder: 'Digite seu email',
    },
    submit: {
      text: 'Enviar instruções por email',
    },
  },
}
