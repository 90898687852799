export default {
  page: {
    title: 'Cambia la contraseña',
  },
  form: {
    firstStep: {
      title: 'Cambiar mi contraseña',
      description:
        'Ingresa el correo electrónico asociado a tu cuenta y te enviaremos instrucciones para cambiar tu contraseña.',
    },
    secondStep: {
      title: 'Consultar su correo electrónico',
      description:
        'Le hemos enviado instrucciones para cambiar su contraseña por correo electrónico. Recuerda revisar tu casilla de correo no deseado.',
      p1: 'No recibiste el email?',
      p2: 'Enviar nuevo enlace',
    },
    email: {
      label: 'Correo electrónico',
      placeholder: 'Escriba su correo electrónico',
    },
    submit: {
      text: 'Enviar instrucciones por correo electrónico',
    },
  },
}
