export default {
  page: {
    title: 'Programar proyecto',
    schedule: {
      title: 'Nuevo proyecto de ingenieria',
      observation: 'Mover el nuevo proyecto de ingeniería al día deseado',
    },
  },
  scheduleModal: {
    title: 'Mover proyecto de ingeniería',
    description: 'Elige la mejor fecha para mover el proyecto de ingeniería',
    canMove: 'Se puede mover',
    cantMove: 'No se puede mover',
    moveButton: 'Para mover',
    scheduleTitle: 'Programación de proyectos',
    observation: {
      title: 'Notas de programación',
      placeholder:
        'Ingrese aquí cualquier observación o información que considere relevante',
    },
    descriptions: {
      reset:
        '¿Está seguro de que desea restablecer el cronograma del proyecto de ingeniería?',
      cancel:
        '¿Está seguro de que desea cancelar el cronograma del proyecto de ingeniería?',
      priorizate:
        '¿Está seguro de que desea priorizar los proyectos de ingeniería?',
      success:
        'Esta operación se realizó con éxito. Proyecto de ingeniería programado para otra fecha.',
    },
  },
  legends: {
    scheduled: 'Programado',
    reproved: 'Rechazado',
    sent: 'Enviado',
    settings: 'Ajustes',
    approved: 'Aprobado',
  },
}
