import * as Accordion from '@radix-ui/react-accordion'
import { styled, keyframes } from '@stitches/react'
import { mauve } from '@radix-ui/colors'
import {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Flex, Grid } from '@components/Core/Display'
import { FileIcon } from '@components/Shared/FileIcon'
import { useUploadStory } from '@states/stores/upload'
import { Translator } from '@components/Core/Translator'
import { Progress } from '@components/Core/Progress'
import { useToast } from '@states/stores/toast'
import SuccessIcon from './success.svg'
import ArrowDownIcon from './arrowDown.svg'
import CloseIcon from './close.svg'
import LoaderIcon from './loader.svg'
import ErrorIcon from './error.svg'
import { useUpload } from './hook'

const MODAL_TIME_CLOSE = 10 * 1000 // 5s

const AccordionRoot = styled(Accordion.Root, {
  borderRadius: 6,
  width: '25rem',
  backgroundColor: mauve.mauve6,
  boxShadow: `0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)`,
  zIndex: 400,
})

const AccordionItem = styled(Accordion.Item, {
  marginTop: 1,

  '&:first-child': {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  '&:last-child': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  '&:focus-within': {
    position: 'relative',
    zIndex: 400,
  },
})

const StyledHeader = styled(Accordion.Header, {
  all: 'unset',
  display: 'flex',
})

const StyledTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  backgroundColor: '#11151F',
  padding: '20px',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '1rem',
  lineHeight: 1,
  color: 'white',
  margin: 0,
  borderRadius: '8px 8px 0 0',
})

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
})

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
})

const StyledContent = styled(Accordion.Content, {
  maxHeight: '25rem',
  overflowY: 'auto',
  fontSize: 15,
  color: mauve.mauve11,
  backgroundColor: mauve.mauve2,

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
})

const AccordionTrigger: ForwardRefExoticComponent<PropsWithChildren> =
  forwardRef<HTMLButtonElement, PropsWithChildren>(
    ({ children, ...props }, forwardedRef) => (
      <StyledHeader>
        <StyledTrigger {...props} ref={forwardedRef}>
          {children}
        </StyledTrigger>
      </StyledHeader>
    )
  )

const AccordionContent: ForwardRefExoticComponent<PropsWithChildren> =
  forwardRef<HTMLDivElement, PropsWithChildren>(
    ({ children, ...props }, forwardedRef) => (
      <StyledContent {...props} ref={forwardedRef}>
        {children}
      </StyledContent>
    )
  )

export const Upload = () => {
  const { visible, setVisible } = useUploadStory()
  const { queue, cancelUpload } = useUpload()
  const [value, setValue] = useState('download-items')
  const { open: openToast } = useToast()

  const titleLabel = useMemo(() => {
    if (queue.some((file) => file.canceled)) return 'core.upload.canceled'
    if (queue.some((file) => file.error)) return 'core.upload.error.title'
    if (queue.some((file) => !file.progress)) return 'core.upload.starting'

    if (
      queue.some((file) => file.progress && file.progress > 0 && !file.uploaded)
    )
      return 'core.upload.loading'

    return 'core.upload.completed'
  }, [queue])

  useEffect(() => {
    setValue(visible ? 'download-items' : '')
  }, [visible])

  useEffect(() => {
    if (!queue.every((file) => file.uploaded)) return
    if (!visible) return

    setTimeout(() => setVisible(false), MODAL_TIME_CLOSE)

    openToast(
      'core.upload.finish.title',
      'core.upload.finish.description',
      'success'
    )
  }, [queue])

  return (
    <AccordionRoot
      className={`fixed bottom-0 right-20 border-0 outline-none ${
        visible ? 'block' : 'hidden'
      }`}
      type="single"
      value={value}
      collapsible
    >
      <AccordionItem value="download-items">
        <AccordionTrigger>
          <>
            <span>
              <Translator path={titleLabel} />
            </span>
            <Flex className="gap-5">
              <span
                className="cursor-pointer"
                aria-hidden="true"
                onClick={() =>
                  setValue((prev) => (prev ? '' : 'download-items'))
                }
              >
                <ArrowDownIcon className={`${value ? '' : 'rotate-180'}`} />
              </span>
              <span
                className="cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  setVisible(false)
                }}
              >
                <CloseIcon />
              </span>
            </Flex>
          </>
        </AccordionTrigger>
        <AccordionContent>
          <Flex className="flex-col">
            {queue.some(
              (file) =>
                file.progress &&
                file.progress > 0 &&
                !file.uploaded &&
                !file.error &&
                !file.canceled
            ) && (
              <Flex className="text-xs text-dark-gray-500 text-semibold justify-end items-center p-3 bg-light-gray-200">
                <span
                  className="cursor-pointer"
                  aria-hidden="true"
                  onClick={cancelUpload}
                >
                  <Translator path="core.upload.cancel" />
                </span>
              </Flex>
            )}
            {queue.map((file) => {
              if (file.error) {
                return (
                  <span
                    key={file.name}
                    className="text-danger-500 text-xs font-semibold bg-light-gray-200 p-3"
                  >
                    <Translator path="core.upload.error.description" />
                  </span>
                )
              }

              return (
                <Flex
                  key={file.name}
                  className="flex-col px-4 py-5 border-b border-light-gray-200 gap-3"
                >
                  <Grid className="gap-6 grid-cols-[auto_1fr_auto]">
                    <FileIcon
                      className={
                        file.progress
                          ? 'stroke-primary-300'
                          : 'stroke-primary-200'
                      }
                    />
                    <span
                      className={
                        file.progress
                          ? 'text-dark-gray-500'
                          : 'text-light-gray-400'
                      }
                    >
                      {file.name}
                    </span>
                    {!file.progress && (
                      <LoaderIcon className="animate-spin-slow" />
                    )}
                    {file.progress && file.uploaded && <SuccessIcon />}
                    {file.progress && file.canceled && <ErrorIcon />}
                  </Grid>
                  {file.progress &&
                    file?.progress > 0 &&
                    !file?.canceled &&
                    !file.uploaded &&
                    !file.error && (
                      <Flex className="flex-col items-start w-full">
                        <Progress
                          name={`${file.name}.${file.file?.name}`}
                          progress={file?.progress ?? 0}
                        />
                      </Flex>
                    )}
                </Flex>
              )
            })}
          </Flex>
        </AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  )
}
