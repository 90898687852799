export default {
  page: {
    title: 'Projects Requests',
  },
  status: {
    all: 'All statuses',
    finished: 'Finished',
    '3d_aorta_printing': '3D aorta printing',
    engineering_design: 'Engineering design',
    exam_analysis_sketch: 'Exam/sketch analysis',
    initial_analysis_and_dicom_images: 'Initial analysis and Dicom images',
    treatment_suggestion: 'Treatment suggestion',
    canceled: 'Canceled',
    endoprosthesis_manufacturing: 'Endoprosthesis manufacturing',
    making_the_prototype: 'Making the prototype',
    draft: 'Draft',
    screening: 'Screening',
    tomography_analysis: 'Tomography Analysis',
    project_approval: 'Project Approval',
    prototype: 'Prototype',
    logistics: 'Logistics',
    waiting_for_the_surgery: 'Waiting for surgery',
    feedback_about_the_surgery: 'Feedback on the surgery',
    declined: 'Declined',
  },
  subStatus: {
    waiting: 'Waiting',
    draft: 'Draft',
    approved: 'Approved',
    finished: 'Finished',
    tomography_analysis: 'Tomography analysis',
    in_pre_analysis_of_informations: 'In pre-analysis of information',
    disapproved_no_contrast: 'No contrast',
    disapproved_cuts_larger_than_1mm: 'Cuts larger than 1mm',
    disapproved_acquisition_date_over_6_months:
      'Acquisition date over 6 months',
    disapproved_motion_artifact_3d: 'Motion Artifact(3D)',
    disapproved_impossibility_of_analyzing_the_3d_reconstruction:
      'Impossibility of analyzing the 3D reconstruction',
    disapproved_empty_file: 'Empty file',
    disapproved_others: 'Others',
    disapproved_no_lap_for_standard_care: 'No lap for standard treatment',
    disapproved_no_anatomical_indication_for_customized_treatment:
      'No anatomical indication for customized treatment',
    disapproved_excessive_crookedness: 'Excessive crookedness',
    disapproved_no_access: 'No access',
    disapproved_productive_impossibility: 'Productive impossibility',
    discuss_and_change: 'Discuss and Change',
    disapproved_does_not_agree_with_the_proposal: 'Disagree with the proposal',
    disapproved_patient_withdrew_from_treatment:
      'Patient withdrew from treatment',
    disapproved_patient_died: 'Patient died',
    disapproved_paying_source_did_not_accept: 'Paying source not accepted',
    disapproved_approved_by_another_company: 'Appointed by another company',
    very_complex_solution: 'Very complex solution',
    technically_approved: 'Technically Approved',
    commercially_approved: 'Commercially Approved',
    disapproved_finished: 'Finished',
    form_to_be_filled_out: 'Form to be filled out',
    registration_problems: 'Problems in the registration',
    disapproved_no_patient_access: 'No patient access',
    approved_specialist: 'Approved specialist',
    disapproved_requester: 'Failed Applicant',
    disapproved_specialist: 'Failed specialist',
    waiting_schedule: 'Awaiting appointment',
    analisys: 'In project analysis',
  },
  subStatusAbbr: {
    in_pre_analysis_of_informations: 'Pre-analysis',
    draft: 'Sketch',
    waiting: 'Waiting',
    approved: 'Approved',
    finished: 'Finished',
    tomography_analysis: 'Tomography analysis',
    disapproved_no_contrast: 'Disapproved - DICOM Problems',
    disapproved_cuts_larger_than_1mm: 'Disapproved - DICOM Problems',
    disapproved_acquisition_date_over_6_months: 'Disapproved - DICOM Problems',
    disapproved_motion_artifact_3d: 'Disapproved - DICOM Problems',
    disapproved_impossibility_of_analyzing_the_3d_reconstruction:
      'Disapproved - DICOM Problems',
    disapproved_empty_file: 'Disapproved - DICOM Problems',
    disapproved_others: 'Disapproved - Other reasons',
    disapproved_no_lap_for_standard_care: 'Decline - No lap',
    disapproved_no_anatomical_indication_for_customized_treatment:
      'Disapproved - N/ anatomical indi.',
    disapproved_excessive_crookedness: 'Disapproved - Excessive crook.',
    disapproved_no_access: 'Declined - No access',
    disapproved_productive_impossibility: 'Declined - Productive impos.',
    discuss_and_change: 'Disapproved - Settings',
    disapproved_does_not_agree_with_the_proposal:
      'Disapproved - Does not agree',
    disapproved_patient_withdrew_from_treatment: 'Patient gave up',
    disapproved_patient_died: 'Patient died',
    disapproved_paying_source_did_not_accept: 'Source did not accept',
    disapproved_approved_by_another_company: 'Opted for another',
    very_complex_solution: 'Very complex solution',
    technically_approved: 'Technically Approved',
    commercially_approved: 'Commercially Approved',
    disapproved_finished: 'Finished',
    form_to_be_filled_out: 'Form to be filled out',
    registration_problems: 'Disapproved- Proble. i/ registration',
    disapproved_no_patient_access: 'Disapproved - No patient access',
    approved_specialist: 'Approved specialist',
    engineering_design_disapproved_requester:
      'Disapproved - Settings and details',
    engineering_design_disapproved_specialist:
      'Disapproved - Settings and details',
    engineering_design_waiting_schedule: 'Awaiting appointment',
    engineering_design_analisys: 'In project analysis',
    treatment_suggestion_repproved_dicom: 'Disapproved - DICOM Problems',
  },
  priorization: {
    all: 'All priorities',
  },
  date: {
    all: 'Every day',
    today: 'Today',
    lastWeek: 'Last 7 days',
    lastFifteen: 'Last 15 days',
    lastMonth: 'Last month',
  },
  table: {
    emptyTitle: 'Register your request',
    emptyDescription:
      "You don't have any requests yet. Register your first request and your information will be displayed here.",
    filters: {
      placeholder: 'Stage',
      priorization: {
        placeholder: 'Priority',
      },
      date: {
        placeholder: 'Date',
      },
    },
    search: {
      placeholder: 'Search',
    },
    newButton: 'New request',
    actions: {
      viewProgress: 'View progress',
      cancelRequest: 'Cancel request',
      approved: 'Commercial approval',
      messages: 'Messenger',
      declineRequest: 'Decline request',
      skipStep: 'Next step',
    },
  },
}
