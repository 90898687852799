export default {
  page: {
    title: 'Login',
  },
  form: {
    title: 'Bem vindo de volta',
    description: 'Faça login para entrar na plataforma',
    email: {
      label: 'Email',
      placeholder: 'Digite seu email',
    },
    password: {
      label: 'Senha',
    },
    withConnect: {
      text: 'Manter conectado',
    },
    forgotPassword: {
      text: 'Esqueci minha senha',
    },
    submit: {
      text: 'Entrar',
    },
  },
  errors: {
    'e-mail_or_password_incorrect': {
      title: 'Login falhou',
      message: 'Usuário ou senha inválida. Por favor tente novamente',
    },
    generic: {
      title: 'Error',
      message: 'Ocorreu um problema com a sua solicitação',
    },
  },
}
