export default {
  page: {
    selectRequest: 'Select the engineering project to be analyzed',
    withoutRequest: 'No project to analyze.',
    upload: {
      description: 'View or download engineering design',
    },
    productCode: 'Project code',
  },
  modal: {
    approve: {
      feedback: 'Engineering project approved.',
    },
    reprove: {
      title: 'Project disapproval',
      observation: {
        label: 'Disapproval notes',
      },
      button: 'Fail',
      feedback: 'Failed engineering project.',
    },
    settings: {
      title: 'Project adjustments',
      observation: {
        label: 'Adjustment notes',
      },
      button: 'Adjust',
      feedback: 'Engineering project sent for adjustments.',
    },
  },
}
