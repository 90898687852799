import { atom } from 'jotai'
import { languangeDefault } from '../../configs/i18n'
import { Language } from '../../types/language'

const valueDefault =
  typeof window !== 'undefined'
    ? (navigator.language as Language)
    : languangeDefault

export const languageAtom = atom<Language>(valueDefault)
