export default {
  page: {
    selectRequest: 'Seleccionar el proyecto de ingeniería a analizar',
    withoutRequest: 'Ningún proyecto para revisar.',
    upload: {
      description: 'Ver o descargar diseño de ingeniería',
    },
    productCode: 'Código de proyecto',
  },
  modal: {
    approve: {
      feedback: 'Proyecto de ingeniería aprobado.',
    },
    reprove: {
      title: 'Desaprobación del proyecto',
      observation: {
        label: 'Comentarios de desaprobación',
      },
      button: 'Fallar',
      feedback: 'Diseño de ingeniería fallido.',
    },
    settings: {
      title: 'Ajustes del proyecto',
      observation: {
        label: 'Notas de ajuste',
      },
      button: 'Ajustar',
      feedback: 'Proyecto de ingeniería enviado para ajustes.',
    },
  },
}
