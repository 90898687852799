export default {
  modal: {
    title: 'Dados do médico',
    description: 'Preencha as informações do médico',
    cancelButton: 'Cancelar',
    saveButton: 'Salvar',
    toast: {
      success: {
        title: 'Sucesso!',
        description: 'Médico cadastrado com sucesso!',
      },
      error: {
        title: 'Erro!',
        'e-mail_already_in_use':
          'E-mail já está sendo utilizado por outro médico',
        register: {
          generic: 'Houve um erro ao cadastrar o médico!',
        },
        update: {
          generic: 'Houve um erro ao atualizar o médico!',
        },
      },
    },
  },
  form: {
    name: {
      label: 'Nome',
      placeholder: 'Digite o nome do médico',
    },
    consultant: {
      placeholder: 'Digite o nome do consultor',
    },
    email: {
      label: 'Email',
      placeholder: 'Digite o email do médico',
    },
    phone: {
      label: 'Celular',
    },
    language: {
      label: 'Idioma',
      placeholder: 'Selecione uma opção',
    },
    telephone: {
      label: 'Telefone',
    },
    invite: {
      title: 'Tornar médico usuário da plataforma',
      description:
        'Logo após a finalização do cadastro, será enviado um convite por e-mail para que o médico se torne um usuário da plataforma.',
    },
  },
  table: {
    newButton: 'Novo médico',
    actions: {
      update: 'Alterar cadastro',
      updateSpecialist: 'Alterar consultor',
      delete: 'Excluir cadastro',
      resend: 'Reenviar convite',
      resetPassword: 'Redefinir senha',
    },
    emptyTitle: 'Cadastre um médico',
    emptyDescription:
      'Você ainda não possui nenhum médico. Cadastre seu primeiro médico e suas informações serão exibidas aqui.',
  },
  register: {
    title: 'Cadastro Médico',
    cancelButton: 'Cancelar',
    saveButton: 'Salvar',
    sections: {
      title: 'Dados do médico',
      description: 'Preenche as informações do médico',
    },
    modal: {
      success: {
        title: 'Sucesso',
        description: 'Esta operação foi realizada com sucesso.',
      },
      noUser: 'Dados do médico salvos.',
      isUser: 'Dados do médico salvos e convite enviado por e-mail.',
      error: {
        title: 'Erro',
      },
    },
  },
  update: {
    title: 'Edição de Médico',
    actionButton: 'Editar informações',
  },
  exclude: {
    modal: {
      initial: {
        title: 'Exclusão de médico',
        description: 'Tem certeza de que deseja excluir esse médico?',
      },
      success: {
        title: 'Sucesso',
        description: 'Essa operação foi realizada com sucesso.',
        passwordReset: 'Link para redefinição de senha foi enviado ao médico.',
        exclude: 'Dados do médico excluídos.',
        invite: 'Convite reenviado ao médico',
      },
      error: {
        title: 'Erro',
      },
    },
  },
}
