export default {
  page: {
    title: 'Registrar',
  },
  loading: {
    title: 'Cargando',
    description: 'Por favor espere el final de la solicitud.',
  },
  register: {
    title: 'Haz tu registro',
    description: 'Rellena los datos para completar tu registro.',
    form: {
      email: {
        label: 'Email',
        placeholder: 'Escriba su email',
      },
      password: {
        label: 'Clave',
      },
      confirmPassword: {
        label: 'Confirmar seña',
      },
      button: 'Registrar',
    },
    hasAccount: '¿Ya tienes una cuenta?',
    redirectLogin: 'Acceso',
  },
  success: {
    title: 'Éxito!',
    description: {
      p1: 'Su cuenta de usuario ha sido creada con éxito.',
      p2: 'Ahora puede gestionar las solicitudes de tratamiento.',
    },
    hasAccount: '¿Ya tienes una cuenta?',
    redirectLogin: 'Acceso',
  },
  notFound: {
    title: 'Token no encontrado!',
    description: {
      p1: 'Token no encontrado en su invitación.',
      p2: 'Contacto support@email.com.br',
    },
  },
  errorLoading: {
    title: 'Error de carga',
    description: {
      p1: 'Ocurrió un error al cargar este formulario.',
      p2: 'Se recomienda no utilizar este formulario para el ingreso de datos hasta que esto se resuelva.',
    },
    error: {
      text: 'Póngase en contacto con support@email.com.br con el enlace a esta página y el siguiente mensaje de error:',
      message: 'token inesperado < en JSON en la posición 0',
    },
  },
  expired: {
    title: '¡Acceso no autorizado!',
    description: {
      p1: 'Su token de autenticación ha caducado.',
    },
  },
  invalid: {
    title: '¡Simbolo no valido!',
    description: {
      p1: 'Su nueva autenticación de token de contraseña no es válida.',
      p2: 'Inténtalo de nuevo.',
    },
  },
}
