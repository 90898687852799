export default {
  page: {
    title: 'Change password',
  },
  form: {
    firstStep: {
      title: 'Change my password',
      description:
        'Enter the email associated with your account and we will send you instructions to change your password.',
    },
    secondStep: {
      title: 'Check your email',
      description:
        'We have sent you instructions to change your password by email. Remember to check your spam box.',
      p1: 'Did not get the email?',
      p2: 'Send new link',
    },
    email: {
      label: 'Email',
      placeholder: 'Type your e-mail',
    },
    submit: {
      text: 'Send instructions by email',
    },
  },
}
