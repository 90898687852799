export default {
  page: {
    title: 'Login',
  },
  form: {
    title: 'Welcome back',
    description: 'Log in to enter the platform',
    email: {
      label: 'Email',
      placeholder: 'Type your e-mail',
    },
    password: {
      label: 'Password',
    },
    withConnect: {
      text: 'keep connected',
    },
    forgotPassword: {
      text: 'I forgot my password',
    },
    submit: {
      text: 'Login',
    },
  },
  errors: {
    'e-mail_or_password_incorrect': {
      title: 'Login error',
      message: 'Invalid username or password. Please try again',
    },
    generic: {
      title: 'Error',
      message: 'There was a problem with your request',
    },
  },
}
