export default {
  page: {
    title: 'Peticiones',
  },
  status: {
    all: 'Todos los estados',
    finished: 'Acabado',
    '3d_aorta_printing': 'Impresión 3D de aorta',
    engineering_design: 'Diseño de ingeniería',
    exam_analysis_sketch: 'Análisis de examen/contorno',
    initial_analysis_and_dicom_images: 'Análisis inicial e imágenes Dicom',
    treatment_suggestion: 'Sugerencia de tratamiento',
    canceled: 'Cancelado',
    endoprosthesis_manufacturing: 'Fabricación de endoprótesis',
    making_the_prototype: 'Haciendo el prototipo',
    draft: 'Reclutar',
    screening: 'Poner en pantalla',
    tomography_analysis: 'Análisis de tomografía',
    project_approval: 'Aprobación del proyecto',
    prototype: 'Prototipo',
    logistics: 'Logística',
    waiting_for_the_surgery: 'Esperando la cirugía',
    feedback_about_the_surgery: 'Comentarios sobre la cirugía',
    declined: 'Rechazado',
  },
  subStatus: {
    in_pre_analysis_of_informations: 'En el pre-análisis de la información',
    draft: 'Reclutar',
    waiting: 'Esperando',
    approved: 'Aprobado',
    finished: 'Acabado',
    disapproved_no_contrast: 'Sin contraste',
    tomography_analysis: 'Análisis de tomografía',
    disapproved_cuts_larger_than_1mm: 'Cortes de más de 1 mm',
    disapproved_acquisition_date_over_6_months:
      'Fecha de adquisición superior a 6 meses',
    disapproved_motion_artifact_3d: 'Artefacto de movimiento (3D)',
    disapproved_impossibility_of_analyzing_the_3d_reconstruction:
      'Imposibilidad de analizar la reconstrucción 3D',
    disapproved_empty_file: 'Archivo vacío',
    disapproved_others: 'Otros',
    disapproved_no_lap_for_standard_care:
      'Sin vuelta para el tratamiento estándar',
    disapproved_no_anatomical_indication_for_customized_treatment:
      'Sin indicación anatómica para un tratamiento personalizado',
    disapproved_excessive_crookedness: 'Tortuosidad excesiva',
    disapproved_no_access: 'Sin acceso',
    disapproved_productive_impossibility: 'Imposibilidad productiva',
    discuss_and_change: 'Discutir y cambiar',
    disapproved_does_not_agree_with_the_proposal:
      'No está de acuerdo con la propuesta',
    disapproved_patient_withdrew_from_treatment:
      'Paciente se retiró del tratamiento',
    disapproved_patient_died: 'El paciente murió',
    disapproved_paying_source_did_not_accept: 'Fuente de pago no aceptada',
    disapproved_approved_by_another_company: 'Designado por otra empresa',
    very_complex_solution: 'Solución muy compleja',
    technically_approved: 'Técnicamente Aprobado',
    commercially_approved: 'Aprobado Comercialmente',
    disapproved_finished: 'Acabado',
    form_to_be_filled_out: 'Formulario a llenar',
    registration_problems: 'Problemas en el registro',
    disapproved_no_patient_access: 'No hay acceso del paciente',
    approved_specialist: 'Especialista homologado',
    disapproved_requester: 'Solicitante fallido',
    disapproved_specialist: 'Especialista fallido',
    waiting_schedule: 'En espera de cita',
    analisys: 'En el análisis de proyectos',
  },
  subStatusAbbr: {
    in_pre_analysis_of_informations: 'Pre-análisis',
    draft: 'Bosquejo',
    waiting: 'Espera',
    approved: 'Aprobado',
    finished: 'Finalizado',
    tomography_analysis: 'Análisis de tomografía',
    disapproved_no_contrast: 'Rechazado - Problemas DICOM',
    disapproved_cuts_larger_than_1mm: 'Rechazado - Problemas DICOM',
    disapproved_acquisition_date_over_6_months: 'Rechazado - Problemas DICOM',
    disapproved_motion_artifact_3d: 'Rechazado - Problemas DICOM',
    disapproved_impossibility_of_analyzing_the_3d_reconstruction:
      'Rechazado - Problemas DICOM',
    disapproved_empty_file: 'Rechazado - Problemas DICOM',
    disapproved_others: 'Rechazado - Otras razones',
    disapproved_no_lap_for_standard_care: 'Declinado - Sin vuelta',
    disapproved_no_anatomical_indication_for_customized_treatment:
      'Rechazado - S/ indi. anatómica',
    disapproved_excessive_crookedness: 'Rechazado - Tort. excesiva',
    disapproved_no_access: 'Declinado - Sin acceso',
    disapproved_productive_impossibility: 'Declinado - Impos. productiva',
    discuss_and_change: 'Rechazado - Ajustes',
    disapproved_does_not_agree_with_the_proposal:
      'Rechazado - No está de acuerdo',
    disapproved_patient_withdrew_from_treatment:
      'El paciente se dio por vencido',
    disapproved_patient_died: 'El paciente murió',
    disapproved_paying_source_did_not_accept: 'La fuente no aceptó',
    disapproved_approved_by_another_company: 'Optó por otro',
    very_complex_solution: 'Solución muy compleja',
    technically_approved: 'Técnicamente Aprobado',
    commercially_approved: 'Aprobado Comercialmente',
    disapproved_finished: 'Acabado',
    form_to_be_filled_out: 'Formulario a llenar',
    registration_problems: 'Rechazado - Proble. registro',
    disapproved_no_patient_access: 'Rechazado - N/ acc. del paciente',
    approved_specialist: 'Especialista homologado',
    engineering_design_disapproved_requester: 'Rechazado - Config. y detalles',
    engineering_design_disapproved_specialist: 'Rechazado - Config. y detalles',
    engineering_design_waiting_schedule: 'En espera de cita',
    engineering_design_analisys: 'En el análisis de proyectos',
    treatment_suggestion_repproved_dicom: 'Rechazado - Problemas DICOM',
  },
  priorization: {
    all: 'Todas las prioridades',
  },
  date: {
    all: 'Todos los días',
    today: 'Este Dia',
    lastWeek: 'Los últimos 7 días',
    lastFifteen: 'Últimos 15 días',
    lastMonth: 'El mes pasado',
  },
  table: {
    emptyTitle: 'Registra tu solicitud',
    emptyDescription:
      'Aún no tienes ninguna solicitud. Registre su primera solicitud y su información se mostrará aquí.',
    filters: {
      placeholder: 'Escenario',
      priorization: {
        placeholder: 'Prioridad',
      },
      date: {
        placeholder: 'Fecha',
      },
    },
    search: {
      placeholder: 'Buscar',
    },
    newButton: 'Nueva solicitud',
    actions: {
      viewProgress: 'Ver el progreso',
      cancelRequest: 'Cancelar petición',
      approved: 'Aprobación comercial',
      messages: 'Mensajero',
      declineRequest: 'Rechazar solicitud',
      skipStep: 'Próximo paso',
    },
  },
}
