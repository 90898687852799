export default {
  page: {
    selectRequest: 'Selecione o projeto de engenharia ser analisado',
    withoutRequest: 'Sem projeto para analisar.',
    upload: {
      description: 'Visualize ou faça o download do projeto de engenharia',
    },
    productCode: 'Código do projeto',
  },
  modal: {
    approve: {
      feedback: 'Projeto de engenharia aprovado.',
    },
    reprove: {
      title: 'Reprovação de projeto',
      observation: {
        label: 'Observações de reprovação',
      },
      button: 'Reprovar',
      feedback: 'Projeto de engenharia reprovado.',
    },
    settings: {
      title: 'Ajustes de projeto',
      observation: {
        label: 'Observações de ajustes',
      },
      button: 'Ajustar',
      feedback: 'Projeto de engenharia foi enviado para ajustes.',
    },
  },
}
