export default {
  sections: {
    patient: {
      title: 'Patient data',
      description: 'Enter patient data here for pre-analysis',
    },
    treatmentLocation: {
      title: 'Treatment Location Data',
      description:
        'Inform the data of the place where the treatment will be carried out',
    },
    payingSource: {
      title: 'Paying Source Data',
      description: "Inform here how the patient's treatment will be paid",
    },
    diseases: {
      title: 'Diseases and more information',
      description:
        'Select one or more disease options. Inform the date of the procedure, clinical history, and proposed current treatment of the patient.',
    },
    questions: {
      title: 'General Questions',
      description:
        'Fill in the information to proceed with service prioritization',
    },
    upload: {
      title: 'Necessary documents',
      description:
        'Enter the required documents. Choose one or more files you want to transfer.',
      help: {
        title: 'To attach a DICOM, you need to compress the file first.',
        description:
          'To compress a file on Mac, follow the instructions below:',
        options: {
          1: 'Right-click on the folder you want to compress;',
          2: 'Select "Compress" from the menu and wait for compression;',
          3: 'When the process is complete, the file will have the name of the folder with the extension ".zip".',
        },
      },
    },
    priorization: {
      title: 'Prioritization',
    },
  },
  modal: {
    title: 'Proposal sent successfully.',
    description:
      'Your review request has been successfully sent and is subject to review of information and documents.',
    protocol: 'Your protocol number',
    titles: {
      send: 'Request sent',
      save: 'Success',
    },
    descriptions: {
      send: "Your request has been sent successfully. Wait for the expert's review.",
      save: 'Patient data has been updated.',
      thanks: 'Congratulations!',
    },
    back: {
      title: 'Application Registration',
      description:
        'You are exiting the request register and are about to lose the information that has already been filled in.',
      question: 'Are you sure you want to quit?',
      toast: {
        title: 'Unsaved information',
        description: 'The entered information has been lost.',
      },
    },
  },
  form: {
    name: {
      label: 'Name',
      placeholder: "Enter the patient's full name",
    },
    genre: {
      label: 'Genre',
      placeholder: 'Select an option',
    },
    birthDate: {
      label: 'Birth date',
    },
    hospital: {
      label: 'Hospital',
      placeholder: 'Enter the hospital name',
    },
    country: {
      label: 'Country',
      placeholder: 'Enter country name',
    },
    state: {
      label: 'State',
      placeholder: 'Enter the state name',
    },
    city: {
      label: 'City',
      placeholder: 'Enter the city name',
    },
    doctor: {
      label: 'Doctor',
      placeholder: "Enter the doctor's name",
    },
    payingSourceType: {
      label: 'Paying Source Type',
      placeholder: 'Select an option',
    },
    payingSource: {
      label: 'Paying Source',
    },
    payingSourceDescription: {
      label: 'Paying source add-on',
      placeholder: 'Enter the Paying Source',
    },
    diseases: {
      label: 'Diseases',
    },
    clinicalHistory: {
      label: 'Clinical history',
      placeholder: "Enter the patient's medical history",
    },
    treatmentProposal: {
      label: 'Treatment proposal',
      placeholder:
        'Patient with bilateral iliac artery aneurysm. Treatment intention: embolization of the right hypogastric artery and custom branched endoprosthesis for the left common iliac artery.',
    },
    comments: {
      label: 'Comments',
      placeholder: 'Enter notes for prioritization here',
    },
    dicom: {
      label: 'Dicom Document',
    },
    medicalReport: {
      label: 'Medical report',
    },
    projectSketch: {
      label: 'Project sketch',
    },
    treatmentSuggestion: {
      label: 'Treatment Suggestion',
    },
    procedure_date: {
      label: 'Procedure date',
    },
  },
  questions: {
    paciente_esta_internado: 'Is the patient hospitalized?',
    paciente_esta_com_dor: 'Is the patient in pain?',
    se_sim_paciente_aguardara_a_cirurgia_internado:
      'If yes, will the patient wait for the surgery in hospital?',
  },
}
