export default {
  page: {
    title: 'Login',
  },
  form: {
    title: 'Bienvenido de nuevo',
    description: 'Inicia sesión para ingresar a la plataforma',
    email: {
      label: 'Email',
      placeholder: 'Escriba su email',
    },
    password: {
      label: 'Clave',
    },
    withConnect: {
      text: 'Mantente conectado',
    },
    forgotPassword: {
      text: 'Olvide mi contraseña',
    },
    submit: {
      text: 'Iniciar sesión',
    },
  },
  errors: {
    'e-mail_or_password_incorrect': {
      title: 'Error de inicio de sesion',
      message: 'Usuario o contraseña invalido. Inténtalo de nuevo',
    },
    generic: {
      title: 'Error',
      message: 'Hubo un problema con su solicitud',
    },
  },
}
