export default {
  page: {
    title: 'Agendar Projeto',
    schedule: {
      title: 'Novo projeto de engenharia',
      observation: 'Mova o novo projeto de engenharia para o dia desejado',
    },
  },
  scheduleModal: {
    title: 'Mover Projeto de engenharia',
    description: 'Escolha a melhor data pra mover o projeto de engenharia',
    canMove: 'Pode ser movido',
    cantMove: 'Não pode ser movido',
    moveButton: 'Mover',
    scheduleTitle: 'Agendamento de projeto',
    observation: {
      title: 'Observações de agendamento',
      placeholder:
        'Digite aqui alguma observação ou informação que você ache pertinente',
    },
    descriptions: {
      reset:
        'Tem certeza que deseja resetar o agendamento do projeto de engenharia?',
      cancel:
        'Tem certeza que deseja cancelar o agendamento do projeto de engenharia?',
      priorizate:
        'Tem certeza que deseja priorizar o(s) projeto de engenharia?',
      success:
        'Essa operação foi realizada com sucesso. Projeto de engenharia agendado para outra data.',
    },
  },
  legends: {
    scheduled: 'Agendado',
    reproved: 'Reprovado',
    sent: 'Enviado',
    settings: 'Ajustes',
    approved: 'Aprovado',
  },
}
