export default {
  page: {
    observation: {
      title: 'Observações/ajustes',
      specialist: 'Observação especialista',
      requester: 'Observação solicitante',
      empty: 'Nenhuma observação',
      project_analyst: 'Observação produção',
    },
    analysis: {
      title: 'Analisar',
    },
    withoutRequest: 'Você não pode mais analisar nenhum projeto hoje',
  },
  modal: {
    title: 'Dados do projeto de engenharia',
    sections: {
      filesPatient: 'Documentação do paciente',
      upload: {
        description: 'Anexe aqui o projeto',
      },
    },
    send: {
      title: 'Enviar para a aprovação',
      description: 'Envie o projeto para aprovação',
    },
  },
  analysis: {
    upload: {
      title: 'Upload de projeto de engenharia',
      description: 'Faça o upload do projeto de engenharia',
    },
    products: {
      title: 'Código e referência do produto',
      description: 'Digite o código e a referencia dos itens da endoprótese',
    },
    prototype: {
      title: 'Necessidade de um protótipo',
      description:
        'Após a análise do projeto de engenharia, será necessário um protótipo?',
    },
    modal: {
      titles: {
        approve: 'Projeto de engenharia',
        cancel: 'Cadastro de solicitação',
        remove: 'Excluir código',
        success: 'Sucesso',
      },
      descriptions: {
        approve: 'Tem certeza que deseja enviar o projeto de engenharia?',
        cancel:
          'Você está prestes a perder as informações que já foram preenchidas no projeto de engenaharia. Tem certeza que deseja sair?',
        remove:
          'Tem certeza que deseja excluir esse campo de código e  referência do produto?',
        success:
          'Essa operação foi realizada com sucesso. Projeto de engenharia enviado.',
      },
    },
  },
}
