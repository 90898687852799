export default {
  page: {
    observation: {
      title: 'Notes/adjustments',
      specialist: 'Specialist observation',
      requester: 'Requesting observation',
    },
    analysis: {
      title: 'Analyze',
    },
  },
  modal: {
    title: 'Engineering design data',
    sections: {
      filesPatient: 'Patient documentation',
      upload: {
        description: 'Attach the project here',
      },
    },
    send: {
      title: 'Submit for approval',
      description: 'Submit the project for approval',
    },
  },
  analysis: {
    upload: {
      title: 'Upload engineering project',
      description: 'Upload the engineering project',
    },
    products: {
      title: 'Product code and reference',
      description: 'Enter the code and reference of the endoprosthesis items',
    },
    prototype: {
      title: 'Need for a prototype',
      description:
        'After reviewing the engineering design, will a prototype be required?',
    },
    modal: {
      titles: {
        approve: 'Engineering design',
        cancel: 'Request registration',
        remove: 'Delete code',
        success: 'Success',
      },
      descriptions: {
        approve: 'Are you sure you want to submit the engineering design?',
        cancel:
          'You are about to lose information that has already been filled in on the engineering project. Are you sure you want to quit?',
        remove:
          'Are you sure you want to delete this product code and reference field?',
        success:
          'This operation was successfully performed. Engineering design submitted.',
      },
    },
  },
}
