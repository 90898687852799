export default {
  dateTime: {
    day: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    longDay: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    at: 'at',
    today: 'Today',
  },
  errors: {
    form: {
      required: 'This field is required',
      minArray: 'The field must have at least 1 items',
      confirmPassword: 'Passwords are not the same',
      email: 'This is an invalid email',
      biggerThanToday: "Procedure date must be greater than today's date",
      passwordLength: 'The password must contain at least 8 digits',
      passwordInvalid:
        'The password must contain at least one uppercase letter, one lowercase letter, one number and one special character.',
    },
    generic: {
      title: 'Error',
      description: 'There was an error on our servers, please try again later!',
    },
  },
  roles: {
    doctor: 'Doctor',
    admin: 'Admin',
    consultant: 'Consultant',
    specialist: 'Specialist',
    distributor: 'Distributor',
    designer: 'Designer',
    estimator: 'Estimator',
    draftsman: 'Draftsman',
    production_analyst: 'Production Analyst',
  },
  table: {
    default: {
      emptyTitle: "We didn't find anything",
      emptyDescription: 'No data was obtained',
    },
  },
  wizards: {
    stepPrefix: 'Step',
    stepOf: 'of',
  },
  genre: {
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
  payingSourceType: {
    health_insurance: 'Health insurance',
    sus: 'Public Health System',
    particular: 'Particular',
    plano_saude: 'Health plan',
    outros: 'Others',
  },
  diseases: {
    aaa: 'AAA',
    coarctation: 'Coarctation',
    taa: 'TAA',
    dissection: 'Dissection',
    endoleak: 'Endoleak',
    bruise: 'Bruise',
    iliac: 'Iliac',
    ulcer: 'Ulcer',
    thoracoabdominal: 'Thoracoabdominal',
  },
  generic: {
    yes: 'Yes',
    not: 'No',
    noOptions: 'No options',
    all: 'All',
    days: 'days',
  },
  upload: {
    completed: 'Uploading finished files',
    cancel: 'CANCEL',
    error: {
      title: 'Upload with error',
      description: 'Unable to upload the file, please try again.',
    },
    finish: {
      title: 'Upload completed',
      description: 'Your file has been successfully uploaded.',
    },
    starting: 'Starting upload',
    loading: 'Uploading files',
    canceled: 'Upload canceled',
    dragDrop: 'Drag and drop or',
    clickSearch: 'click to search',
    fileValid: 'Valid file, release to select',
    fileNotValid: 'File not allowed',
    limitSize: 'Maximum allowed size up to',
  },
  words: {
    date: 'Date',
  },
  languages: {
    'pt-BR': 'Portuguese',
    'es-ES': 'Spanish',
    'en-US': 'English',
  },
  actions: {
    response: {
      understood: 'I understood',
    },
  },
  file: {
    modal: {
      exclude: {
        title: 'Delete file',
        description: 'Are you sure you want to delete this file?',
        success: {
          title: 'Success',
          description:
            'This operation was successfully performed. Deleted file.',
        },
      },
    },
  },
}
