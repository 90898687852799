export default {
  links: {
    requests: 'Requests',
    catalogEndoprosthesis: 'Endoprosthesis catalog',
    logout: 'Logout',
    editProfile: 'Edit Profile',
    doctor: 'Doctors',
    hospital: 'Hospitals',
    dashboard: 'Dashboard',
    priorizate: 'Project prioritization',
    user: 'Users',
  },
}
