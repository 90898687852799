export default {
  request: {
    title: 'Paciente',
    description: 'Status da Solicitação',
    list: {
      progress: 'Andamento',
      preAnalysis: 'Solicitação',
      treatmentSuggestion: 'Sugestão de tratamento',
      project: 'Projeto',
      message: 'Mensageria',
    },
    preAnalysis: {
      editButton: 'Editar informações',
      saveButton: 'Salvar',
      sendButton: 'Salvar e enviar',
      cancelButton: 'Cancelar',
      canceledButton: 'Cancelar',
      declinedButton: 'Declinar',
      approveButton: 'Aprovar',
      reproveButton: 'Reprovar',
      skipStepButton: 'Avançar',
      continueButton: 'OK',
      backButton: 'Voltar',
      modal: {
        success: {
          title: 'Sucesso',
          description: 'Essa operação foi realizada com sucesso',
          canceled: 'Solicitação cancelada',
          declined: 'Solicitação declinada',
          skipStep: 'Próxima etapa avançada com sucesso.',
          descriptions: {
            declined: 'Solicitação declinada em análise de tomografia.',
            repproved_pre_analysis: 'Solicitação reprovada em pré-análise.',
            repproved_tomography: 'Análise de tomografia reprovada',
            approve_pre_analysis: 'Solicitação aprovada em pré-análise.',
            approve_tomography:
              'Solicitação aprovada em análise de tomografia.',
          },
        },
        title: {
          approve: 'Informe o status da solicitação',
          reprove: 'Informe o motivo da reprovação',
          cancel: 'Informe o motivo do cancelamento',
          canceled: 'Informe o motivo do cancelamento',
          declined: 'Informe o motivo do declínio',
          repproved_pre_analysis: 'Informe o motivo da reprovação',
          repproved_tomography: 'Informe o motivo da reprovação',
          approve_pre_analysis: 'Aprovação de pré-análise',
          approve_tomography: 'Informe para qual etapa será avançada',
          skipStep: 'Informe para qual etapa será avançada',
        },
        status: {
          approve: 'Status',
          reprove: 'Motivo',
          declined: 'Motivo',
          placeholder: 'Selecione um dos motivos',
          repproved_pre_analysis: 'Motivo',
          approve_pre_analysis: 'Motivo',
          repproved_tomography: 'Motivo',
          approve_tomography: 'Etapa',
          skipStep: 'Etapa',
        },
        subStatus: 'Sub motivo',
        observation: {
          label: 'Observação',
          placeholder:
            'Digite aqui alguma observação ou informação que você ache pertinente',
        },
        reprove: {
          options: {
            dicon: 'Problemas no arquivo DICOM',
          },
        },
      },
      approve: {
        title: 'Atenção',
        descriptions: {
          in_pre_analysis_of_informations:
            'Essa etapa requer duas aprovações, a Pré-análise de informações e Análise de tomografia.',
          tomography_analysis:
            'Você já fez a pré análise de informações, agora faça a análise de tomografia.',
        },
      },
    },
    progress: {
      title: 'Acompanhe o status do pedido',
      tableTile: 'Histórico',
      seeLess: 'Ver menos',
      viewMore: 'Ver mais',
      reproved: 'Reprovado',
      declined: 'Declinado',
      canceled: 'Cancelado',
      skiped: '(Etapa pulada)',
      titles: {
        draft: 'Rascunho',
        waiting: 'Aguardando',
        approved: 'Aprovado',
        engineering_design_draft: 'Rascunho',
        finished: 'Solicitação Finalizada',
        in_pre_analysis_of_informations: 'Pré análise',
        tomography_analysis: 'Análise de tomografia',
        disapproved_no_contrast: 'Sem contraste',
        disapproved_cuts_larger_than_1mm: 'Cortes maiores que 1mm',
        disapproved_acquisition_date_over_6_months:
          'Data de aquisição acima de 6 meses',
        disapproved_motion_artifact_3d: 'Artefato de movimento(3D)',
        disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Imposibilidade de analise da reconstrução 3D',
        disapproved_empty_file: 'Arquivo vazio',
        disapproved_others: 'Outros motivos',
        disapproved_no_lap_for_standard_care: 'Sem colo para tratamento padrão',
        disapproved_no_anatomical_indication_for_customized_treatment:
          'Sem indicação anatômica para tratamento customizado',
        disapproved_excessive_crookedness: 'Tortuosidade excessiva',
        disapproved_no_access: 'Sem acesso',
        disapproved_productive_impossibility: 'Impossibilidade produtiva',
        discuss_and_change: 'Discutir e Alterar',
        disapproved_does_not_agree_with_the_proposal:
          'Não concorda com a proposta',
        disapproved_patient_withdrew_from_treatment:
          'Paciente desistiu do tratamento',
        disapproved_patient_died: 'Paciente faleceu',
        disapproved_paying_source_did_not_accept: 'Fonte pagadora não aceitou',
        disapproved_approved_by_another_company: 'Optou por outra empresa',
        very_complex_solution: 'Solução muito complexa',
        technically_approved: 'Aprovado Tecnicamente',
        commercially_approved: 'Aprovado Comercialmente',
        disapproved_finished: 'Finalizado',
        form_to_be_filled_out: 'Formulário a ser preenchido',
        registration_problems: 'Problemas no cadastro',
        disapproved_no_patient_access: 'Sem acesso do paciente',
        commercially_waiting: 'Aguardando comercialmente',
        approved_specialist: 'Aprovado especialista',
        engineering_design_disapproved_requester:
          'Reprovado - Ajustes e detalhes',
        engineering_design_disapproved_specialist:
          'Reprovado - Ajustes e detalhes',
        engineering_design_waiting_schedule: 'Aguardando agendamento',
        engineering_design_analisys: 'Em análise de projeto',
        treatment_suggestion_repproved_dicom: 'Problemas no arquivo DICOM',
        engineering_project_analisys_production:
          'Em análise de projeto - Produção',
        engineering_approved_production: 'Aprovado produção',
        engineering_repproved_production: 'Reprovado produção',
        engineering_production_settings_production: 'Ajustes de produção',
      },
      subStatus: {
        draft: 'Rascunho',
        waiting: 'Aguardando',
        engineering_design_draft: 'Rascunho',
        approved: 'Aprovado',
        finished: 'Solicitação Finalizada',
        in_pre_analysis_of_informations: 'Pré análise',
        tomography_analysis: 'Análise de tomografia',
        disapproved_no_contrast: 'Reprovado - Sem contraste',
        disapproved_cuts_larger_than_1mm: 'Reprovado - Cortes maiores que 1mm',
        disapproved_acquisition_date_over_6_months:
          'Reprovado - Data de aquisição acima de 6 meses',
        disapproved_motion_artifact_3d: 'Reprovado - Artefato de movimento(3D)',
        disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Reprovado - Imposibilidade de analise da reconstrução 3D',
        disapproved_empty_file: 'Reprovado - Arquivo vazio',
        disapproved_others: 'Reprovado - Outros motivos',
        disapproved_no_lap_for_standard_care:
          'Reprovado - Sem colo para tratamento padrão',
        disapproved_no_anatomical_indication_for_customized_treatment:
          'Reprovado - Sem indicação anatômica para tratamento customizado',
        disapproved_excessive_crookedness: 'Reprovado - Tortuosidade excessiva',
        disapproved_no_access: 'Declinado - Sem acesso',
        disapproved_productive_impossibility:
          'Declinado - Impossibilidade produtiva',
        discuss_and_change: 'Reprovado - Discutir e Alterar',
        disapproved_does_not_agree_with_the_proposal:
          'Não concorda com a proposta',
        disapproved_patient_withdrew_from_treatment:
          'Declinado - Paciente desistiu do tratamento',
        disapproved_patient_died: 'Paciente faleceu',
        disapproved_paying_source_did_not_accept:
          'Declinado - Fonte pagadora não aceitou',
        disapproved_approved_by_another_company: 'Optou por outra empresa',
        very_complex_solution: 'Solução muito complexa',
        technically_approved: 'Aprovado Tecnicamente',
        commercially_approved: 'Aprovado Comercialmente',
        disapproved_finished: 'Finalizado',
        form_to_be_filled_out: 'Formulário a ser preenchido',
        registration_problems: 'Reprovado - Problemas no cadastro',
        disapproved_no_patient_access: 'Declinado - Sem acesso ao paciente',
        commercially_waiting: 'Aguardando comercialmente',
        approved_specialist: 'Aprovado especialista',
        engineering_design_disapproved_requester:
          'Reprovado - Ajustes e detalhes',
        engineering_design_disapproved_specialist:
          'Reprovado - Ajustes e detalhes',
        engineering_design_waiting_schedule: 'Aguardando agendamento',
        engineering_design_analisys: 'Em análise de projeto',
        treatment_suggestion_repproved_dicom:
          'Reprovado - Problemas no Arquivo DICOM',
        engineering_project_analisys_production:
          'Em análise de projeto - Produção',
        engineering_approved_production: 'Aprovado produção',
        engineering_repproved_production: 'Reprovado produção',
        engineering_production_settings_production: 'Ajustes de produção',
      },
      descriptions: {
        draft_waiting: 'Complete o cadastro da solicitação',
        screening_waiting: 'Uma nova solicitação foi criada.',
        screening_in_pre_analysis_of_informations:
          'Solicitação em triagem, sendo feita uma pré-análise de informações.',
        screening_tomography_analysis:
          'Solicitação em triagem, sendo feita uma análise de tomografia.',
        screening_disapproved_no_contrast:
          'Solicitação foi reprovada pré-análise por problemas no arquivo DICOM, para prosseguir edite a solicitação.',
        screening_disapproved_cuts_larger_than_1mm:
          'Solicitação foi reprovada pré-análise por problemas no arquivo DICOM, para prosseguir edite a solicitação.',
        screening_disapproved_acquisition_date_over_6_months:
          'Solicitação foi reprovada pré-análise data de aquisição acima de 6 meses',
        screening_disapproved_motion_artifact_3d:
          'Solicitação foi reprovada pré-análise artefato de movimento(3D)',
        screening_disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Solicitação foi reprovada pré-análise imposibilidade de analise da reconstrução 3D',
        screening_disapproved_empty_file: 'Arquivo vazio',
        screening_tomography_analysis_disapproved_others:
          'Tomografia reprovada por outros motivos, para prosseguir entre em um acordo com os especialista.',
        screening_disapproved_others:
          'Solicitação reprovada em pré-análise por outros motivos.',
        screening_disapproved_no_lap_for_standard_care:
          'Solicitação declinada por não tem colo para tratamento padrão.',
        screening_tomography_analysis_disapproved_no_lap_for_standard_care:
          'Solicitação declinada por não tem colo para tratamento padrão.',
        declined_disapproved_no_anatomical_indication_for_customized_treatment:
          'Tomografia reprovada por não tem indicação anatômica, para prosseguir entre em um acordo com os especialista.',
        declined_disapproved_no_lap_for_standard_care:
          'Solicitação declinada por não tem colo para tratamento padrão.',
        screening_tomography_analysis_disapproved_no_anatomical_indication_for_customized_treatment:
          'Tomografia reprovada por não tem indicação anatômica, para prosseguir entre em um acordo com os especialista.',
        screening_disapproved_excessive_crookedness:
          'Tomografia reprovada por ter tortuosidade excessiva, para prosseguir entre em um acordo com os especialista.',
        screening_disapproved_productive_impossibility:
          'Solicitação declinada por impossibilidade produtiva.',
        declined_disapproved_productive_impossibility:
          'Solicitação declinada por impossibilidade produtiva.',
        screening_very_complex_solution: 'Solução muito complexa',
        screening_form_to_be_filled_out: 'Formulário a ser preenchido',
        screening_registration_problems:
          'Solicitação foi reprovada em pré-análise por problemas no cadastro, para prosseguir edite a solicitação.',
        screening_disapproved_no_patient_access:
          'Solicitação declinada por não ter acesso ao paciente.',
        declined_disapproved_no_patient_access:
          'Solicitação declinada por não ter acesso ao paciente.',
        treatment_suggestion_draft:
          'Solicitação aguardando a sugestão de tratamento do especialista.',
        treatment_suggestion_waiting:
          'Sugestão de tratamento aguardando aprovação.',
        treatment_suggestion_disapproved_others:
          'Sugestão de tratamento reprovada, por outros motivos, para prosseguir entre em um acordo.',
        treatment_suggestion_disapproved_does_not_agree_with_the_proposal:
          'Sugestão de tratamento reprovada, não concordaram com mesma, para prosseguir entre em um acordo.',
        treatment_suggestion_approved:
          'Sugestão de tratamento aprovada, prosseguir para próxima etapa.',
        treatment_suggestion_commercially_waiting: 'Aguardando comercialmente',
        endoprosthesis_manufacturing_draft:
          'Fabricação de endoprótese aguardando produção',
        prototype_draft: 'Protótipo aguardando produção',
        engineering_design_draft: 'Projeto de engenharia aguardando produção.',
        endoprosthesis_manufacturing_waiting:
          'Fabricação de endoprótese aguardando aprovação.',
        prototype_waiting: 'Protótipo aguardando aprovação.',
        engineering_design_waiting:
          'Projeto de engenharia aguardando aprovação.',
        engineering_design_approved:
          'Projeto de engenharia aprovado, prossiga para próxima etapa.',
        engineering_design_discuss_and_change:
          'Projeto de engenharia reprovado, existem ajustes a serem feitos.',
        approved: 'Aprovado',
        finished_finished: 'A solicitação chegou a etapa final.',
        screening_finished: 'Finalizado',
        canceled_disapproved_others: 'Solicitação cancelada por outros motivos',
        declined_disapproved_others: 'Solicitação declineda por outros motivos',
        canceled_disapproved_patient_died:
          'Solicitação cancelada, paciente faleceu',
        canceled_disapproved_paying_source_did_not_accept:
          'Solicitação cancelada, fonte pagadora não aceitou',
        canceled_disapproved_approved_by_another_company:
          'Solicitação cancelada, optou por outra empresa',
        canceled_disapproved_patient_withdrew_from_treatment:
          'Solicitação cancelada, paciente desistiu do tratamento',
        declined_disapproved_patient_withdrew_from_treatment:
          'Solicitação declinada, paciente desistiu do tratamento',
        declined_disapproved_no_access:
          'Solicitação declinada, sem acesso ao paciente',
        screening_tomography_analysis_disapproved_no_patient_access:
          'Solicitação declinada, sem acesso ao paciente',
        approved_specialist: 'Aprovado especialista',
        engineering_design_disapproved_requester:
          'Projeto de engenharia reprovado, existem ajustes a serem feitos',
        engineering_design_disapproved_specialist:
          'Projeto de engenharia reprovado, existem ajustes a serem feitos',
        engineering_design_waiting_schedule: 'Projeto Aguardando agendamento',
        engineering_design_analisys:
          'Projeto de engenharia aguardand aprovação do especialista',
        treatment_suggestion_repproved_dicom:
          'Solicitação foi reprovada pré-análise por problemas no arquivo DICOM, para prosseguir edite a solicitação.',
        engineering_project_analisys_production:
          'Em análise de projeto - Produção',
        engineering_approved_production: 'Aprovado produção',
        engineering_repproved_production: 'Reprovado produção',
        engineering_production_settings_production: 'Ajustes de produção',
      },
    },
    treatmentSuggestion: {
      upload: {
        title: 'Relatório do especialista',
        description: 'Anexe aqui o relatório para solicitação',
      },
      send: {
        title: 'Enviar para aprovação',
        description: 'Envie o relatório para aprovação',
        button: 'Enviar',
      },
      modal: {
        success: {
          technically_approved: 'Sugestão de tratamento aprovada tecnicamente.',
          commercially_approved:
            'Sugestão de tratamento aprovada comercialmente.',
          reprove: 'Sugestão de tratamento reprovada.',
          approve: 'Sugestão de tratamento aprovada.',
        },
        title: {
          approve: 'Informe o tipo de aprovação',
          reprove: 'Informe o motivo da reprovação',
        },
        statusLabel: {
          commercially_waiting: {
            title: 'Aprove comercialmente',
            description:
              'Você já aprovou tecnicamente, agora só pode aprovar ou reprovar comercialmente.',
          },
          technically_waiting: {
            title: 'Aprove tecnicamente',
            description:
              'Você já aprovou comercialmente, agora só pode aprovar ou reprovar tecnicamente.',
          },
        },
      },
    },
    project: {
      schedule: {
        title: 'Agendar projeto de engenharia',
        description: 'Agende aqui o projeto de engenharia',
        button: 'Agendar',
        priorizate: 'Priorizar',
      },
      file: {
        title: 'Dados do projeto de engenharia',
        subtitle: 'Arquivo do projeto de engenharia',
        description: 'Visualize o arquivo do projeto de engenharia',
      },
      prototype: {
        title: 'Necessidade de um protótipo',
        description: 'Veredito da produção',
        acceptTerms: 'Li e concordo.',
        p: {
          1: 'Após uma análise cuidadosa realizada pela nossa equipe de produção, constatamos que a endoprótese que você solicitou possui uma complexidade considerável e, por isso, será necessário a fabricação de um protótipo antes de seguirmos para a fabricação da endoprótese final.',
          2: 'Gostaríamos de ressaltar que a confecção do protótipo é uma etapa fundamental do processo de fabricação da endoprótese, pois nos permite testar o projeto em condições controladas e ajustá-lo conforme necessário. Dessa forma, podemos garantir que a endoprótese final terá a eficácia e segurança necessárias para cumprir sua finalidade.',
          3: 'No entanto, é importante lembrar que a produção de um protótipo adiciona mais uma etapa ao processo de fabricação e, portanto, pode levar um pouco mais de tempo para a conclusão do projeto. Além disso, após a fabricação do protótipo, ele será testado em laboratório para avaliar sua eficácia e segurança.',
        },
      },
      observation: {
        title: 'Observação do projetista',
      },
      modal: {
        success: {
          descriptions: {
            success_schedule: 'Projeto de engenharia agendado para outra data.',
            success_specialist_approve: 'Projeto de engenharia aprovado.',
            success_consultant_approve: 'Projeto de engenharia aprovado.',
            success_send_project: 'Projeto de engenharia enviado.',
            success_reprove: 'Projeto de engenharia reprovado.',
          },
        },
        reprove: {
          title: 'Reprovação de projeto',
          observation: {
            label: 'Observações de reprovação',
            placeholder: 'Digite aqui os detalhes e ajustes a serem feitos',
          },
        },
        approve: {
          title: 'Tem certeza que deseja aprovar o projeto de engenharia?',
        },
        schedule: {
          title: 'Agendamento de projeto de engenharia',
          reset: 'Resetar',
          calendar: {
            title: 'Calendário',
            description: 'Escolha o mês do agendamento',
          },
          resume: {
            title: 'Mais Informações',
            label: 'Data de agendamento projeto',
            notFound: 'Nenhum projeto agendado para este dia',
          },
          current: {
            title: 'Novo projeto de engenharia',
            description:
              'Mova o novo projeto de engenharia para a fila de produção',
          },
          queue: {
            procedure: 'Procedimento',
            title: 'Fila de produção mensal',
            description: 'Priorize o novo projeto na fila de produção',
          },
        },
      },
    },
  },
}
