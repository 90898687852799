export default {
  table: {
    newButton: 'New user',
    emptyTitle: 'Register a user',
    emptyDescription:
      "You don't have any users yet. Register your first user and your information will be displayed here.",
  },
  update: {
    title: 'User',
  },
  register: {
    title: 'User registration',
    sections: {
      title: 'User data',
      description: 'Fill in user information',
    },
    saveButton: 'To create',
  },
  toast: {
    error: {
      'e-mail_already_in_use': 'Email is already being used by another user',
    },
  },
  exclude: {
    modal: {
      initial: {
        title: 'Excluir usuário',
        description: 'Are you sure you want to delete this user?',
      },
      success: {
        title: 'Success',
        description: 'User deleted successfully.',
      },
    },
  },
}
