export default {
  page: {
    observation: {
      title: 'Notas/ajustes',
      specialist: 'Observación experta',
      requester: 'Solicitando observación',
    },
    analysis: {
      title: 'Analizar',
    },
  },
  modal: {
    title: 'Datos de diseño de ingeniería',
    sections: {
      filesPatient: 'Documentación del paciente',
      upload: {
        description: 'Adjunte el proyecto aquí',
      },
    },
    send: {
      title: 'Someter para aprobacion',
      description: 'Presentar el proyecto para su aprobación',
    },
  },
  analysis: {
    upload: {
      title: 'Carga de proyecto de ingeniería',
      description: 'Sube el proyecto de ingeniería',
    },
    products: {
      title: 'Código y referencia del producto',
      description:
        'Introduce el código y referencia de los artículos de endoprótesis',
    },
    prototype: {
      title: 'Necesidad de un prototipo',
      description:
        'Después de revisar el diseño de ingeniería, ¿se requerirá un prototipo?',
    },
    modal: {
      titles: {
        approve: 'Diseño de ingeniería',
        cancel: 'Solicitar registro',
        remove: 'Eliminar código',
        success: 'Éxito',
      },
      descriptions: {
        approve: '¿Está seguro de que desea enviar el diseño de ingeniería?',
        cancel:
          'Está a punto de perder información que ya se ha completado en el proyecto de ingeniería. ¿Seguro que quieres salir?',
        remove:
          '¿Está seguro de que desea eliminar este código de producto y campo de referencia?',
        success:
          'Esta operación se realizó con éxito. Diseño de ingeniería presentado.',
      },
    },
  },
}
