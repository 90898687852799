export default {
  modal: {
    title: 'Datos del medico',
    description: 'Rellena los datos del médico',
    cancelButton: 'Cancelar',
    saveButton: 'Ahorrar',
    toast: {
      success: {
        title: '¡Éxito!',
        description: '¡Médico registrado con éxito!',
      },
      error: {
        title: '¡Error!',
        'e-mail_already_in_use':
          'El correo electrónico ya está siendo utilizado por otro médico',
        register: {
          generic: '¡Hubo un error al registrar al médico!',
        },
        update: {
          generic: '¡Hubo un error al actualizar el doctor!',
        },
      },
    },
  },
  form: {
    name: {
      label: 'Nombre',
      placeholder: 'Ingrese el nombre del médico',
    },
    consultant: {
      placeholder: 'Ingrese el nombre del consultor',
    },
    email: {
      label: 'Correo electrónico',
      placeholder: 'Ingrese el correo electrónico del médico',
    },
    phone: {
      label: 'Celular',
    },
    language: {
      label: 'Idioma',
      placeholder: 'Seleccione una opción',
    },
    telephone: {
      label: 'Teléfono',
    },
    invite: {
      title: 'Convertir a un médico en usuario de la plataforma',
      description:
        'Poco después de completar el registro, se enviará una invitación por correo electrónico para que el médico se convierta en usuario de la plataforma.',
    },
  },
  table: {
    newButton: 'Nuevo medico',
    actions: {
      update: 'Cambiar registro',
      updateSpecialist: 'Cambiar consultor',
      delete: 'Eliminar el registro',
      resend: 'Reenviar invitacíon',
      resetPassword: 'Redefinir contraseña',
    },
    emptyTitle: 'Registrar un medico',
    emptyDescription:
      'Aún no tienes médicos. Registre su primer médico y su información se mostrará aquí.',
  },
  register: {
    title: 'Registro médico',
    cancelButton: 'Cancelar',
    saveButton: 'Ahorrar',
    sections: {
      title: 'Datos del medico',
      description: 'Rellena los datos del médico',
    },
    modal: {
      success: {
        title: 'Éxito',
        description: 'Esta operación se realizó con éxito.',
      },
      noUser: 'Datos médicos guardados.',
      isUser:
        'Datos del médico guardados e invitación enviada por correo electrónico.',
      error: {
        title: 'Error',
      },
    },
  },
  update: {
    title: 'Edición del médico',
    actionButton: 'Editar informacion',
  },
  exclude: {
    modal: {
      initial: {
        title: 'Exclusión del médico',
        description: '¿Está seguro de que desea eliminar a este médico?',
      },
      success: {
        title: 'Éxito',
        description: 'Esta operación se realizó con éxito.',
        passwordReset:
          'El enlace de restablecimiento de contraseña se ha enviado al médico.',
        exclude: 'Datos del médico excluidos.',
        invite: 'Invitación reenviada al médico',
      },
      error: {
        title: 'Error',
      },
    },
  },
}
