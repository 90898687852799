export default {
  message: 'Teste de mensagem',
  other: 'Outro teste',
  title: {
    translator: 'Componente de tradutor',
    input: 'Componente de entrada',
    button: 'Componente de botão',
  },
  text: {
    input: 'Selecione a variação do input: ',
    button: 'Selecione a variação do botão: ',
  },
}
