export default {
  afghanistao: 'Afghanistão',
  africa_do_sul: 'África do Sul',
  albania: 'Albânia',
  alemanha: 'Alemanha',
  andora: 'Andora',
  angola: 'Angola',
  anguilla: 'Anguilla',
  antarctica: 'Antarctica',
  antigua_e_barbuda: 'Antígua e Barbuda',
  argentina: 'Argentina',
  argelia: 'Argélia',
  armenia: 'Arménia',
  aruba: 'Aruba',
  arabia_saudita: 'Arábia Saudita',
  australia: 'Austrália',
  austria: 'Áustria',
  azerbaijao: 'Azerbaijão',
  bahamas: 'Bahamas',
  bahrein: 'Bahrein',
  bangladesh: 'Bangladesh',
  barbados: 'Barbados',
  belgica: 'Bélgica',
  belize: 'Belize',
  benim: 'Benim',
  bermuda: 'Bermuda',
  butao: 'Butão',
  bielorrussia: 'Bielorrússia',
  bolivia: 'Bolívia',
  bosnia_e_herzegovina: 'Bósnia e Herzegovina',
  botswana: 'Botswana',
  brasil: 'Brasil',
  brunei: 'Brunei',
  bulgaria: 'Bulgária',
  burkina_faso: 'Burkina Faso',
  myanmar_burma: 'Myanmar (Burma)',
  burundi: 'Burundi',
  camaroes: 'Camarões',
  cabo_verde: 'Cabo Verde',
  camboja: 'Camboja',
  canada: 'Canadá',
  catar: 'Catar',
  chad: 'Chad',
  chile: 'Chile',
  china: 'China',
  chipre: 'Chipre',
  christmas_island: 'Christmas Island',
  cingapura: 'Cingapura',
  colombia: 'Colômbia',
  comores: 'Comores',
  coreia_do_norte: 'Coreia do Norte',
  coreia_do_sul: 'Coreia do Sul',
  costa_do_marfim: 'Costa do Marfim',
  costa_rica: 'Costa Rica',
  croacia: 'Croácia',
  cuba: 'Cuba',
  dinamarca: 'Dinamarca',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  egito: 'Egito',
  el_salvador: 'El Salvador',
  emirados_arabes_unidos: 'Emirados Árabes Unidos',
  equador: 'Equador',
  eritreia: 'Eritreia',
  estonia: 'Estónia',
  espanha: 'Espanha',
  etiopia: 'Etiópia',
  fiji: 'Fiji',
  finlandia: 'Finlândia',
  franca: 'França',
  gabao: 'Gabão',
  gambia: 'Gâmbia',
  georgia: 'Geórgia',
  gana: 'Gana',
  gibraltar: 'Gibraltar',
  grecia: 'Grécia',
  groelandia: 'Groelândia',
  granada: 'Granada',
  guam: 'Guam',
  guatemala: 'Guatemala',
  guiana: 'Guiana',
  guine: 'Guiné',
  guine_equatorial: 'Guiné Equatorial',
  guinebissau: 'Guiné-Bissau',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hong_kong: 'Hong Kong',
  hungria: 'Hungria',
  islandia: 'Islândia',
  ilhas_cayman: 'Ilhas Cayman',
  ilhas_cocos_keeling: 'Ilhas Cocos (Keeling)',
  ilha_de_man: 'Ilha de Man',
  ilhas_cook: 'Ilhas Cook',
  ilhas_falkland_malvinas: 'Ilhas Falkland (Malvinas)',
  ilhas_feroe: 'Ilhas Feroé',
  ilhas_mariana_do_norte: 'Ilhas Mariana do Norte',
  ilhas_marshall: 'Ilhas Marshall',
  ilhas_norfolk: 'Ilhas Norfolk',
  ilhas_pitcairn: 'Ilhas Pitcairn',
  ilhas_salomao: 'Ilhas Salomão',
  ilhas_turcas_e_caicos: 'Ilhas Turcas e Caicos',
  ilhas_virgens_americanas: 'Ilhas Virgens Americanas',
  ilhas_virgens_britanicas: 'Ilhas Virgens Britânicas',
  india: 'India',
  indonesia: 'Indonésia',
  inglaterra_reino_unido: 'Inglaterra (Reino Unido)',
  ira: 'Irã',
  iraque: 'Iraque',
  irlanda: 'Irlanda',
  israel: 'Israel',
  italia: 'Itália',
  jamaica: 'Jamaica',
  japao: 'Japão',
  jordania: 'Jordânia',
  cazaquistao: 'Cazaquistão',
  quenia_kenya: 'Quénia (Kenya)',
  kiribati: 'Kiribati',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  quirguistao: 'Quirguistão',
  laos: 'Laos',
  letonia: 'Letônia',
  libano: 'Líbano',
  lesoto: 'Lesoto',
  liberia: 'Libéria',
  libia: 'Líbia',
  liechtenstein: 'Liechtenstein',
  lituania: 'Lituânia',
  luxemburgo: 'Luxemburgo',
  macau: 'Macau',
  macedonia: 'Macedónia',
  madagascar: 'Madagáscar',
  malawi: 'Malawi',
  malasia: 'Malásia',
  maldivas: 'Maldivas',
  mali: 'Mali',
  malta: 'Malta',
  mauritania: 'Mauritânia',
  mauricia: 'Maurícia',
  mayotte: 'Mayotte',
  mexico: 'México',
  micronesia: 'Micronésia',
  moldavia: 'Moldávia',
  monaco: 'Monaco',
  mongolia: 'Mongólia',
  montenegro: 'Montenegro',
  montserrat: 'Montserrat',
  marrocos: 'Marrocos',
  mocambique: 'Moçambique',
  namibia: 'Namíbia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands_antilles: 'Netherlands Antilles',
  nova_caledonia: 'Nova Caledônia',
  nova_zelandia: 'Nova Zelândia',
  nicaragua: 'Nicaragua',
  niger: 'Níger',
  nigeria: 'Nigéria',
  niue: 'Niue',
  noruega: 'Noruega',
  oma: 'Omã',
  paises_baixos: 'Países Baixos',
  paquistao: 'Paquistão',
  palau: 'Palau',
  panama: 'Panamá',
  papuanova_guine: 'Papua-Nova Guiné',
  paraguai: 'Paraguai',
  peru: 'Peru',
  filipinas: 'Filipinas',
  polonia: 'Polónia',
  polinesia_francesa: 'Polinésia Francesa',
  portugal: 'Portugal',
  porto_rico: 'Porto Rico',
  republica_do_congo: 'República do Congo',
  republica_democratica_do_congo: 'República Democrática do Congo',
  republica_centroafricana: 'República Centro-Africana',
  republica_checa: 'República Checa',
  republica_dominicana: 'República Dominicana',
  romenia: 'Roménia',
  ruanda: 'Ruanda',
  russia: 'Rússia',
  saint_barthelemy: 'Saint Barthelemy',
  samoa: 'Samoa',
  samoa_americana: 'Samoa Americana',
  san_marino: 'San Marino',
  sao_tome_e_principe: 'Sao Tome e Principe',
  senegal: 'Senegal',
  serbia: 'Serbia',
  serra_leoa: 'Serra Leoa',
  seychelles: 'Seychelles',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  somalia: 'Somalia',
  sri_lanka: 'Sri Lanka',
  saint_helena: 'Saint Helena',
  saint_kitts_and_nevis: 'Saint Kitts and Nevis',
  saint_lucia: 'Saint Lucia',
  saint_martin: 'Saint Martin',
  saint_pierre_and_miquelon: 'Saint Pierre and Miquelon',
  saint_vincent_and_the_grenadines: 'Saint Vincent and the Grenadines',
  sudao: 'Sudão',
  suriname: 'Suriname',
  suazilandia: 'Suazilândia',
  suecia: 'Suécia',
  suica: 'Suiça',
  syria: 'Syria',
  taiwan: 'Taiwan',
  tajiquistao: 'Tajiquistão',
  tanzania: 'Tanzânia',
  timorleste: 'Timor-Leste',
  tailandia: 'Tailândia',
  togo: 'Togo',
  tokelau: 'Tokelau',
  tonga: 'Tonga',
  trinidad_e_tobago: 'Trinidad e Tobago',
  tunisia: 'Tunísia',
  turquemenistao: 'Turquemenistão',
  turquia: 'Turquia',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ucrania: 'Ucrânia',
  uruguai: 'Uruguai',
  estados_unidos_eua: 'Estados Unidos (EUA)',
  uzbequistao: 'Uzbequistão',
  vanuatu: 'Vanuatu',
  vaticano: 'Vaticano',
  venezuela: 'Venezuela',
  vietna_vietname: 'Vietnã (Vietname)',
  wallis_e_futuna: 'Wallis e Futuna',
  iemen_iemen_yemen: 'Iémen (Iémen, Yemen)',
  zimbabwezimbabue: 'Zimbabwe(Zimbabué)',
  zambia: 'Zâmbia',
}
