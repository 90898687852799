export default {
  title: 'Notificações',
  markAll: 'Marcar todas como lidas',
  empty: 'Você ainda não possui notificações',
  titles: {
    draft_awaiting_dicon: 'Solicitação em rascunho',
    draft: 'Solicitação em rascunho',
    created: 'Nova solicitação criada',
    screening_pre_analysis: 'Solicitação em triagem',
    screening_disapproved_registration_problems: 'Solicitação em triagem',
    screening_disapproved_dicon: 'Solicitação em triagem',
    screening_disapproved_others: 'Solicitação em triagem',
    screening_approved: 'Solicitação em triagem',
    screening_disapproved_no_lap_for_standard_care: 'Solicitação declinada',
    screening_disapproved_no_patient_access: 'Solicitação declinada',
    screening_disapproved_productive_impossibility: 'Solicitação declinada',
    screening_disapproved_no_atomical_indication: 'Solicitação em triagem',
    screening_disapproved_no_excessive_crookdness: 'Solicitação em triagem',
    treatment_suggestion_draft: 'Solicitação em sugestão de tratamento',
    treatment_suggestion_waiting_consultant_approved:
      'Solicitação em sugestão de tratamento',
    treatment_suggestion_waiting: 'Solicitação em sugestão de tratamento',
    treatment_suggestion_approved: 'Solicitação em sugestão de tratamento',
    treatment_suggestion_commercially_approved: 'Aprovação Comercial',
    treatment_suggestion_consultant_approve_request:
      'Solicitação em projeto de engenharia',
    treatment_approved: 'Solicitação aprovada',
    engineering_design_waiting: 'Solicitação em projeto de engenharia',
    endoprosthesis_manufacturing_waiting:
      'Solicitação em fabricação de endoprótese',
    treatment_suggestion_disapproved_approved_does_not_agree_with_the_proposal:
      'Solicitação em sugestão de tratamento',
    treatment_suggestion_disapproved_paying_source_did_not_accept:
      'Solicitação cancelada',
    treatment_suggestion_disapproved_by_another_company:
      'Solicitação cancelada',
    canceled_disapproved_patient_died: 'Solicitação cancelada',
    canceled_disapproved_patient_others: 'Solicitação cancelada',
    canceled_disapproved_withdrew_from_treatment: 'Solicitação cancelada',
    treatment_suggestion_disapproved_others:
      'Solicitação em sugestão de tratamento',
    declined_others: 'Solicitação declinada',
    declined_disapproved_patient_withdrew_from_treatment:
      'Solicitação declinada',
    approved: 'Solicitação aprovada',
    declined: 'Solicitação declinada',
    canceled: 'Solicitação cancelada',
    finished: 'Solicitação finalizada',

    draft_awaiting_consultant: 'Solicitação em triagem',
    screening_tomography_analysis: 'Solicitação em triagem',
    treatment_disapproved_others: 'Solicitação em sugestão de tratamento',
    engineering_design_draft: 'Solicitação em projeto de engenharia',
    engineering_design_approved: 'Projeto de Engenharia aprovado',
    engineering_design_repproved: 'Projeto de Engenharia reprovado',
    treatment_suggestion_approved_consultant:
      'Solicitação em projeto de engenharia',
    engineering_design_waiting_title: 'Solicitação em projeto de engenharia',
    screening_disapproved_others_agreement_with_specialist:
      'Solicitação em triagem',
    engineering_design_analisys_production_title:
      'Solicitação em projeto de engenharia',
    engineering_design_settings_production_title:
      'Solicitação em projeto de engenharia',
    prototype_waiting: 'Solicitação em protótipo',
  },
  descriptions: {
    draft_awaiting_dicon:
      'Consultor sua solicitação está em rascunho, para prosseguir anexe o arquivo DICOM',
    draft:
      'Consultor sua solicitação está em rascunho, converse com o consultor para obter mais informações',
    created:
      'Especialista uma nova solicitação foi criada, clique aqui para para visualizá-la',
    screening_pre_analysis:
      'Consultor sua solicitação está em triagem, sendo feita uma pré análise de informações',
    screening_disapproved_registration_problems:
      'Consultor sua solicitação foi reprovada em pré-análise por problemas no cadastro, para prosseguir edite a solicitação',
    screening_disapproved_dicon:
      'Consultor sua solicitação foi reprovada pré-análise por problemas no arquivo DICOM, para prosseguir edite a solicitação',
    screening_approved:
      'Consultor sua solicitação foi aprovada na fase de triagem',
    treatment_suggestion_approved_consultant:
      'Consultor a sugestão de tratamento foi aprovada e a solicitação segue para a próxima etapa',
    treatment_suggestion_approved_specialist:
      'Especialista sua sugestão de tratamento foi aprovada, prossiga para próxima etapa',
    treatment_suggestion_consultant_approve_request:
      'Consultor aprove a sugestão de tratamento',
    treatment_suggestion_commercially_approved:
      'Consultor sua solicitação foi aprovada comercialmente',
    screening_disapproved_no_lap_for_standard_care:
      'Consultor sua solicitação foi declinada pois não tem colo para tratamento padrão',
    screening_disapproved_no_patient_access:
      'Consultor sua solicitação foi declinada pois não tem acesso ao paciente',
    screening_disapproved_productive_impossibility:
      'Consultor sua solicitação foi declinada por impossibilidade produtiva',
    screening_disapproved_no_atomical_indication:
      'Consultor sua tomografia foi reprovada por não tem indicação anatômica, para prosseguir entre em um acordo',
    screening_disapproved_no_excessive_crookdness:
      'Consultor sua tomografia foi reprovada por ter tortuosidade excessiva, para prosseguir entre em um acordo',
    screening_disapproved_others:
      'Consultor sua solicitação foi reprovada em pré-análise por outros motivos, clique aqui para para obter mais informações',
    treatment_suggestion_draft:
      'Consultor, a solicitação está aguardando a sugestão de tratamento do especialista.',
    treatment_suggestion_waiting_consultant_approved:
      'Consultor aprove a sugestão de tratamento',
    treatment_suggestion_waiting: 'Consultor, aprove a sugestão de tratamento',
    treatment_suggestion_disapproved_others:
      'Especialista a solicitação foi reprovada por outros motivos, clique aqui para obter mais informações',
    treatment_suggestion_disapproved_ay_another_company:
      'Especialista a solicitação foi cancelada, optaram por outra empresa, clique aqui para obter mais informações',
    treatment_suggestion_disapproved_paying_source_did_not_accept:
      'Especialista a solicitação foi cancelada, fonte pagadora não aceitou, clique aqui para obter mais informações',
    treatment_suggestion_disapproved_approved_does_not_agree_with_the_proposal:
      'Especialista sua sugestão de tratamento foi reprovada, não concordaram com a mesma, para prosseguir entre em um acordo',
    engineering_design_waiting: 'Consultor, aprove o projeto de engenharia.',
    endoprosthesis_manufacturing_waiting:
      'Consultor sua solicitação está aguardando agendamento',
    declined_others:
      'Consultor a solicitação foi declinada por outros motivos, clique aqui para para obter mais informações',
    declined_disapproved_patient_withdrew_from_treatment:
      'Consultor paciente desistiu do tratamento, clique aqui para obter mais informações',
    finished: 'A solicitação chegou ao final de todas as etapas',
    canceled_disapproved_patient_others:
      'Especialista a solicitação foi cancelada por outros motivos, clique aqui para para obter mais informações',
    canceled_disapproved_patient_died:
      'Especialista o paciente faleceu, clique aqui para obter mais informações',
    canceled_disapproved_withdrew_from_treatment:
      'Especialista o paciente desistiu do tratamento, clique aqui para obter mais informações',

    draft_awaiting_consultant:
      'Consultor, sua solicitação está em triagem, sendo feita uma pré análise de informações',
    screening_tomography_analysis:
      'Consultor, sua solicitação está em triagem, sendo feita uma análise de tomografia.',
    treatment_suggestion_approved:
      'Especialista, sua sugestão de tratamento foi aprovada, prossiga para próxima etapa',
    treatment_disapproved_others:
      'Especialista, a solicitação foi reprovada por outros motivos, clique aqui para obter mais informações',
    engineering_design_draft:
      'Projetista, existe projeto de engenharia a ser feito.',
    engineering_design_approved:
      'Especialista, o projeto de engenharia foi aprovado, prossiga para próxima etapa.',
    engineering_design_repproved:
      'Especialista, o projeto de engenharia foi reprovado, existem ajustes a serem feitos.',
    engineering_design_waiting_description:
      'Consultor, aprove o projeto de engenharia.',
    draft_waiting:
      'Consultor sua solicitação está em rascunho, converse com o consultor para obter mais informações',
    screening_disapproved_others_agreement_with_specialist:
      'Consultor, sua tomografia foi reprovada por outros motivos, para prosseguir entre em um acordo com o especialista.',
    treatment_suggestion_disapproved_by_another_company:
      'Especialista, a solicitação foi cancelada, optaram por outra empresa, clique aqui para obter mais informações.',
    engineering_design_analisys_production_description:
      'Produção, aprove o projeto de engenharia feito pelo projetista.',
    engineering_design_settings_production_description:
      'Projestista, existem ajustes no projeto de engenharia recomendados pela produção.',
    prototype_waiting_applicant_prototype_production:
      'Solicitante, o protótipo está em produção',
    endoprosthesis_manufacturing_waiting_endoprosthesis_protuction:
      'Solicitante, a endoprótese está em produção',
    finished_finished: 'Solicitante, sua solicitação foi finalizada',
  },
}
