import 'resize-observer-polyfill'
import 'resize-observer'
import '@styles/globals.css'
import '@styles/calendars/schedule.css'
import '@styles/calendars/scheduleModal.css'
import '@configs/i18n'
import type { AppProps } from 'next/app'
import { NextPageWithLayout } from 'types/app'
import { QueryClientProvider } from 'react-query'
import * as ToastPrimitive from '@radix-ui/react-toast'
import { Toast } from '@components/Core/Toast'
import { queryClient } from '@configs/api'
import { Upload } from '@components/Features/Upload'
import Script from 'next/script'
import { braileDB } from '@configs/db'
import { useEffect } from 'react'

type PageWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: PageWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)

  const resetDB = async () => {
    if (braileDB.isOpen()) {
      braileDB.close()
    }
    await braileDB.delete()
    await braileDB.open()
  }

  useEffect(() => {
    resetDB()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ToastPrimitive.Provider swipeDirection="right">
        {getLayout(<Component {...pageProps} />, pageProps)}
        <Toast />
        <Upload />
      </ToastPrimitive.Provider>
      {process.env.NEXT_PUBLIC_USE_HOTJAR === 'true' && (
        <Script
          id="hotjar"
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3290339,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
      )}
      <script src="//unpkg.com/@ungap/global-this" />
    </QueryClientProvider>
  )
}

export default MyApp
