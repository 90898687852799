export default {
  modal: {
    title: 'Datos del hospital',
    description: 'Rellena los datos del hospital',
    cancelButton: 'Cancelar',
    saveButton: 'Ahorrar',
    toast: {
      success: {
        title: '¡Éxito!',
        description: '¡hospital registrado con éxito!',
      },
      error: {
        title: '¡Error!',
        'e-mail_already_in_use':
          'El correo electrónico ya está siendo utilizado por otro hospital',
        register: {
          generic: '¡Hubo un error al registrar al hospital!',
        },
        update: {
          generic: '¡Hubo un error al actualizar el doctor!',
        },
      },
    },
  },
  form: {
    name: {
      label: 'Nombre',
      placeholder: 'Ingrese el nombre del hospital',
    },
    email: {
      label: 'Correo electrónico',
      placeholder: 'Ingrese el correo electrónico del hospital',
    },
    phone: {
      label: 'Celular',
    },
    language: {
      label: 'Idioma',
      placeholder: 'Seleccione una opción',
    },
    telephone: {
      label: 'Teléfono',
    },
    invite: {
      title: 'Convertir a un hospital en usuario de la plataforma',
      description:
        'Poco después de completar el registro, se enviará una invitación por correo electrónico para que el hospital se convierta en usuario de la plataforma.',
    },
  },
  table: {
    newButton: 'Nuevo hospital',
    actions: {
      update: 'Cambiar registro',
      delete: 'Eliminar el registro',
    },
    emptyTitle: 'Registrar un medico',
    emptyDescription:
      'Aún no tienes hospitais. Registre su primer hospital y su información se mostrará aquí.',
  },
  register: {
    title: 'Hospital',
    cancelButton: 'Cancelar',
    saveButton: 'Ahorrar',
    sections: {
      title: 'Datos del hospital',
      description: 'Rellena los datos del hospital',
    },
    modal: {
      success: {
        title: 'Éxito',
        description: 'Esta operación se realizó con éxito.',
      },
      saveDescription: 'Datos hospitais guardados.',
      isUser:
        'Datos del hospital guardados e invitación enviada por correo electrónico.',
      error: {
        title: 'Error',
      },
    },
  },
  update: {
    title: 'Edición del hospital',
    actionButton: 'Editar informacion',
  },
  exclude: {
    modal: {
      initial: {
        title: 'Exclusión del hospital',
        description: '¿Está seguro de que desea eliminar a este hospital?',
      },
      success: {
        title: 'Éxito',
        description: 'Esta operación se realizó con éxito.',
        passwordReset:
          'El enlace de restablecimiento de contraseña se ha enviado al hospital.',
        exclude: 'Datos del hospital excluidos.',
        invite: 'Invitación reenviada al hospital',
      },
      error: {
        title: 'Error',
      },
    },
  },
}
