import * as ProgressBase from '@radix-ui/react-progress'
import { styled } from '@stitches/react'
import { blackA } from '@radix-ui/colors'
import { FC } from 'react'
import { Flex } from '../Display'

const ProgressRoot = styled(ProgressBase.Root, {
  position: 'relative',
  overflow: 'hidden',
  background: blackA.blackA9,
  borderRadius: '99999px',

  // Fix overflow clipping in Safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: 'translateZ(0)',
})

const ProgressIndicator = styled(ProgressBase.Indicator, {
  width: '100%',
  height: '100%',
})

type ProgressProps = {
  progress: number
  name: string
  withoutLabel?: boolean
}

export const Progress: FC<ProgressProps> = ({
  progress,
  name,
  withoutLabel,
}) => {
  return (
    <Flex className="flex-col items-start w-full overflow-hidden">
      {!withoutLabel && (
        <span
          style={{
            transform: `translateX(${Math.min(89, progress)}%)`,
            width: '100%',
            position: 'relative',
          }}
        >
          {Math.ceil(Math.min(99, progress))}%
        </span>
      )}
      <ProgressRoot className="h-3 w-full" id={name} value={100}>
        <ProgressIndicator
          id={name}
          className="bg-primary-300"
          style={{ transform: `translateX(-${100 - Math.min(99, progress)}%)` }}
        />
      </ProgressRoot>
    </Flex>
  )
}
