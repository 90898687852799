export default {
  modal: {
    title: 'Dados do hospital',
    description: 'Preencha as informações do hospital',
    cancelButton: 'Cancelar',
    saveButton: 'Salvar',
    toast: {
      success: {
        title: 'Sucesso!',
        description: 'Hospital cadastrado com sucesso!',
      },
      error: {
        title: 'Erro!',
        'e-mail_already_in_use':
          'E-mail já está sendo utilizado por outro hospital',
        register: {
          generic: 'Houve um erro ao cadastrar o hospital!',
        },
        update: {
          generic: 'Houve um erro ao atualizar o hospital!',
        },
      },
    },
  },
  form: {
    name: {
      label: 'Nome',
      placeholder: 'Digite o nome do hospital',
    },
    email: {
      label: 'Email',
      placeholder: 'Digite o email do hospital',
    },
    phone: {
      label: 'Celular',
    },
    language: {
      label: 'Idioma',
      placeholder: 'Selecione uma opção',
    },
    telephone: {
      label: 'Telefone',
    },
    invite: {
      title: 'Tornar hospital usuário da plataforma',
      description:
        'Logo após a finalização do cadastro, será enviado um convite por e-mail para que o hospital se torne um usuário da plataforma.',
    },
  },
  table: {
    newButton: 'Novo hospital',
    actions: {
      update: 'Alterar cadastro',
      delete: 'Excluir cadastro',
    },
    emptyTitle: 'Cadastre um hospital',
    emptyDescription:
      'Você ainda não possui nenhum hospital. Cadastre seu primeiro hospital e suas informações serão exibidas aqui.',
  },
  register: {
    title: 'Hospital',
    cancelButton: 'Cancelar',
    saveButton: 'Salvar',
    sections: {
      title: 'Dados do hospital',
      description: 'Preenche as informações do hospital',
    },
    modal: {
      success: {
        title: 'Sucesso',
        description: 'Esta operação foi realizada com sucesso.',
      },
      saveDescription: 'Dados do hospital salvos.',
      isUser: 'Dados do hospital salvos e convite enviado por e-mail.',
      error: {
        title: 'Erro',
      },
    },
  },
  update: {
    title: 'Edição de hospital',
    actionButton: 'Editar informações',
  },
  exclude: {
    modal: {
      initial: {
        title: 'Exclusão de hospital',
        description: 'Tem certeza de que deseja excluir esse hospital?',
      },
      success: {
        title: 'Sucesso',
        description: 'Essa operação foi realizada com sucesso.',
        passwordReset:
          'Link para redefinição de senha foi enviado ao hospital.',
        exclude: 'Dados do hospital excluídos.',
        invite: 'Convite reenviado ao hospital',
      },
      error: {
        title: 'Erro',
      },
    },
  },
}
