export default {
  dateTime: {
    day: {
      sunday: 'Domingo',
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
    },
    longDay: {
      sunday: 'Domingo',
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
    },
    month: {
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'mayM',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',
    },
    at: 'a',
    today: 'Hoy',
    of: 'de',
  },
  errors: {
    form: {
      required: 'Este campo es obligatorio',
      minArray: 'El campo debe tener al menos 1 elementos',
      confirmPassword: 'Las contraseñas no son similares',
      email: 'Este es un email inválido',
      passwordLength: 'La contraseña debe contener al menos 8 dígitos',
      biggerThanToday:
        'La fecha del procedimiento debe ser posterior a la fecha de hoy',
      passwordInvalid:
        'La contraseña debe contener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.',
    },
    generic: {
      title: 'Error',
      description:
        'Hubo un error en nuestros servidores, inténtalo de nuevo más tarde.',
    },
  },
  roles: {
    doctor: 'Médico',
    admin: 'Admin',
    consultant: 'Consultor',
    specialist: 'Especialista',
    distributor: 'Distribuidor',
    designer: 'Designer',
    estimator: 'Estimador',
    draftsman: 'Diseñador',
    production_analyst: 'Analista de producción',
  },
  table: {
    default: {
      emptyTitle: 'No encontramos nada',
      emptyDescription: 'No se obtuvieron datos',
    },
  },
  wizards: {
    stepPrefix: 'Paso',
    stepOf: 'de',
  },
  genre: {
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
  },
  payingSourceType: {
    health_insurance: 'Seguro de salud',
    sus: 'Sistema de Salud Pública',
    particular: 'Especial',
    plano_saude: 'Plan de salud',
    outros: 'Otros',
  },
  diseases: {
    aaa: 'AAA',
    coarctation: 'Coartación',
    taa: 'TAA',
    dissection: 'Disección',
    endoleak: 'Endofuga',
    bruise: 'Moretón',
    iliac: 'Ilíaco',
    ulcer: 'Úlcera',
    thoracoabdominal: 'Toracoabdominal',
  },
  generic: {
    yes: 'Sí',
    not: 'No',
    noOptions: 'Sin opciones',
    all: 'Todo',
    days: 'días',
  },
  upload: {
    completed: 'Subiendo archivos terminados',
    cancel: 'CANCELAR',
    error: {
      title: 'Subir con error',
      description: 'No se puede cargar el archivo, intente nuevamente.',
    },
    finish: {
      title: 'Carga completada',
      description: 'Su archivo ha sido subido con éxito.',
    },
    starting: 'Iniciando carga',
    canceled: 'Carga cancelada',
    loading: 'Cargando archivos',
    dragDrop: 'Arrastrar y soltar o',
    clickSearch: 'haga clic para buscar',
    fileValid: 'Archivo válido, soltar para seleccionar',
    fileNotValid: 'Archivo no permitido',
    limitSize: 'Tamaño máximo permitido hasta',
  },
  words: {
    date: 'Fecha',
  },
  languages: {
    'pt-BR': 'Portugués',
    'es-ES': 'Español',
    'en-US': 'Inglés',
  },
  actions: {
    response: {
      understood: 'Entendí',
    },
  },
  file: {
    modal: {
      exclude: {
        title: 'Borrar archivo',
        description: '¿Está seguro de que desea eliminar este archivo?',
        success: {
          title: 'Éxito',
          description:
            'Esta operación se realizó con éxito. Archivo eliminado.',
        },
      },
    },
  },
}
