export default {
  page: {
    title: 'Register',
  },
  loading: {
    title: 'Loading',
    description: 'Please wait for the end of the request.',
  },
  register: {
    title: 'Make your registration',
    description: 'Fill in the information to complete your registration.',
    form: {
      email: {
        label: 'Email',
        placeholder: 'Type your e-mail',
      },
      password: {
        label: 'Password',
      },
      confirmPassword: {
        label: 'Confirm password',
      },
      button: 'Register',
    },
    hasAccount: 'Already have an account?',
    redirectLogin: 'Login',
  },
  success: {
    title: 'Success!',
    description: {
      p1: 'Your user account has been successfully created.',
      p2: 'You can now manage treatment requests.',
    },
    hasAccount: 'Already have an account?',
    redirectLogin: 'Login',
  },
  notFound: {
    title: 'Token not found!',
    description: {
      p1: 'Token not found in your invite.',
      p2: 'Contact support@email.com.br',
    },
  },
  errorLoading: {
    title: 'Loading error',
    description: {
      p1: 'An error occurred while loading this form.',
      p2: 'It is recommended not to use this form for data entry until this is resolved.',
    },
    error: {
      text: 'Please contact support@email.com.br with the link to this page and the error message below:',
      message: 'unexpected token < in JSON at position 0',
    },
  },
  expired: {
    title: 'Unauthorized access!',
    description: {
      p1: 'Your authentication token is expired.',
    },
  },
  invalid: {
    title: 'Invalid token!',
    description: {
      p1: 'Your new password token authentication is invalid.',
      p2: 'Please try again.',
    },
  },
}
