export default {
  request: {
    title: 'Paciente',
    description: 'Estado de la solicitud',
    list: {
      progress: 'Progreso',
      preAnalysis: 'Solicitud',
      treatmentSuggestion: 'Sugerencia de tratamiento',
      project: 'Proyecto',
      message: 'Mensajero',
    },
    preAnalysis: {
      editButton: 'Editar informacion',
      saveButton: 'Ahorrar',
      sendButton: 'Guardar y enviar',
      cancelButton: 'Cancelar',
      canceledButton: 'Cancelar',
      declinedButton: 'Rechazar',
      approveButton: 'Aprobar',
      reproveButton: 'Fallar',
      continueButton: 'OK',
      backButton: 'Volver',
      modal: {
        success: {
          title: 'Éxito',
          description: 'Esta operación se realizó con éxito.',
          canceled: 'Solicitud cancelada',
          declined: 'Solicitud rechazada',
          descriptions: {
            declined: 'Solicitud declinada en análisis de tomografía.',
            repproved_pre_analysis: 'Solicitud rechazada en preanálisis.',
            repproved_tomography: 'Análisis de tomografía fallido',
            approve_pre_analysis: 'Solicitud aprobada en preanálisis.',
            approve_tomography: 'Solicitud aprobada en análisis de tomografía.',
          },
        },
        title: {
          approve: 'Informar el estado de la solicitud',
          reprove: 'Indique el motivo de la desaprobación',
          cancel: 'Introduce el motivo de la cancelación',
          canceled: 'Introduce el motivo de la cancelación',
          declined: 'Indique el motivo de la declinación',
          repproved_pre_analysis: 'Indique el motivo de la desaprobación',
          repproved_tomography: 'Indique el motivo de la desaprobación',
          approve_pre_analysis: 'Aprobación del preanálisis',
          approve_tomography: 'Informar a qué paso se avanzará',
        },
        subStatus: 'Razón secundaria',
        status: {
          approve: 'Estado',
          reprove: 'Razón',
          placeholder: 'Seleccione una de las razones',
          repproved_pre_analysis: 'Razón',
          approve_pre_analysis: 'Razón',
          repproved_tomography: 'Razón',
          approve_tomography: 'Escenario',
        },
        observation: {
          label: 'Observación',
          placeholder:
            'Ingrese aquí cualquier observación o información que considere relevante',
        },
        reprove: {
          options: {
            dicon: 'Problemas con archivos DICOM',
          },
        },
      },
      approve: {
        title: 'Atención',
        descriptions: {
          in_pre_analysis_of_informations:
            'Este paso requiere dos aprobaciones, revisión de información previa y revisión de tomografía.',
          tomography_analysis:
            'Ya hiciste el pre-análisis de la información, ahora haz el análisis de tomografía.',
        },
      },
    },
    progress: {
      title: 'Seguimiento del estado del pedido',
      tableTile: 'Histórico',
      seeLess: 'Ver menos',
      viewMore: 'Ver más',
      reproved: 'Rechazado',
      declined: 'Rechazado',
      canceled: 'Cancelado',
      skiped: '(Paso omitido)',
      titles: {
        draft: 'Bosquejo',
        waiting: 'Espera',
        approved: 'Aprobado',
        finished: 'Finalizado',
        in_pre_analysis_of_informations: 'Pre análisis',
        tomography_analysis: 'Análisis de tomografía',
        disapproved_no_contrast: 'Sin contraste',
        disapproved_cuts_larger_than_1mm: 'Cortes de más de 1 mm',
        disapproved_acquisition_date_over_6_months:
          'Fecha de adquisición superior a 6 meses',
        disapproved_motion_artifact_3d: 'Artefacto de movimiento (3D)',
        disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Imposibilidad de analizar la reconstrucción 3D',
        disapproved_empty_file: 'Archivo vacío',
        disapproved_others: 'Otras razones',
        disapproved_no_lap_for_standard_care:
          'Sin vuelta para el tratamiento estándar',
        disapproved_no_anatomical_indication_for_customized_treatment:
          'Sin indicación anatómica para un tratamiento personalizado',
        disapproved_excessive_crookedness: 'Tortuosidad excesiva',
        disapproved_no_access: 'Sin acceso',
        disapproved_productive_impossibility: 'imposibilidad productiva',
        discuss_and_change: 'Discutir y cambiar',
        disapproved_does_not_agree_with_the_proposal:
          'No estoy de acuerdo con la propuesta',
        disapproved_patient_withdrew_from_treatment:
          'El paciente se retiró del tratamiento.',
        disapproved_patient_died: 'El paciente murió',
        disapproved_paying_source_did_not_accept: 'Fuente de pago no aceptada',
        disapproved_approved_by_another_company: 'Designado por otra empresa',
        very_complex_solution: 'Solución muy compleja',
        technically_approved: 'Técnicamente Aprobado',
        commercially_approved: 'Aprobado Comercialmente',
        disapproved_finished: 'Finalizado',
        form_to_be_filled_out: 'Formulario a llenar',
        registration_problems: 'Problemas de registro',
        disapproved_no_patient_access: 'Sin acceso de pacientes',
        commercially_waiting: 'Esperando comercialmente',
        approved_specialist: 'Especialista homologado',
        disapproved_requester: 'Solicitante fallido',
        disapproved_specialist: 'Especialista fallido',
        waiting_schedule: 'En espera de cita',
        analisys: 'En el análisis de proyectos',
        treatment_suggestion_repproved_dicom: 'Problemas con archivos DICOM',
      },
      subStatus: {
        draft: 'Bosquejo',
        waiting: 'Espera',
        approved: 'Aprobado',
        finished: 'Finalizado',
        in_pre_analysis_of_informations: 'Pre análisis',
        tomography_analysis: 'Análisis de tomografía',
        disapproved_no_contrast: 'Rechazado - sin contraste',
        disapproved_cuts_larger_than_1mm: 'Rechazado - Cortes de más de 1 mm',
        disapproved_acquisition_date_over_6_months:
          'Rechazado - Fecha de adquisición superior a 6 meses',
        disapproved_motion_artifact_3d:
          'Rechazado - Artefacto de movimiento (3D)',
        disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Rechazado - Imposibilidad de analizar la reconstrucción 3D',
        disapproved_empty_file: 'Rechazado - Archivo vacío',
        disapproved_others: 'Otras razones',
        disapproved_no_lap_for_standard_care:
          'Rechazado - Sin vuelta para el tratamiento estándar',
        disapproved_no_anatomical_indication_for_customized_treatment:
          'Rechazado - Sin indicación anatómica para un tratamiento personalizado',
        disapproved_excessive_crookedness: 'Rechazado - tortuosidad excesiva',
        disapproved_no_access: 'Cancelada - Sin acceso',
        disapproved_productive_impossibility:
          'Rechazado - imposibilidad productiva',
        discuss_and_change: 'Rechazado - Discutir y cambiar',
        disapproved_does_not_agree_with_the_proposal:
          'No estoy de acuerdo con la propuesta',
        disapproved_patient_withdrew_from_treatment:
          'El paciente se retiró del tratamiento.',
        disapproved_patient_died: 'El paciente murió',
        disapproved_paying_source_did_not_accept: 'Fuente de pago no aceptada',
        disapproved_approved_by_another_company: 'Designado por otra empresa',
        very_complex_solution: 'Solución muy compleja',
        technically_approved: 'Técnicamente Aprobado',
        commercially_approved: 'Aprobado Comercialmente',
        disapproved_finished: 'Finalizado',
        form_to_be_filled_out: 'Formulario a llenar',
        registration_problems: 'Problemas de registro',
        disapproved_no_patient_access: 'Sin acceso de pacientes',
        commercially_waiting: 'Esperando comercialmente',
        approved_specialist: 'Especialista homologado',
        disapproved_requester: 'Solicitante fallido',
        disapproved_specialist: 'Especialista fallido',
        waiting_schedule: 'En espera de cita',
        analisys: 'En el análisis de proyectos',
        treatment_suggestion_repproved_dicom:
          'Rechazado - Problemas con archivos DICOM',
      },
      descriptions: {
        draft_waiting: 'Complete la solicitud de registro',
        screening_waiting: 'Se ha creado una nueva solicitud.',
        screening_in_pre_analysis_of_informations:
          'Solicitud en triage, con un pre-análisis de la información.',
        screening_tomography_analysis:
          'Solicitud en triaje, realizándose un análisis de tomografía.',
        screening_disapproved_no_contrast:
          'Se desaprobó solicitud de pre-análisis por problemas en el archivo DICOM, proceder a editar la solicitud.',
        screening_disapproved_cuts_larger_than_1mm:
          'Se desaprobó solicitud de pre-análisis por problemas en el archivo DICOM, proceder a editar la solicitud.',
        screening_disapproved_acquisition_date_over_6_months:
          'La solicitud fue rechazada antes de la fecha de adquisición del análisis más de 6 meses',
        screening_disapproved_motion_artifact_3d:
          'Se rechazó la solicitud de análisis previo de artefactos de movimiento (3D)',
        screening_disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Solicitud rechazada preanálisis imposibilidad de analizar la reconstrucción 3D',
        screening_disapproved_empty_file: 'Archivo vacío',
        screening_tomography_analysis_disapproved_others:
          'Tomografía rechazada por otros motivos, para proceder, llegar a un acuerdo con el especialista.',
        screening_disapproved_others:
          'La solicitud de revisión previa falló por otros motivos.',
        screening_disapproved_no_lap_for_standard_care:
          'Solicitud rechazada debido a la falta de vuelta para el tratamiento estándar.',
        declined_disapproved_no_lap_for_standard_care:
          'Solicitud rechazada debido a la falta de vuelta para el tratamiento estándar.',
        screening_tomography_analysis_disapproved_no_lap_for_standard_care:
          'Solicitud rechazada debido a la falta de vuelta para el tratamiento estándar.',
        declined_disapproved_no_anatomical_indication_for_customized_treatment:
          'Se rechaza la tomografía por no tener indicación anatómica, para proceder se pone de acuerdo con el especialista.',
        screening_tomography_analysis_disapproved_no_anatomical_indication_for_customized_treatment:
          'Se rechaza la tomografía por no tener indicación anatómica, para proceder se pone de acuerdo con el especialista.',
        screening_disapproved_excessive_crookedness:
          'Tomografía rechazada por tener excesiva tortuosidad, para proceder, concertar con el especialista.',
        screening_disapproved_productive_impossibility:
          'Solicitud rechazada por imposibilidad de producción.',
        declined_disapproved_productive_impossibility:
          'Solicitud rechazada por imposibilidad de producción.',
        screening_very_complex_solution: 'Solución muy compleja',
        screening_form_to_be_filled_out: 'Formulario a llenar',
        screening_registration_problems:
          'Solicitud fue rechazada en pre-análisis por problemas de registro, proceder a editar la solicitud.',
        screening_disapproved_no_patient_access:
          'Solicitud rechazada por no tener acceso al paciente.',
        declined_disapproved_no_patient_access:
          'Solicitud rechazada por no tener acceso al paciente.',
        treatment_suggestion_draft:
          'Solicitud en espera de sugerencia de tratamiento por parte del especialista.',
        treatment_suggestion_waiting:
          'Sugerencia de tratamiento pendiente de aprobación.',
        treatment_suggestion_disapproved_others:
          'Sugerencia de tratamiento desaprobada, por otros motivos, para proceder a celebrar un acuerdo.',
        treatment_suggestion_disapproved_does_not_agree_with_the_proposal:
          'Sugerencia de tratamiento desaprobada, no estaban de acuerdo con ella, para proceder a celebrar un acuerdo.',
        treatment_suggestion_approved:
          'Sugerencia de tratamiento aprobada, continúe con el siguiente paso.',
        treatment_suggestion_commercially_waiting: 'Esperando comercialmente',
        endoprosthesis_manufacturing_draft:
          'Fabricación de endoprótesis en espera de producción',
        prototype_draft: 'Prototipo en espera de producción',
        engineering_design_draft:
          'Diseño de ingeniería en espera de producción.',
        endoprosthesis_manufacturing_waiting:
          'Fabricación de endoprótesis en espera de aprobación.',
        prototype_waiting: 'Prototipo pendiente de aprobación.',
        engineering_design_waiting:
          'Proyecto de ingeniería pendiente de aprobación.',
        engineering_design_approved:
          'Proyecto de ingeniería aprobado, pasar al siguiente paso.',
        engineering_design_discuss_and_change:
          'Proyecto de ingeniería Rechazado, hay ajustes por hacer.',
        approved: 'Aprobado',
        finished: 'Finalizado',
        screening_finished: 'Finalizado',
        canceled_disapproved_others: 'Solicitud cancelada por otros motivos',
        declined_disapproved_others: 'Solicitud desaprobada por otros motivos',
        canceled_disapproved_patient_died:
          'Solicitud cancelada, el paciente murió',
        canceled_disapproved_paying_source_did_not_accept:
          'Solicitud cancelada, fuente de pago no aceptada',
        canceled_disapproved_approved_by_another_company:
          'Solicitud cancelada, optó por otra empresa',
        canceled_disapproved_patient_withdrew_from_treatment:
          'Solicitud cancelada, el paciente retiró el tratamiento',
        declined_disapproved_patient_withdrew_from_treatment:
          'Solicitud rechazada, el paciente se retiró del tratamiento',
        declined_disapproved_no_access:
          'Solicitud cancelada, sin acceso al paciente',
        approved_specialist: 'Especialista homologado',
        engineering_design_disapproved_requester:
          'Proyecto de ingeniería fallido, hay que hacer ajustes',
        engineering_design_disapproved_specialist:
          'Proyecto de ingeniería fallido, hay que hacer ajustes',
        engineering_design_waiting_schedule:
          'Calendario de proyectos en espera',
        engineering_design_analisys:
          'Diseño de ingeniería en espera de aprobación de expertos',
        treatment_suggestion_repproved_dicom:
          'Se desaprobó solicitud de pre-análisis por problemas en el archivo DICOM, proceder a editar la solicitud.',
      },
    },
    treatmentSuggestion: {
      upload: {
        title: 'Informe pericial',
        description: 'Adjunte aquí el informe para solicitarlo',
      },
      send: {
        title: 'Someter para aprobacion',
        description: 'Enviar el informe para su aprobación.',
        button: 'Mandar',
      },
      modal: {
        success: {
          technically_approved:
            'Sugerencia de tratamiento técnicamente aprobado.',
          commercially_approved:
            'Sugerencia de tratamiento aprobado comercialmente.',
          reprove: 'Sugerencia de tratamiento rechazada.',
          approve: 'Sugerencia de tratamiento aprobada.',
        },
        title: {
          approve: 'Introduzca el tipo de aprobación',
          reprove: 'Indique el motivo de la desaprobación',
        },
        statusLabel: {
          commercially_waiting: {
            title: 'Aprobar comercialmente',
            description:
              'Has aprobado técnicamente, ahora solo puedes aprobar o desaprobar comercialmente.',
          },
          technically_waiting: {
            title: 'Aprobar técnicamente',
            description:
              'Ya has aprobado comercialmente, ahora solo puedes aprobar o desaprobar técnicamente.',
          },
        },
      },
    },
    project: {
      schedule: {
        title: 'Programar proyecto de ingeniería',
        description: 'Reserva aquí tu proyecto de ingeniería',
        button: 'Para programar',
        priorizate: 'Priorizar',
      },
      file: {
        title: 'Datos de diseño de ingeniería',
        subtitle: 'Archivo de diseño de ingeniería',
        description: 'Ver el archivo de diseño de ingeniería',
      },
      prototype: {
        title: 'Necesidad de un prototipo',
        description: 'Veredicto de producción',
        acceptTerms: 'Leo y acepto.',
        p: {
          1: 'Tras un minucioso análisis realizado por nuestro equipo de producción, hemos detectado que la endoprótesis que nos solicita tiene una complejidad considerable y, por tanto, será necesario fabricar un prototipo antes de proceder a la fabricación de la endoprótesis definitiva.',
          2: 'Queremos señalar que la realización del prototipo es un paso fundamental en el proceso de fabricación de endoprótesis, ya que nos permite probar el diseño en condiciones controladas y ajustarlo según sea necesario. De esta forma, podemos garantizar que la endoprótesis definitiva tendrá la eficacia y seguridad necesarias para cumplir su cometido.',
          3: 'Sin embargo, es importante recordar que producir un prototipo agrega un paso más al proceso de fabricación y, por lo tanto, puede llevar un poco más de tiempo completar el proyecto. Además, tras fabricar el prototipo, se probará en el laboratorio para evaluar su eficacia y seguridad.',
        },
      },
      observation: {
        title: 'Nota del diseñador',
      },
      modal: {
        success: {
          descriptions: {
            success_schedule:
              'Proyecto de ingeniería programado para otra fecha.',
            success_specialist_approve: 'Proyecto de ingeniería aprobado.',
            success_consultant_approve: 'Proyecto de ingeniería aprobado.',
            success_send_project: 'Diseño de ingeniería presentado.',
            success_reprove: 'Proyecto de ingeniería fallido.',
          },
        },
        reprove: {
          title: 'Informar los ajustes a realizar',
          observation: {
            label: 'Configuraciones y detalles',
            placeholder: 'Ingrese aquí los detalles y ajustes a realizar',
          },
        },
        approve: {
          title: '¿Está seguro de que desea aprobar el proyecto de ingeniería?',
        },
        schedule: {
          title: 'Programacion de proyectos de ingenieria',
          reset: 'Reiniciar',
          calendar: {
            title: 'Calendario',
            description: 'Elija el mes de la cita',
          },
          resume: {
            title: 'Mas informaciones',
            label: 'Fecha de programación del proyecto',
            notFound: 'No hay solicitudes programadas para este día',
          },
          current: {
            title: 'Nuevo proyecto de ingenieria',
            description:
              'Mover el nuevo proyecto de ingeniería a la cola de producción',
          },
          queue: {
            procedure: 'Procedimento',
            title: 'Cola de producción mensual',
            description: 'Priorizar el nuevo proyecto en la cola de producción',
          },
        },
      },
    },
  },
}
