import { apiGateway } from '@configs/api'
import { braileDB } from '@configs/db'
import create from 'zustand'

export type FileData = {
  file: File
  name: string
  data: any
  id?: number
}

type UploadStory = {
  files: FileData[]
  visible: boolean
  updateFile: (index: number, data: any) => void
  addFile: (file: File, name: string, data: any) => void
  removeFile: (index: number) => void
  replaceFile: (index: number, file: File, name: string, data: any) => void
  setVisible: (visible: boolean) => void
  reset: () => void
}

export const useUploadStory = create<UploadStory>((set) => ({
  files: [],
  visible: false,
  reset: () => set({ files: [], visible: false }),
  addFile: (file: File, name: string, data: any) => {
    set((prev) => ({
      ...prev,
      visible: true,
      files: [...prev.files, { file, name, data }],
    }))
  },
  updateFile: (index: number, data: any) =>
    set((prev) => {
      const files = [...prev.files]
      files[index] = { ...files[index], id: data }
      return {
        ...prev,
        files: [...files],
      }
    }),
  replaceFile: (index: number, file: File, name: string, data: any) =>
    set((prev) => {
      const files = [...prev.files]
      files[index] = { file, name, data }
      return {
        ...prev,
        files: [...files],
      }
    }),
  removeFile: async (index: number) => {
    const filesDb = await braileDB.files.toArray()

    if (filesDb.length > index + 1) {
      await braileDB.files.delete(filesDb[index].id ?? 0)
    }

    set((prev) => {
      if (prev.files[index]?.id) {
        apiGateway.delete({
          url: `/files/${prev.files[index].id}`,
        })
      }

      return {
        ...prev,
        files: [...prev.files.slice(0, index), ...prev.files.slice(index + 1)],
      }
    })
  },
  setVisible: (visible: boolean) => set((prev) => ({ ...prev, visible })),
}))
