export const generateHash = async (algorithm: string, data: Blob) => {
  const main = async (msgUint8: Uint8Array) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
    const hashBuffer = await crypto.subtle.digest(algorithm, msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  }

  if (data instanceof Blob) {
    const arrayBuffer = await data.arrayBuffer()
    const msgUint8 = new Uint8Array(arrayBuffer)
    return main(msgUint8)
  }
  const encoder = new TextEncoder()
  const msgUint8 = encoder.encode(data)
  return main(msgUint8)
}
