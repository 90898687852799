export default {
  modal: {
    title: 'Hospital data',
    description: 'Fill in the hospital information',
    cancelButton: 'Cancel',
    saveButton: 'Save',
    toast: {
      success: {
        title: 'Success!',
        description: 'Hospital registered successfully!',
      },
      error: {
        title: 'Error!',
        'e-mail_already_in_use':
          'Email is already being used by another hospital',
        register: {
          generic: 'There was an error registering the hospital!',
        },
        update: {
          generic: 'There was an error updating the hospital!',
        },
      },
    },
  },
  form: {
    name: {
      label: 'Name',
      placeholder: 'Enter the hospital name',
    },
    email: {
      label: 'Email',
      placeholder: 'Enter the hospital email',
    },
    phone: {
      label: 'Mobile phone',
    },
    language: {
      label: 'Language',
      placeholder: 'Select an option',
    },
    telephone: {
      label: 'Telephone',
    },
    invite: {
      title: 'Make the hospital a user of the platform',
      description:
        'Soon after completing the registration, an invitation will be sent by email for the hospital to become a user of the platform.',
    },
  },
  table: {
    newButton: 'New hospital',
    actions: {
      update: 'Change registration',
      delete: 'Delete registration',
    },
    emptyTitle: 'Register a hospital',
    emptyDescription:
      "You don't have any hospitals yet. Register your first hospital and their information will be displayed here.",
  },
  register: {
    title: 'Hospital',
    cancelButton: 'Cancel',
    saveButton: 'Save',
    sections: {
      title: 'Hospital data',
      description: 'Fill in the hospital information',
    },
    modal: {
      success: {
        title: 'Success',
        description: 'This operation was successfully performed.',
      },
      saveDescription: 'Saved hospital data.',
      isUser: 'Hospital data saved and invitation sent by email.',
      error: {
        title: 'Error',
      },
    },
  },
  update: {
    title: 'Hospital Edition',
    actionButton: 'Edit information',
  },
  exclude: {
    modal: {
      initial: {
        title: 'Hospital exclusion',
        description: 'Are you sure you want to delete this hospital?',
      },
      success: {
        title: 'Success',
        description: 'This operation was successfully performed.',
        passwordReset: 'Password reset link has been sent to the hospital.',
        exclude: 'Saved hospital data.',
        invite: 'Invitation resent to hospital',
      },
      error: {
        title: 'Error',
      },
    },
  },
}
