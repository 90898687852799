export default {
  title: 'Notifications',
  markAll: 'Mark all as read',
  empty: "You don't have notifications yet",
  titles: {
    draft_awaiting_dicon: 'Request in draft',
    draft: 'Request in draft',
    created: 'New request created',
    screening_pre_analysis: 'Triage request',
    screening_disapproved_registration_problems: 'Triage request',
    screening_disapproved_dicon: 'Triage request',
    screening_approved: 'Triage request',
    screening_disapproved_no_lap_for_standard_care: 'Request declined',
    screening_disapproved_no_patient_access: 'Request declined',
    screening_disapproved_productive_impossibility: 'Request declined',
    screening_disapproved_no_atomical_indication: 'Triage request',
    screening_disapproved_no_excessive_crookdness: 'Triage request',
    screening_disapproved_others: 'Triage request',
    treatment_suggestion_draft: 'Request for treatment suggestion',
    treatment_suggestion_waiting_consultant_approved:
      'Request for treatment suggestion',
    treatment_suggestion_approved: 'Request for treatment suggestion',
    treatment_suggestion_commercially_approved: 'Commercial Approval',
    treatment_suggestion_consultant_approve_request:
      'Engineering design request',
    treatment_approved: 'Request approved',
    engineering_design_waiting: 'Engineering design request',
    endoprosthesis_manufacturing_waiting:
      'Application in endoprosthesis manufacturing',
    treatment_suggestion_disapproved_approved_does_not_agree_with_the_proposal:
      'Request canceled',
    treatment_suggestion_disapproved_paying_source_did_not_accept:
      'Request canceled',
    treatment_suggestion_disapproved_others: 'Request for treatment suggestion',
    treatment_suggestion_disapproved_by_another_company: 'Request canceled',
    canceled_disapproved_patient_died: 'Request canceled',
    canceled_disapproved_patient_others: 'Request canceled',
    canceled_disapproved_withdrew_from_treatment: 'Request canceled',
    declined_others: 'Request declined',
    declined_disapproved_patient_withdrew_from_treatment: 'Request declined',
    approved: 'Request approved',
    declined: 'Request declined',
    canceled: 'Request canceled',
    finished: 'Completed request',

    draft_awaiting_consultant: 'Triage request',
    screening_tomography_analysis: 'Triage request',
    treatment_disapproved_others: 'Request for treatment suggestion',
    engineering_design_draft: 'Engineering design request',
    engineering_design_approved: 'Engineering Project approved',
    engineering_design_repproved: 'Failed Engineering Project',
    treatment_suggestion_approved_consultant: 'Engineering design request',
    engineering_design_waiting_title: 'Engineering design request',
    screening_disapproved_others_agreement_with_specialist: 'Triage request',
    prototype_waiting: 'Request in prototype',
  },
  descriptions: {
    draft_awaiting_dicon:
      'Consultant your request is in draft, to proceed attach the DICOM file',
    draft:
      'Consultant your request is in draft, talk to the consultant for more information',
    created: 'Expert a new request has been created, click here to view it',
    screening_pre_analysis:
      'Consultant, your request is being sorted, and a pre-analysis of information is being carried out',
    screening_disapproved_registration_problems:
      'Consultant your request was rejected in pre-analysis due to problems in the registration, to proceed edit the request',
    screening_disapproved_dicon:
      'Consultant your request was disapproved pre-analysis due to problems in the DICOM file, to proceed edit the request',
    screening_approved:
      'Consultant your request was approved in the screening phase',
    treatment_suggestion_approved_consultant:
      'Consultant the treatment suggestion was approved and the request proceeds to the next step',
    treatment_suggestion_approved_specialist:
      'Specialist your treatment suggestion has been approved, proceed to the next step',
    treatment_suggestion_consultant_approve_request:
      'Consultant approves treatment suggestion',
    treatment_suggestion_commercially_approved:
      'Consultant your request has been commercially approved',
    screening_disapproved_no_lap_for_standard_care:
      'Consultant your request was declined because you do not have a lap for standard treatment',
    screening_disapproved_no_patient_access:
      'Consultant your request was declined because you do not have access to the patient',
    screening_disapproved_productive_impossibility:
      'Consultant your request was declined due to production impossibility',
    screening_disapproved_no_atomical_indication:
      'Consultant your tomography was rejected because it has no anatomical indication, to proceed enter into an agreement',
    screening_disapproved_no_excessive_crookdness:
      'Consultant your tomography was rejected for having excessive tortuousness, to proceed enter into an agreement',
    screening_disapproved_others:
      'Consultant Your application failed pre-review for other reasons, click here for more information',
    treatment_suggestion_draft:
      "Consultant, the request is awaiting the specialist's treatment suggestion.",
    treatment_suggestion_waiting_consultant_approved:
      'Consultant approves treatment suggestion',
    treatment_suggestion_waiting: 'Consultant, approve treatment suggestion',
    treatment_suggestion_disapproved_others:
      'Expert request was rejected for other reasons, click here for more information',
    treatment_suggestion_disapproved_by_another_company:
      'Specialist the request was cancelled, they opted for another company, click here for more information',
    treatment_suggestion_disapproved_paying_source_did_not_accept:
      'Specialist the request was cancelled, paying source not accepted, click here for more information',
    treatment_suggestion_disapproved_approved_does_not_agree_with_the_proposal:
      'Specialist your treatment suggestion was rejected, they did not agree with it, to proceed enter into an agreement',
    engineering_design_waiting: 'Consultant, approve engineering design.',
    endoprosthesis_manufacturing_waiting:
      'Consultant your request is awaiting appointment',
    declined_others:
      'Consultant the request was declined for other reasons, click here for more information',
    declined_disapproved_patient_withdrew_from_treatment:
      'Consultant patient withdrew from treatment, click here for more information',
    finished: 'The request has reached the end of all stages',
    canceled_disapproved_patient_others:
      'Expert request was canceled for other reasons, click here for more information',
    canceled_disapproved_patient_died:
      'Specialist the patient passed away, click here for more information',
    canceled_disapproved_withdrew_from_treatment:
      'Specialist the patient withdrew from treatment, click here for more information',

    draft_awaiting_consultant:
      'Consultant, your request is being sorted, with a pre-analysis of information being carried out',
    screening_tomography_analysis:
      'Consultant, your request is being screened and a tomography analysis is being carried out.',
    treatment_suggestion_approved:
      'Specialist, your treatment suggestion has been approved, proceed to the next step',
    treatment_disapproved_others:
      'Expert, the request was disapproved for other reasons, click here for more information',
    engineering_design_draft:
      'Designer, there is an engineering project to be done.',
    engineering_design_approved:
      'Expert, the engineering project has been approved, proceed to the next step.',
    engineering_design_repproved:
      'Expert, the engineering project has failed, there are adjustments to be made.',
    engineering_design_waiting_description:
      'Consultant, approve engineering design.',
    draft_waiting:
      'Consultant your request is in draft, talk to the consultant for more information',
    screening_disapproved_others_agreement_with_specialist:
      'Consultant, your tomography was rejected for other reasons, to proceed, enter into an agreement with the specialist.',
    prototype_waiting_applicant_prototype_production:
      'Applicant, prototype is in production',
    endoprosthesis_manufacturing_waiting_endoprosthesis_protuction:
      'Applicant, the endoprosthesis is in production',
    finished_finished: 'Requester, your request has been finalized',
  },
}
