import { apiGateway } from '@configs/api'
import { fileAtom } from '@states/atoms/file'
import { useUploadStory } from '@states/stores/upload'
import { generateHash } from '@utils/file'
import axios, { Canceler } from 'axios'
import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import useAsyncQueue from 'use-async-queue'

export type FileUpload = {
  projectRequestId: number
  file?: File
  name?: string
  fileType?: string
  data?: any
  progress?: number
  started?: boolean
  uploaded?: boolean
  error?: boolean
  cancel?: Canceler
  canceled?: boolean
  hash?: string
}

const isSameFile = (file: FileUpload, item: Partial<FileUpload>) =>
  item?.projectRequestId === file.data.projectRequestId &&
  item?.file?.name === file.file?.name &&
  item?.fileType === file?.data?.fileType &&
  item?.name === file.name

export const useUpload = () => {
  const { files, updateFile } = useUploadStory()
  const queryClient = useQueryClient()
  const [queue, setQueue] = useAtom(fileAtom)

  const cancelUpload = useCallback(() => {
    queue.forEach((file) => {
      if (file.uploaded || file.error || file.canceled) return

      file.cancel && file.cancel()
    })
  }, [queue])

  const updateFileInQueue = useCallback(
    (projectRequestId: number, data: any) => {
      setQueue((prev) =>
        prev.map((item) => {
          return isSameFile(item, { ...data, projectRequestId })
            ? { ...item, ...data }
            : item
        })
      )
    },
    []
  )

  const uploadFile = async (processFile: FileUpload, index: number) => {
    if (
      processFile.progress ||
      processFile.cancel ||
      processFile.error ||
      processFile.uploaded ||
      processFile.canceled ||
      !processFile.hash
    )
      return

    const form = new FormData()
    form.append('file', processFile.file as File, processFile.file?.name)
    form.append('project_request_id', String(processFile.data.projectRequestId))
    form.append('file_type', processFile.data.fileType)
    form.append('hash', processFile.hash)
    let cancelRequest: Canceler

    apiGateway
      .post(
        {
          url: '/files/file',
          body: form,
        },
        {
          cancelToken: new axios.CancelToken(function executor(cancel) {
            updateFileInQueue(processFile.projectRequestId, {
              name: processFile.name,
              file: processFile.file,
              fileType: processFile.fileType,
              cancel,
            })
            cancelRequest = cancel
          }),
          onUploadProgress: (progressEvent) => {
            setQueue((prev) => {
              const newQueues = [...prev]
              if (!newQueues.some((item) => isSameFile(item, processFile))) {
                cancelRequest()
                return prev
              }

              const progress: number = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 0)
              )
              const queueIndex = newQueues.findIndex((item) =>
                isSameFile(item, processFile)
              )
              newQueues[queueIndex].progress = progress
              return newQueues
            })
          },
        }
      )
      .then((res) => {
        updateFile(index, res.data.id)
        updateFileInQueue(processFile.projectRequestId, {
          name: processFile.name,
          file: processFile.file,
          fileType: processFile.fileType,
          uploaded: true,
        })
        queryClient.refetchQueries(['projects-requests'])
        // queryClient.refetchQueries(['requests-by-month'])
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          updateFileInQueue(processFile.projectRequestId, {
            name: processFile.name,
            file: processFile.file,
            fileType: processFile.fileType,

            canceled: true,
          })
          return
        }
        updateFileInQueue(processFile.projectRequestId, {
          name: processFile.name,
          file: processFile.file,
          fileType: processFile.fileType,
          error: true,
        })
      })
  }

  const hashQueue = useAsyncQueue({
    concurrency: 1,
    done: (obj) => {
      obj.result?.then((hash) => {
        setQueue((prev) =>
          prev.map((item, index) => {
            if (index + 1 === obj.id) {
              return { ...item, hash }
            }

            return item
          })
        )
      })
    },
  })

  const addFileInQueue = () => {
    setQueue((prev) =>
      files.map((file) => {
        const newFile = {
          file: file.file,
          data: file.data,
          name: file.name,
          projectRequestId: file.data.projectRequestId,
          fileType: file.data.fileType,
        }

        const existedFile = prev.find((item) => isSameFile(item, newFile))
        if (existedFile) {
          return {
            ...existedFile,
            uploaded: Boolean(
              existedFile?.progress && existedFile.progress >= 100
            ),
          }
        }

        hashQueue.add({
          id: prev.length + 1,
          task: async () => generateHash('SHA-256', new Blob([newFile.file])),
        })

        return newFile
      })
    )
  }

  useEffect(() => {
    if (!files.length) return

    addFileInQueue()
  }, [files])

  useEffect(() => {
    if (!queue.length) return

    if (queue.every((file) => file.uploaded)) return

    queue.forEach((file, index) => {
      uploadFile(file, index)
    })
  }, [queue])

  return {
    queue,
    updateFileInQueue,
    uploadFile,
    cancelUpload,
  }
}
