export default {
  links: {
    requests: 'Peticiones',
    catalogEndoprosthesis: 'Catálogo de endoprótesis',
    logout: 'Cerrar sesión',
    editProfile: 'Editar Perfil',
    doctor: 'Médicos',
    hospital: 'Hospitales',
    dashboard: 'Dashboard',
    priorizate: 'Priorización de proyectos',
    user: 'Usuarios',
  },
}
