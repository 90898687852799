export default {
  form: {
    title: 'Password Reset',
    description:
      "Create a new secure password, different from the last one you've used and follow our password pattern",
    password: {
      label: 'Password',
    },
    confirmPassword: {
      label: 'Confirm password',
    },
    submit: {
      text: 'Reset',
    },
  },
  instructions: {
    title: 'New password instructions',
    i1: 'Alphanumeric that has at least 8 characters;',
    i2: '1 or more lowercase letters;',
    i3: '1 or more capital letters;',
    i4: '1 or more numeric characters;',
    i5: '1 or more special characters (e.g. $*&@¨%!#)',
  },
  toast: {
    success: {
      title: 'Success!',
      description: 'Password successfully reset!',
    },
  },
  page: {
    title: 'Password Reset',
  },
}
