export default {
  message: 'Test message',
  other: 'Other Test',
  title: {
    translator: 'Component Translator',
    input: 'Component Input',
    button: 'Component Button',
  },
  text: {
    input: 'Select input variant: ',
    button: 'Select button variant: ',
  },
}
