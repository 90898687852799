import { styled, keyframes } from '@stitches/react'
import * as ToastPrimitive from '@radix-ui/react-toast'
import { FC, useCallback } from 'react'
import CloseIcon from '@assets/icons/close.svg'
import { useToast } from '@states/stores/toast'
import { Translator } from '../Translator'
import { toastIcons } from './config'

const VIEWPORT_PADDING = 25

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
})

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
})

const StyledViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 390,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 2147483647,
  outline: 'none',
})

const StyledToast = styled(ToastPrimitive.Root, {
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  padding: 15,
  display: 'grid',
  columnGap: 15,
  alignItems: 'center',

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },
})

const StyledTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
})

const StyledDescription = styled(ToastPrimitive.Description, {
  gridArea: 'description',
})

const IconContainer = styled('div', {
  marginRight: '1.563rem',
})

const ToastViewport = StyledViewport
const ToastTitle = StyledTitle
const ToastDescription = StyledDescription
const ToastClose = ToastPrimitive.Close

const gridTemplateDefault = {
  gridTemplateAreas: '"title action" "description action"',
  gridTemplateColumns: 'auto max-content',
}

const gridTemplateWithIcon = {
  gridTemplateAreas: '"icon title action" "icon description action"',
  gridTemplateColumns: '10% auto max-content',
}

export const Toast: FC = () => {
  const toast = useToast()

  const openChange = useCallback(
    (open: boolean) => {
      if (!open) {
        toast.close()
        return
      }
      toast.open(toast.title, toast.description, toast.icon)
    },
    [toast]
  )

  return (
    <>
      <StyledToast
        style={toast.icon ? gridTemplateWithIcon : gridTemplateDefault}
        open={toast.visible}
        onOpenChange={openChange}
      >
        {toast.icon && <IconContainer>{toastIcons[toast.icon]}</IconContainer>}
        <ToastTitle className="text-dark-gray-500 text-base font-bold">
          <Translator path={toast.title} />
        </ToastTitle>
        <ToastDescription className="mt-2 text-light-gray-500 text-base font-semibold">
          <Translator path={toast.description} />
        </ToastDescription>
        <ToastClose onClick={toast.close}>
          <CloseIcon />
        </ToastClose>
      </StyledToast>
      <ToastViewport />
    </>
  )
}
