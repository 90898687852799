export default {
  request: {
    title: 'Patient',
    description: 'Request Status',
    list: {
      progress: 'Progress',
      preAnalysis: 'Request',
      treatmentSuggestion: 'Treatment Suggestion',
      project: 'Project',
      message: 'Messenger',
    },
    preAnalysis: {
      editButton: 'Edit information',
      saveButton: 'Save',
      sendButton: 'Save and send',
      cancelButton: 'Cancel',
      canceledButton: 'Cancel',
      declinedButton: 'Decline',
      approveButton: 'Approve',
      reproveButton: 'Disapproval',
      continueButton: 'OK',
      backButton: 'Back',
      modal: {
        success: {
          title: 'Success',
          description: 'This operation was performed successfully.',
          canceled: 'Request canceled',
          declined: 'Request declined',
          descriptions: {
            declined: 'Request declined in tomography analysis.',
            repproved_pre_analysis: 'Request rejected in pre-analysis.',
            repproved_tomography: 'Failed tomography analysis',
            approve_pre_analysis: 'Request approved in pre-analysis.',
            approve_tomography: 'Request approved in tomography analysis.',
          },
        },
        title: {
          approve: 'Report the status of the request',
          reprove: 'State the reason for disapproval',
          cancel: 'Enter the reason for cancellation',
          canceled: 'Enter the reason for cancellation',
          declined: 'State the reason for the decline',
          repproved_pre_analysis: 'State the reason for disapproval',
          repproved_tomography: 'State the reason for disapproval',
          approve_pre_analysis: 'Pre-analysis approval',
          approve_tomography: 'Inform which step will be advanced to',
        },
        subStatus: 'Sub reason',
        status: {
          approve: 'Status',
          reprove: 'Reason',
          placeholder: 'Select one of the reasons',
          repproved_pre_analysis: 'Reason',
          approve_pre_analysis: 'Reason',
          repproved_tomography: 'Reason',
          approve_tomography: 'Stage',
        },
        observation: {
          label: 'Observation',
          placeholder:
            'Enter here any observation or information that you think is relevant',
        },
        reprove: {
          options: {
            dicon: 'Problemas no arquivo DICOM',
          },
        },
      },
      approve: {
        title: 'Attention',
        descriptions: {
          in_pre_analysis_of_informations:
            'This step requires two approvals, Pre-Information Review and Tomography Review.',
          tomography_analysis:
            'You have already done the pre-analysis of information, now do the tomography analysis.',
        },
      },
    },
    progress: {
      title: 'Track order status',
      tableTile: 'Historic',
      seeLess: 'See less',
      viewMore: 'View more',
      reproved: 'Disapproved',
      declined: 'Declined',
      canceled: 'Cancelado',
      skiped: '(Skipped step)',
      titles: {
        draft: 'Draft',
        waiting: 'Waiting',
        approved: 'Approved',
        finished: 'Finished',
        in_pre_analysis_of_informations: 'Pre analysis',
        tomography_analysis: 'Tomography analysis',
        disapproved_no_contrast: 'No contrast',
        disapproved_cuts_larger_than_1mm: 'Cuts larger than 1mm',
        disapproved_acquisition_date_over_6_months:
          'Acquisition date over 6 months',
        disapproved_motion_artifact_3d: 'Motion Artifact(3D)',
        disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Impossibility of analyzing the 3D reconstruction',
        disapproved_empty_file: 'empty file',
        disapproved_others: 'Other reasons',
        disapproved_no_lap_for_standard_care: 'No lap for standard treatment',
        disapproved_no_anatomical_indication_for_customized_treatment:
          'No anatomical indication for customized treatment',
        disapproved_excessive_crookedness: 'Excessive crookedness',
        disapproved_no_access: 'No access',
        disapproved_productive_impossibility: 'Productive impossibility',
        discuss_and_change: 'Discuss and Change',
        disapproved_does_not_agree_with_the_proposal:
          'Disagree with the proposal',
        disapproved_patient_withdrew_from_treatment:
          'Patient withdrew from treatment',
        disapproved_patient_died: 'Patient died',
        disapproved_paying_source_did_not_accept: 'Paying source not accepted',
        disapproved_approved_by_another_company: 'Chose another company',
        very_complex_solution: 'Very complex solution',
        technically_approved: 'Technically Approved',
        commercially_approved: 'Commercially Approved',
        disapproved_finished: 'Finished',
        form_to_be_filled_out: 'Form to be filled out',
        registration_problems: 'Registration problems',
        disapproved_no_patient_access: 'No patient access',
        commercially_waiting: 'Awaiting commercially',
        approved_specialist: 'Approved specialist',
        engineering_design_disapproved_requester:
          'Disapproved - Settings and details',
        engineering_design_disapproved_specialist:
          'Disapproved - Settings and details',
        engineering_design_waiting_schedule: 'Awaiting appointment',
        engineering_design_analisys: 'In project analysis',
        treatment_suggestion_repproved_dicom: 'DICOM File Problems',
      },
      subStatus: {
        draft: 'Draft',
        waiting: 'Waiting',
        approved: 'Approved',
        finished: 'Finished',
        in_pre_analysis_of_informations: 'Pre analysis',
        tomography_analysis: 'Tomography analysis',
        disapproved_no_contrast: 'Disapproved - No contrast',
        disapproved_cuts_larger_than_1mm: 'Disapproved - Cuts larger than 1mm',
        disapproved_acquisition_date_over_6_months:
          'Disapproved - Purchase date older than 6 months',
        disapproved_motion_artifact_3d: 'Disapproved - Motion Artifact(3D)',
        disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Disapproved - Impossible to analyze the 3D reconstruction',
        disapproved_empty_file: 'Disapproved - Empty file',
        disapproved_others: 'Other reasons',
        disapproved_no_lap_for_standard_care:
          'Disapproved - No lap for standard treatment',
        disapproved_no_anatomical_indication_for_customized_treatment:
          'Disapproved - No anatomical indication for customized treatment',
        disapproved_excessive_crookedness:
          'Disapproved - Excessive crookedness',
        disapproved_no_access: 'Disapproved - No access',
        disapproved_productive_impossibility:
          'Disapproved - Productive impossibility',
        discuss_and_change: 'Disapproved - Discuss and Change',
        disapproved_does_not_agree_with_the_proposal:
          'Disagree with the proposal',
        disapproved_patient_withdrew_from_treatment:
          'Patient withdrew from treatment',
        disapproved_patient_died: 'Patient died',
        disapproved_paying_source_did_not_accept: 'Paying source not accepted',
        disapproved_approved_by_another_company: 'Appointed by another company',
        very_complex_solution: 'Very complex solution',
        technically_approved: 'Technically Approved',
        commercially_approved: 'Commercially Approved',
        disapproved_finished: 'Finished',
        form_to_be_filled_out: 'Form to be filled out',
        registration_problems: 'Registration problems',
        disapproved_no_patient_access: 'No patient access',
        commercially_waiting: 'Awaiting commercially',
        approved_specialist: 'Approved specialist',
        engineering_design_disapproved_requester:
          'Disapproved - Settings and details',
        engineering_design_disapproved_specialist:
          'Disapproved - Settings and details',
        engineering_design_waiting_schedule: 'Awaiting appointment',
        engineering_design_analisys: 'In project analysis',
        treatment_suggestion_repproved_dicom:
          'Disapproved - DICOM File Problems',
      },
      descriptions: {
        draft_waiting: 'Complete the request registration',
        screening_waiting: 'A new request has been created.',
        screening_in_pre_analysis_of_informations:
          'Request in triage, with a pre-analysis of information.',
        screening_tomography_analysis:
          'Request in triage, being made a tomography analysis.',
        screening_disapproved_no_contrast:
          'Request was disapproved of pre-analysis due to problems in the DICOM file, to proceed edit the request.',
        screening_disapproved_cuts_larger_than_1mm:
          'Request was disapproved of pre-analysis due to problems in the DICOM file, to proceed edit the request.',
        screening_disapproved_acquisition_date_over_6_months:
          'Request was rejected pre-analysis acquisition date over 6 months',
        screening_disapproved_motion_artifact_3d:
          'Request was rejected motion artifact pre-analysis (3D)',
        screening_disapproved_impossibility_of_analyzing_the_3d_reconstruction:
          'Request was rejected pre-analysis impossibility of analyzing the 3D reconstruction',
        screening_disapproved_empty_file: 'Empty file',
        screening_tomography_analysis_disapproved_others:
          'Tomography rejected for other reasons, to accept enter into an agreement with the specialist.',
        screening_disapproved_others:
          'Request failed pre-review for other reasons.',
        screening_disapproved_no_lap_for_standard_care:
          'Request declined due to lack of lap for standard treatment.',
        declined_disapproved_no_lap_for_standard_care:
          'Request declined due to lack of lap for standard treatment.',
        screening_tomography_analysis_disapproved_no_anatomical_indication_for_customized_treatment:
          'Tomography rejected because it has no anatomical indication, to proceed, enter into an agreement with the specialist.',
        screening_disapproved_excessive_crookedness:
          'Tomography rejected for having excessive tortuosity, to proceed, enter into an agreement with the specialist.',
        screening_tomography_analysis_disapproved_no_lap_for_standard_care:
          'Request declined due to lack of lap for standard treatment.',
        declined_disapproved_no_anatomical_indication_for_customized_treatment:
          'Tomography rejected because it has no anatomical indication, to proceed, enter into an agreement with the specialist.',
        screening_disapproved_productive_impossibility:
          'Request declined due to production impossibility.',
        declined_disapproved_productive_impossibility:
          'Request declined due to production impossibility.',
        screening_very_complex_solution: 'Very complex solution',
        screening_form_to_be_filled_out: 'Form to be filled out',
        screening_registration_problems:
          'Request was rejected in pre-analysis due to registration problems, to proceed edit the request.',
        screening_disapproved_no_patient_access:
          'Request declined due to not having access to the patient.',
        declined_disapproved_no_patient_access:
          'Request declined due to not having access to the patient.',
        treatment_suggestion_draft:
          'Request awaiting treatment suggestion from the specialist.',
        treatment_suggestion_waiting: 'Treatment suggestion awaiting approval.',
        treatment_suggestion_disapproved_others:
          'Treatment suggestion disapproved, for other reasons, to proceed enter into an agreement.',
        treatment_suggestion_disapproved_does_not_agree_with_the_proposal:
          'Disapproved treatment suggestion, they did not agree with it, to proceed enter into an agreement.',
        treatment_suggestion_approved:
          'Treatment suggestion approved, proceed to the next step.',
        treatment_suggestion_commercially_waiting: 'Awaiting commercially',
        endoprosthesis_manufacturing_draft:
          'Manufacture of endoprosthesis awaiting production',
        prototype_draft: 'Prototype awaiting production',
        engineering_design_draft: 'Engineering design awaiting production.',
        endoprosthesis_manufacturing_waiting:
          'Manufacture of endoprosthesis awaiting approval.',
        prototype_waiting: 'Prototype awaiting approval.',
        engineering_design_waiting: 'Engineering project awaiting approval.',
        engineering_design_approved:
          'Engineering project approved, proceed to the next step.',
        engineering_design_discuss_and_change:
          'Disapproved engineering design, there are adjustments to be made.',
        approved: 'Approved',
        finished: 'Finished',
        screening_finished: 'Finished',
        canceled_disapproved_others: 'Request canceled for other reasons',
        declined_disapproved_others: 'Request disapproved for other reasons',
        canceled_disapproved_patient_died: 'Request cancelled, patient died',
        canceled_disapproved_paying_source_did_not_accept:
          'Request canceled, paying source not accepted',
        canceled_disapproved_approved_by_another_company:
          'Request cancelled, opted for another company',
        canceled_disapproved_patient_withdrew_from_treatment:
          'Request cancelled, patient withdrew treatment',
        declined_disapproved_patient_withdrew_from_treatment:
          'Request declined, patient withdrew from treatment',
        declined_disapproved_no_access:
          'Request declined, no access to patient',
        approved_specialist: 'Approved specialist',
        engineering_design_disapproved_requester:
          'Engineering design failed, there are adjustments to be made',
        engineering_design_disapproved_specialist:
          'Engineering design failed, there are adjustments to be made',
        engineering_design_waiting_schedule: 'Project Awaiting Schedule',
        engineering_design_analisys:
          'Engineering design awaiting expert approval',
        treatment_suggestion_repproved_dicom:
          'Request was disapproved of pre-analysis due to problems in the DICOM file, to proceed edit the request.',
      },
    },
    treatmentSuggestion: {
      upload: {
        title: 'Expert report',
        description: 'Attach the report here for request',
      },
      send: {
        title: 'Submit for approval',
        description: 'Submit the report for approval',
        button: 'To send',
      },
      modal: {
        success: {
          technically_approved: 'Technically approved treatment suggestion.',
          commercially_approved: 'Commercially approved treatment suggestion.',
          reprove: 'Disapproved treatment suggestion.',
          approve: 'Approved treatment suggestion.',
        },
        title: {
          approve: 'Enter the type of approval',
          reprove: 'State the reason for disapproval',
        },
        statusLabel: {
          commercially_waiting: {
            title: 'Commercially approve',
            description:
              "You've technically approved, now you can only approve or disapprove commercially.",
          },
          technically_waiting: {
            title: 'Technically approve',
            description:
              "You've already approved commercially, now you can only approve or disapprove technically.",
          },
        },
      },
    },
    project: {
      schedule: {
        title: 'Schedule engineering project',
        description: 'Book your engineering project here',
        button: 'To schedule',
        priorizate: 'Prioritize',
      },
      file: {
        title: 'Engineering design data',
        subtitle: 'Engineering design file',
        description: 'View the engineering design file',
      },
      prototype: {
        title: 'Need for a prototype',
        description: 'Production verdict',
        acceptTerms: 'I read and agree.',
        p: {
          1: 'After a careful analysis carried out by our production team, we found that the endoprosthesis you requested has considerable complexity and, therefore, it will be necessary to manufacture a prototype before we proceed to manufacture the final endoprosthesis.',
          2: 'We would like to point out that making the prototype is a fundamental step in the endoprosthesis manufacturing process, as it allows us to test the design under controlled conditions and adjust it as necessary. In this way, we can guarantee that the final endoprosthesis will have the necessary efficacy and safety to fulfill its purpose.',
          3: 'However, it is important to remember that producing a prototype adds one more step to the manufacturing process and therefore may take a little longer to complete the project. In addition, after manufacturing the prototype, it will be tested in the laboratory to assess its effectiveness and safety.',
        },
      },
      observation: {
        title: "Designer's note",
      },
      modal: {
        success: {
          descriptions: {
            success_schedule: 'Engineering project scheduled for another date.',
            success_specialist_approve: 'Engineering project approved.',
            success_consultant_approve: 'Engineering project approved.',
            success_send_project: 'Engineering design submitted.',
            success_reprove: 'Failed engineering design.',
          },
        },
        reprove: {
          title: 'Inform the adjustments to be made',
          observation: {
            label: 'Settings and details',
            placeholder: 'Enter here the details and adjustments to be made',
          },
        },
        approve: {
          title: 'Are you sure you want to approve the engineering design?',
        },
        schedule: {
          title: 'Engineering project scheduling',
          reset: 'Reset',
          calendar: {
            title: 'Calendar',
            description: 'Choose appointment month',
          },
          resume: {
            title: 'More information',
            label: 'Project scheduling date',
            notFound: 'No requests scheduled for this day',
          },
          current: {
            title: 'New engineering project',
            description:
              'Move the new engineering project to the production queue',
          },
          queue: {
            procedure: 'Procedure',
            title: 'Monthly production queue',
            description: 'Prioritize the new project in the production queue',
          },
        },
      },
    },
  },
}
