export default {
  sections: {
    patient: {
      title: 'Dados do Paciente',
      description: 'Informe aqui os dados do paciente para a pré análise',
    },
    treatmentLocation: {
      title: 'Dados do Local do Tratamento',
      description: 'Informe os dados do local onde será realizada o tratamento',
    },
    payingSource: {
      title: 'Dados da Fonte Pagadora',
      description: 'Informe aqui  como será pago o tratamento do paciente',
    },
    diseases: {
      title: 'Doenças e mais informações',
      description:
        'Selecione uma o mais opções da doença. Informe data do procedimento, histórico clínico, e proposta de tratamento atual do paciente.',
    },
    questions: {
      title: 'Perguntas Gerais',
      description:
        'Preenche as informações para seguir com priorização de atendimento',
    },
    upload: {
      title: 'Documentos necessários',
      description:
        'Insira os documentos necessários. Escolha um ou mais arquivos que deseja transferir.',
      help: {
        title: 'Para anexar um DICOM, você precisa comprimir o arquivo antes.',
        description:
          'Para comprimir um arquivo no Mac, siga as instruções abaixo:',
        options: {
          1: 'Clique com botão direito do mouse na pasta que deseja compactar;',
          2: 'Selecione "Comprimir" no menu e aguarde a compactação;',
          3: 'Ao concluir o processo, o arquivo terá o nome da pasta com a extensão ".zip".',
        },
      },
    },
    priorization: {
      title: 'Priorização',
    },
  },
  modal: {
    title: 'Proposta enviado com sucesso.',
    description:
      'Sua solicitação de pré análise foi enviada com sucesso e está sujeita à análise de informações e documentos.',
    titles: {
      send: 'Solicitação enviada',
      save: 'Sucesso',
    },
    descriptions: {
      send: 'Sua solicitação foi enviada com sucesso. Aguarde a revisão do especialista.',
      save: 'Os dados do paciente foram atualizados.',
      thanks: 'Parabéns!',
    },
    protocol: 'Seu número de protocolo',
    back: {
      title: 'Cadastro de Solicitação',
      description:
        'Você está saindo do cadastro de solicitação e está prestes a perder as informações que ja foram preenchidas.',
      question: 'Tem certeza que deseja sair?',
      toast: {
        title: 'Informações não salvas',
        description: 'As informações preenchidas foram perdidas.',
      },
    },
  },
  form: {
    name: {
      label: 'Nome',
      placeholder: 'Digite o nome do paciente',
    },
    genre: {
      label: 'Gênero',
      placeholder: 'Selecione uma opção',
    },
    birthDate: {
      label: 'Data de Nascimento',
    },
    hospital: {
      label: 'Hospital',
      placeholder: 'Digite o nome do hospital',
    },
    country: {
      label: 'País',
      placeholder: 'Digite nome do País',
    },
    state: {
      label: 'Estado',
      placeholder: 'Digite o nome do estado',
    },
    city: {
      label: 'Cidade',
      placeholder: 'Digite o nome da cidade',
    },
    doctor: {
      label: 'Médico',
      placeholder: 'Digite o nome do médico',
    },
    payingSourceType: {
      label: 'Tipo da fonte pagadora',
      placeholder: 'Selecione uma opção',
    },
    payingSource: {
      label: 'Fonte pagadora',
    },
    payingSourceDescription: {
      label: 'Complemento da fonte pagadora',
      placeholder: 'Digite a fonte pagadora',
    },
    diseases: {
      label: 'Doenças',
    },
    clinicalHistory: {
      label: 'Histórico clínico',
      placeholder: 'Digite o histórico clínico do paciente',
    },
    treatmentProposal: {
      label: 'Proposta de tratamento',
      placeholder:
        'Paciente com aneurisma bilateral de artérias ilíacas. Pretensão de tratamento: embolização da artéria hipogástrica direita e endoprótese customizada ramificada para a artéria ilíaca comum esquerda.',
    },
    comments: {
      label: 'Observações',
      placeholder: 'Digite aqui observações para priorização',
    },
    dicom: {
      label: 'Documento Dicom',
    },
    medicalReport: {
      label: 'Laudo Médico',
    },
    projectSketch: {
      label: 'Esboço do projeto',
    },
    treatmentSuggestion: {
      label: 'Sugestão de Tratamento',
    },
    procedure_date: {
      label: 'Data do procedimento',
    },
  },
  questions: {
    paciente_esta_internado: 'Paciente está internado?',
    paciente_esta_com_dor: 'Paciente está com dor?',
    se_sim_paciente_aguardara_a_cirurgia_internado:
      'Se sim, paciente aguardará a cirurgia internado?',
  },
}
