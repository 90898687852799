export default {
  page: {
    title: 'Redefinição de senha',
  },
  form: {
    title: 'Redefinição de senha',
    description:
      'Crie uma nova senha segura, diferente da última que você já utilizou e siga o nosso padrão de senhas',
    password: {
      label: 'Senha',
    },
    confirmPassword: {
      label: 'Confirmar senha',
    },
    submit: {
      text: 'Redefinir',
    },
  },
  instructions: {
    title: 'Instruções para nova senha',
    i1: 'Alfanumérica que tenha no mínimo 8 caracteres;',
    i2: '1 ou mais letras minúsculas;',
    i3: '1 ou mais letras maiúsculas;',
    i4: '1 ou mais caracteres númericos;',
    i5: '1 ou mais caracteres especiais (ex: $*&@¨%!#)',
  },
  toast: {
    success: {
      title: 'Sucesso!',
      description: 'Senha redefinida com sucesso!',
    },
  },
}
