import { languageAtom } from '@states/atoms/language'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export const useTranslator = () => {
  const { i18n, t } = useTranslation()
  const [language] = useAtom(languageAtom)

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  return {
    language,
    t,
  }
}
