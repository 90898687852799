import { ReactElement } from 'react'
import SuccessIcon from '@assets/icons/toast/success.svg'
import ErrorIcon from '@assets/icons/toast/error.svg'
import WarningIcon from '@assets/icons/toast/warning.svg'
import InfoIcon from '@assets/icons/toast/info.svg'
import NotificationIcon from '@assets/icons/toast/notification.svg'

type ToastIcon = {
  success: ReactElement
  error: ReactElement
  info: ReactElement
  warning: ReactElement
  notification: ReactElement
  [key: string]: ReactElement
}

export const toastIcons: ToastIcon = {
  success: <SuccessIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  notification: <NotificationIcon />,
}
