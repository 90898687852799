export default {
  afghanistao: 'Afghanistan',
  africa_do_sul: 'South Africa',
  albania: 'Albania',
  alemanha: 'Germany',
  andora: 'Andora',
  angola: 'Angola',
  anguilla: 'Anguilla',
  antarctica: 'Antarctica',
  antigua_e_barbuda: 'Antigua and Barbuda',
  argentina: 'Argentina',
  argelia: 'Algeria',
  armenia: 'Armenia',
  aruba: 'Aruba',
  arabia_saudita: 'Saudi Arabia',
  australia: 'Australia',
  austria: 'Austria',
  azerbaijao: 'Azerbaijan',
  bahamas: 'Bahamas',
  bahrein: 'Bahrain',
  bangladesh: 'Bangladesh',
  barbados: 'Barbados',
  belgica: 'Belgium',
  belize: 'Belize',
  benim: 'Benin',
  bermuda: 'Bermuda',
  butao: 'Bhutan',
  bielorrussia: 'Belarus',
  bolivia: 'Bolivia',
  bosnia_e_herzegovina: 'Bosnia and Herzegovina',
  botswana: 'Botswana',
  brasil: 'Brazil',
  brunei: 'Brunei',
  bulgaria: 'Bulgaria',
  burkina_faso: 'Burkina Faso',
  myanmar_burma: 'Myanmar (Burma)',
  burundi: 'Burundi',
  camaroes: 'Cameroon',
  cabo_verde: 'Cape Green',
  camboja: 'Cambodia',
  canada: 'Canada',
  catar: 'Qatar',
  chad: 'Chad',
  chile: 'Chile',
  china: 'China',
  chipre: 'Cyprus',
  christmas_island: 'Christmas Island',
  cingapura: 'Singapore',
  colombia: 'Colombia',
  comores: 'Comoros',
  coreia_do_norte: 'North Korea',
  coreia_do_sul: 'South Korea',
  costa_do_marfim: 'Costa do Marfim',
  costa_rica: 'Costa Rica',
  croacia: 'Croatia',
  cuba: 'Cuba',
  dinamarca: 'Denmark',
  djibouti: 'Djibouti',
  dominica: 'Dominica',
  egito: 'Egypt',
  el_salvador: 'El Salvador',
  emirados_arabes_unidos: 'United Arab Emirates',
  equador: 'Ecuador',
  eritreia: 'Eritrea',
  estonia: 'Estonia',
  espanha: 'Spain',
  etiopia: 'Ethiopia',
  fiji: 'Fiji',
  finlandia: 'Finland',
  franca: 'France',
  gabao: 'Gabon',
  gambia: 'Gambia',
  georgia: 'Georgia',
  gana: 'Ghana',
  gibraltar: 'Gibraltar',
  grecia: 'Greece',
  groelandia: 'Greenland',
  granada: 'Grenade',
  guam: 'Guam',
  guatemala: 'Guatemala',
  guiana: 'Guyana',
  guine: 'Guinea',
  guine_equatorial: 'Equatorial Guinea',
  guinebissau: 'Guinea Bissau',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hong_kong: 'Hong Kong',
  hungria: 'Hungary',
  islandia: 'Iceland',
  ilhas_cayman: 'Cayman Islands',
  ilhas_cocos_keeling: 'Cocos (Keeling) Islands',
  ilha_de_man: 'Isle of Man',
  ilhas_cook: 'Cook Islands',
  ilhas_falkland_malvinas: 'Falkland Islands (Malvinas)',
  ilhas_feroe: 'Faroe Islands',
  ilhas_mariana_do_norte: 'Northern Mariana Islands',
  ilhas_marshall: 'Marshall Islands',
  ilhas_norfolk: 'Norfolk Islands',
  ilhas_pitcairn: 'Pitcairn Islands',
  ilhas_salomao: 'Solomon Islands',
  ilhas_turcas_e_caicos: 'Turks and Caicos Islands',
  ilhas_virgens_americanas: 'US Virgin Islands',
  ilhas_virgens_britanicas: 'British Virgin Islands',
  india: 'India',
  indonesia: 'Indonesia',
  inglaterra_reino_unido: 'England (United Kingdom)',
  ira: 'Will',
  iraque: 'Iraq',
  irlanda: 'Ireland',
  israel: 'Israel',
  italia: 'Italy',
  jamaica: 'Jamaica',
  japao: 'Japan',
  jordania: 'Jordan',
  cazaquistao: 'Kazakhstan',
  quenia_kenya: 'Kenya (Kenya)',
  kiribati: 'Kiribati',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  quirguistao: 'Kyrgyzstan',
  laos: 'Laos',
  letonia: 'Latvia',
  libano: 'Lebanon',
  lesoto: 'Lesotho',
  liberia: 'Liberia',
  libia: 'Libya',
  liechtenstein: 'Liechtenstein',
  lituania: 'Lithuania',
  luxemburgo: 'Luxembourg',
  macau: 'Macao',
  macedonia: 'Macedonia',
  madagascar: 'Madagascar',
  malawi: 'Malawi',
  malasia: 'Malaysia',
  maldivas: 'Maldives',
  mali: 'Mali',
  malta: 'Malta',
  mauritania: 'Mauritania',
  mauricia: 'Mauritius',
  mayotte: 'Mayotte',
  mexico: 'Mexico',
  micronesia: 'Micronesia',
  moldavia: 'Moldavia',
  monaco: 'Monaco',
  mongolia: 'Mongolia',
  montenegro: 'Montenegro',
  montserrat: 'Montserrat',
  marrocos: 'Morocco',
  mocambique: 'Mozambique',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands_antilles: 'Netherlands Antilles',
  nova_caledonia: 'New Caledonia',
  nova_zelandia: 'New Zealand',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  niue: 'Nue',
  noruega: 'Norway',
  oma: 'Oman',
  paises_baixos: 'Netherlands',
  paquistao: 'Pakistan',
  palau: 'Palau',
  panama: 'Panama',
  papuanova_guine: 'Papua New Guinea',
  paraguai: 'Paraguay',
  peru: 'Peru',
  filipinas: 'Philippines',
  polonia: 'Poland',
  polinesia_francesa: 'French Polynesian',
  portugal: 'Portugal',
  porto_rico: 'Puerto Rico',
  republica_do_congo: 'Congo Republic',
  republica_democratica_do_congo: 'Democratic Republic of Congo',
  republica_centroafricana: 'Central African Republic',
  republica_checa: 'Czech Republic',
  republica_dominicana: 'Dominican Republic',
  romenia: 'Romania',
  ruanda: 'Rwanda',
  russia: 'Russia',
  saint_barthelemy: 'Saint Barthelemy',
  samoa: 'Samoa',
  samoa_americana: 'American Samoa',
  san_marino: 'San Marino',
  sao_tome_e_principe: 'Sao Tome and Principe',
  senegal: 'Senegal',
  serbia: 'Serbia',
  serra_leoa: 'Sierra Leone',
  seychelles: 'Seychelles',
  slovakia: 'Slovakia',
  slovenia: 'Slovenia',
  somalia: 'Somalia',
  sri_lanka: 'Sri Lanka',
  saint_helena: 'Saint Helena',
  saint_kitts_and_nevis: 'Saint Kitts and Nevis',
  saint_lucia: 'Saint Lucia',
  saint_martin: 'Saint Martin',
  saint_pierre_and_miquelon: 'Saint Pierre and Miquelon',
  saint_vincent_and_the_grenadines: 'Saint Vincent and the Grenadines',
  sudao: 'Sudan',
  suriname: 'Surinam',
  suazilandia: 'Swaziland',
  suecia: 'Sweden',
  suica: 'Switzerland',
  syria: 'Syria',
  taiwan: 'Taiwan',
  tajiquistao: 'Tajikistan',
  tanzania: 'Tanzania',
  timorleste: 'Timor-Leste',
  tailandia: 'Thailand',
  togo: 'Togo',
  tokelau: 'Tokelau',
  tonga: 'Tonga',
  trinidad_e_tobago: 'Trinidad and Tobago',
  tunisia: 'Tunisia',
  turquemenistao: 'Turkmenistan',
  turquia: 'Turkey',
  tuvalu: 'Tuvalu',
  uganda: 'Uganda',
  ucrania: 'Ukraine',
  uruguai: 'Uruguay',
  estados_unidos_eua: 'United States (USA)',
  uzbequistao: 'Uzbekistan',
  vanuatu: 'Vanuatu',
  vaticano: 'Vatican',
  venezuela: 'Venezuela',
  vietna_vietname: 'Vietnam (Vietnam)',
  wallis_e_futuna: 'Wallis and Futuna',
  iemen_iemen_yemen: 'Yemen (Yemen, Yemen)',
  zimbabwezimbabue: 'Zimbabwe(Zimbabwe)',
  zambia: 'Zambia',
}
