export default {
  page: {
    title: 'Schedule Project',
    schedule: {
      title: 'New engineering project',
      observation: 'Move the new engineering project to the desired day',
    },
  },
  scheduleModal: {
    title: 'Move Engineering Project',
    description: 'Choose the best date to move the engineering project',
    canMove: 'Can be moved',
    cantMove: 'Cannot be moved',
    moveButton: 'To move',
    scheduleTitle: 'Project scheduling',
    observation: {
      title: 'Scheduling Notes',
      placeholder:
        'Enter here any observation or information that you think is relevant',
    },
    descriptions: {
      reset: 'Are you sure you want to reset the engineering project schedule?',
      cancel:
        'Are you sure you want to cancel the engineering project schedule?',
      priorizate:
        'Are you sure you want to prioritize the engineering project(s)?',
      success:
        'This operation was successfully performed. Engineering project scheduled for another date.',
    },
  },
  legends: {
    scheduled: 'Scheduled',
    reproved: 'Disapproved',
    sent: 'Sent',
    settings: 'Settings',
    approved: 'Approved',
  },
}
