export default {
  table: {
    newButton: 'Nuevo Usuario',
    emptyTitle: 'Registrar un usuario',
    emptyDescription:
      'Aún no tienes ningún usuario. Registre su primer usuario y su información se mostrará aquí.',
  },
  update: {
    title: 'Usuario',
  },
  register: {
    title: 'Registro de usuario',
    sections: {
      title: 'Datos del usuario',
      description: 'Rellene la información del usuario',
    },
    saveButton: 'Crear',
  },
  toast: {
    error: {
      'e-mail_already_in_use':
        'El correo electrónico ya está siendo utilizado por otro usuario',
    },
  },
  exclude: {
    modal: {
      initial: {
        title: 'Borrar usuario',
        description: '¿Está seguro de que desea eliminar este usuario?',
      },
      success: {
        title: 'Éxito',
        description: 'Usuario eliminado con éxito.',
      },
    },
  },
}
